import React, { useState, useEffect } from "react";
import axios from 'axios';
// import '@zoomus/websdk/dist/css/bootstrap.css'; // Import Zoom SDK CSS
// import '@zoomus/websdk/dist/css/react-select.css'; // Import Zoom SDK CSS
import '../styles/counsellor.css';
import {Input, Layout } from "antd";
import DashboardLayout from "../components/teacher-layout";
import { useNavigate,useParams } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
const { Search } = Input;
const { Content } = Layout;

const JoinSession = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    const { meetingid } = useParams();
    
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [studentList, setStudentList] = useState([]);

    
    useEffect(() => {
         
        axios.post(api_url + 'pbs/getSessionDetails', {
            meeting_id: meetingid

            }).then((res) => {
                loadZoomSDK(res);
            })
          
    }, [])

    const loadZoomSDK = async (response) => {
        let class_data = response.data.data;
        
        const { ZoomMtg } = await import('@zoomus/websdk');
        ZoomMtg.setZoomJSLib('https://source.zoom.us/2.15.2/lib', '/av');
  
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();
  
        ZoomMtg.i18n.load('en-US');
        ZoomMtg.i18n.reload('en-US');
        console.log(class_data);
        if(class_data){
            ZoomMtg.generateSDKSignature({
                meetingNumber : response.data.data.meeting_id,
                role : 0,
                sdkKey :  class_data.sdk_key,
                sdkSecret : class_data.sdk_secret,
                success:function(signature){
                   
                    ZoomMtg.init({
                        leaveUrl: '/student-dashboard/pbs-session',
                        success: (success) => {
                            ZoomMtg.join({
                                meetingNumber:  class_data.meeting_id,
                                sdkKey: class_data.sdk_key,
                                signature: signature.result,
                                userName: decrypt(user_data.name,encriptionKey) + '-'+ user_data.id,
                                userEmail: decrypt(user_data.email,encriptionKey),
                                // registrant_id : user_data.id,
                                passWord: 123456,
                                success: (joinSuccess) => {
                                    console.log(joinSuccess);
                                    // ZoomMtg.getSettings().disableOtherSettings(false);
                                },
                                error: (joinError) => {
                                    console.error('Error joining the meeting:', joinError);
                                },
                            });
                        },
                        error: (error) => {
                          console.log(error)
                        }
                      })
                }
            })
        }else{
            window.location.href = '/student-dashboard/pbs-session';
        }
        
    };

    // useEffect(() => {
    //     const loadZoomSDK = async () => {
    //         const { ZoomMtg } = await import('@zoomus/websdk');
    //         ZoomMtg.setZoomJSLib('https://source.zoom.us/2.15.2/lib', '/av');
      
    //         ZoomMtg.preLoadWasm();
    //         ZoomMtg.prepareWebSDK();
      
    //         ZoomMtg.i18n.load('en-US');
    //         ZoomMtg.i18n.reload('en-US');
    //         axios.post(api_url + 'application/generateZoomSignature', {
    //             class_id: 1
    
    //         }).then((res) => {
               
    //             ZoomMtg.init({
    //                 leaveUrl: 'http://localhost:3000',
    //                 success: (success) => {
    //                     console.log(success);

    //                     ZoomMtg.join({
    //                         sdkKey: "BjPOoEzyRO2QCdaYhYlscw",
    //                         signature: res.data, // Pass in your Meeting SDK JWT
    //                         meetingNumber: '88972615750',
    //                         passWord: '7P1uYX',
    //                         userName: 'Test',
    //                         success: (success) => {
    //                           console.log("dgssdgdsg")
    //                         },
    //                         error: (error) => {
    //                           console.log(error)
    //                         }
    //                       })
                        
    //                 },
    //                 error: (error) => {
    //                   console.log(error)
    //                 }
    //               })
    //         })
           
    //       };
      
    //       loadZoomSDK();
    // }, [])
   
   

    return (
       ''
    )
}

export default JoinSession