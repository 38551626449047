import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/dashboard.css';
import { Button, Col, Row, Form, Input, Layout } from 'antd';
import { api_url } from '../components/constants';
import { useNavigate } from "react-router-dom";
import DashboardLayout from '../components/dashboard-layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Content } = Layout;

const GiqSummary = () => {
    const [questions, setQuestions] = useState([{ qid: "", question: "", answer: "" }])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const fetchUserData = async () => {

        await axios.post(api_url + 'get_giq_form_data', {
            user_id: user_data.id,

        }).then((res) => {
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const giq_data = res.data.giq_data;
            let obj = JSON.parse(giq_data);
            //console.log(obj);
            setQuestions(obj);
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    const handleChange = (i, e) => {

        let newFormValues = [...questions];
        newFormValues[i]['answer'] = e.target.value;
        setQuestions(newFormValues);

    };

    const handleSubmit = () => {
        //console.log(questions);
        axios.post(api_url + 'user/update_giq_records', {
            user_id: user_data.id,
            type: 'GIQ',
            records: questions,
        })
            .then(result => {

                if (result.data.status) {

                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        navigate(window.location.pathname)
                    }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }

            })
    }

    return (
        <>
            <ToastContainer />
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >

                        <Row gutter={30}>

                            <Col md={24} xs={24}>
                                <div className="form-layout form-space giq-block-section">
                                    <div>
                                        <h1 className="bg-[#0F4275] text-[#fff] text-2xl flex justify-center text-center py-2.5 uppercase">General Introspection Questionnaire</h1>
                                    </div>
                                    <div className="bg-[#F5F5F5] lg:p-5 p-5">
                                        <div className="flex justify-center items-center">
                                            <Form
                                                name="normal_login"
                                            >
                                                {questions.map((ques, index) => (
                                                    <div key={index}>

                                                        <Row>
                                                            <Col md={24} xs={24}>
                                                                <div className="que-box mb-2.5">
                                                                    <h2 className="text-[#191818] md:text-base text-sm">
                                                                        {ques.question}
                                                                    </h2>
                                                                </div>
                                                            </Col>

                                                            <Col md={24} xs={24}>
                                                                <Form.Item
                                                                    name={ques.qid}
                                                                    wrapperCol={{ span: 24 }}
                                                                    initialValue={ques.answer}
                                                                >
                                                                    <Input.TextArea placeholder="Answer :-" type="textarea" onChange={e => handleChange(index, e)} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))}

                                            </Form>
                                        </div>

                                        <div>
                                            <Row gutter={30} style={{ marginTop: 20 }}>
                                                <Col md={24} xs={24} className='flex justify-center items-center'>

                                                    <div className="flex">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-10 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={handleSubmit}
                                                        >
                                                            Update
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </Row>

                    </Content>
                )
                }
            </DashboardLayout>
        </>
    )
}

export default GiqSummary