import React, { useEffect, useState } from 'react';
import '../styles/faculty.css';
import axios from 'axios';
import { Layout, Button, Form, Col, Row, Input, Radio, Spin } from 'antd';
import { BackwardOutlined } from '@ant-design/icons';
import TeacherLayout from "../components/teacher-layout";
import { useParams, useNavigate } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
const { Content } = Layout;
const { TextArea } = Input;

const UpdatePerformanceReport = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({ username: "", centername: "", classname: "", coursename: "", email: '' });
    const [reportColumn, setReportColumn] = useState({ id: "", user_id: "", month: "", report_data: "", created_by: "" });
    const [reportData, setReportData] = useState({
        month: '', classes: '', present: '', absent: '', remark: '', communication: '', participation: '', presentation_skills: '', assignment: '', behaviour: '', performance: "", grammar: "",
        noun_score: '', pronoun_score: '', verb_score: '', adjective_score: '', adverb_score: '', improvement: '', gen_remarks: '',
    });

    const fetchPerfomanceDetails = async () => {

        await axios.post(api_url + 'faculty/get_performance_data', {
            id: id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }

            const report_list = res.data.report_data;

            setReportColumn(report_list);
            const obj = JSON.parse(report_list.report_data);
            const st_list = res.data.user_data;
            setReportData(obj);
            setUserData(st_list);
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchPerfomanceDetails()
    }, [])

    const onFinish = (values) => {
        axios.post(api_url + 'faculty/submit_performance_report', {
            id: reportColumn.id,
            faculty_id: user_data.id,
            records: values,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    // const timer = setTimeout(() => {
                    //     navigate("/counsellor-dashboard/targets/" + id)
                    // }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })
        console.log('Success:', values);

    };
    const onBackRequest = (record) => {
        navigate('/teacher-dashboard/performance-list/' + reportColumn.user_id)
    }

    return (
        <TeacherLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <div className="mx-16 mt-7" style={{ border: '1px solid #E5E5E5' }}>
                        <Row>
                            <Col md={24}>
                                <div className='flex bg-[#114D89] px-5 py-2.5 justify-between '>
                                    <div className="">
                                        <h1 className="text-white text-xl text-center">Student Performance Report</h1>
                                        <span className='text-sm text-white text-center'>For the month of April & May</span>
                                    </div>
                                    <div>

                                    </div>
                                    <BackwardOutlined onClick={onBackRequest} className='text-white text-3xl flex items-center justify-center' />
                                </div>
                            </Col>

                        </Row>
                        <div className='bg-[#F1F3F6]'>
                            <Row gutter={30} style={{ padding: '20px 30px' }}>
                                <Col md={24}>
                                    <Row gutter={30}>
                                        <Col md={12}>
                                            <div className='flex py-1.5'>
                                                <h3 className='text-base text-[#0C3762] font-medium'>Name</h3>
                                                <span className='text-base text-[#787878] font-medium pl-16'>{decrypt(userData.username, encriptionKey)}</span>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className='flex py-1.5 '>
                                                <h3 className='text-base text-[#0C3762] font-medium'>Contact No:-</h3>
                                                <span className='text-base text-[#787878] font-medium pl-7'>{decrypt(userData.mobile, encriptionKey)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col md={12}>
                                            <div className='flex py-1.5'>
                                                <h3 className='text-base text-[#0C3762] font-medium'>Centre</h3>
                                                <span className='text-base text-[#787878] font-medium pl-16'>{userData.centername}</span>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className='flex py-1.5'>
                                                <h3 className='text-base text-[#0C3762] font-medium'>Email id</h3>
                                                <span className='text-base text-[#787878] font-medium pl-16'>{decrypt(userData.email, encriptionKey)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col md={12}>
                                            <div className='flex py-1.5'>
                                                <h3 className='text-base text-[#0C3762] font-medium'>Class</h3>
                                                <span className='text-base text-[#787878] font-medium pl-16'>{decrypt(userData.classname, encriptionKey)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <Form onFinish={onFinish}>
                            <div>
                                <Row>
                                    <Col md={24}>
                                        <h2 className='text-[#0C3762] text-lg p-5 text-center font-bold'>REPORT OF MONTH</h2>
                                    </Col>
                                </Row>
                                <div className='bg-[#F1F3F6] py-5 px-7'>
                                    <div className='attendance py-5'>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Item
                                                    name="month"
                                                    label="Month"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={reportData.month}
                                                >
                                                    <Input
                                                        type="text"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <Row>
                                    <Col md={24}>
                                        <h2 className='text-[#0C3762] text-lg p-5 text-center font-bold'>MONTHLY ATTENDANCE</h2>
                                    </Col>
                                </Row>
                                <div className='bg-[#F1F3F6] py-5 px-7'>
                                    <div className='attendance py-5'>
                                        <Row gutter={30}>
                                            <Col md={6}>
                                                <Form.Item
                                                    name="classes"
                                                    label="Classes"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={reportData.classes}
                                                >
                                                    <Input
                                                        type="text"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Item
                                                    name="present"
                                                    label="Total Present"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={reportData.present}
                                                >
                                                    <Input
                                                        type="number"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Item
                                                    name="absent"
                                                    label="Total Absent"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={reportData.absent}
                                                >
                                                    <Input
                                                        type="number"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Item
                                                    name="remark"
                                                    label="Remark*"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={reportData.remark}
                                                >
                                                    <Input
                                                        type="text"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>

                            <Row>
                                <Col md={24}>
                                    <h2 className='text-[#0C3762] text-lg p-5 text-center font-bold'>PERFORMANCE EVALUATION</h2>
                                </Col>
                            </Row>
                            <div className='bg-[#F1F3F6] px-7'>
                                <div className='evaluation py-5'>
                                    <Row gutter={30}>
                                        <Col md={6}>
                                            <Form.Item
                                                name="communication"
                                                label="Communication"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={reportData.communication}
                                            >
                                                <select name="communication">
                                                    <option value="select">Select</option>
                                                    <option value="Excellent">Excellent</option>
                                                    <option value="Good">Good</option>
                                                    <option value="Urgent Attention">Urgent Attention</option>
                                                    <option value="Need Practice">Need Practice</option>
                                                    <option value="Need Improvement">Need Improvement</option>
                                                </select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="participation"
                                                label="Class Participation"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={reportData.participation}
                                            >
                                                <select name="participation">
                                                    <option value="select">Select</option>
                                                    <option value="Excellent">Excellent</option>
                                                    <option value="Good">Good</option>
                                                    <option value="Urgent Attention">Urgent Attention</option>
                                                    <option value="Need Practice">Need Practice</option>
                                                    <option value="Need Improvement">Need Improvement</option>
                                                </select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="presentation_skills"
                                                label="Presentation Skills"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={reportData.presentation_skills}
                                            >
                                                <select name="presentation_skills">
                                                    <option value="select">Select</option>
                                                    <option value="Excellent">Excellent</option>
                                                    <option value="Good">Good</option>
                                                    <option value="Urgent Attention">Urgent Attention</option>
                                                    <option value="Need Practice">Need Practice</option>
                                                    <option value="Need Improvement">Need Improvement</option>
                                                </select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="assignment"
                                                label="Home Assignment"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={reportData.assignment}
                                            >
                                                <select name="assignment">
                                                    <option value="select">Select</option>
                                                    <option value="Excellent">Excellent</option>
                                                    <option value="Good">Good</option>
                                                    <option value="Urgent Attention">Urgent Attention</option>
                                                    <option value="Need Practice">Need Practice</option>
                                                    <option value="Need Improvement">Need Improvement</option>
                                                </select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="grammar"
                                                label="Grammar"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={reportData.grammar}
                                            >
                                                <select name="grammar">
                                                    <option value="select">Select</option>
                                                    <option value="Excellent">Excellent</option>
                                                    <option value="Good">Good</option>
                                                    <option value="Urgent Attention">Urgent Attention</option>
                                                    <option value="Need Practice">Need Practice</option>
                                                    <option value="Need Improvement">Need Improvement</option>
                                                </select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="behaviour"
                                                label="Class Behaviour"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={reportData.behaviour}
                                            >
                                                <select name="behaviour">
                                                    <option value="select">Select</option>
                                                    <option value="Excellent">Excellent</option>
                                                    <option value="Good">Good</option>
                                                    <option value="Urgent Attention">Urgent Attention</option>
                                                    <option value="Need Practice">Need Practice</option>
                                                    <option value="Need Improvement">Need Improvement</option>
                                                </select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div>
                                <Row>
                                    <Col md={24}>
                                        <h2 className='text-[#0C3762] text-lg p-5 text-center font-bold'>TEST RESULT</h2>
                                    </Col>
                                </Row>
                                <div className='bg-[#F1F3F6] px-7'>
                                    <div className='evaluation py-5'>
                                        <Row>
                                            <Col md={24}>
                                                <table className='test-result'>
                                                    <thead>
                                                        <tr>
                                                            <th>Topics</th>
                                                            <th>Total</th>
                                                            <th>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className='topic'>Noun</td>
                                                            <td>500</td>
                                                            <td>
                                                                <Form.Item name='noun_score' initialValue={reportData.noun_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='topic'>Pronoun</td>
                                                            <td>500</td>
                                                            <td>
                                                                <Form.Item name='pronoun_score' initialValue={reportData.pronoun_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='topic'>Verbs</td>
                                                            <td>500</td>
                                                            <td>
                                                                <Form.Item name='verb_score' initialValue={reportData.verb_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='topic'>Adjective</td>
                                                            <td>500</td>
                                                            <td>
                                                                <Form.Item name='adjective_score' initialValue={reportData.adjective_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='topic'>Adverb</td>
                                                            <td>500</td>
                                                            <td>
                                                                <Form.Item name='adverb_score' initialValue={reportData.adverb_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td></td>
                                                            <td className='score'>Grand Total 2500</td>
                                                            <td className='score'>Total Scored 2500</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='mt-7 bg-[#F1F3F6] p-5'>
                                    <Row>
                                        <Col md={24}>
                                            <h2 className='text-[#0C3762] text-lg font-bold mb-5'>IMPROVEMENT SINCE JOINING</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item name='improvement' initialValue={reportData.improvement}>
                                                <TextArea rows={5} className='remark' />
                                            </Form.Item>

                                        </Col>
                                    </Row>
                                </div>
                                <div className='mt-7 bg-[#F1F3F6] p-5'>
                                    <Row>
                                        <Col md={24}>
                                            <h2 className='text-[#0C3762] text-lg font-bold mb-5'>GENERAL REMARKS</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item name='gen_remarks' initialValue={reportData.gen_remarks}>
                                                <TextArea rows={5} className='remark' />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <Row>
                                <Col md={24} className='flex justify-center my-7'>
                                    <Button className="bg-[#205584] text-white" htmlType='submit' >Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}
            </Content>
        </TeacherLayout>
    )
}

export default UpdatePerformanceReport