import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { ClockCircleOutlined } from '@ant-design/icons';


const Notification = () => {
    return (
        <>
            <CounsellorLayout>
                <div className="mx-16 mt-10">
                    <Row>
                        <Col md={24}>
                            <h1 className="text-[#061522] text-2xl mb-2.5">Student Records</h1>
                            <span className="text-[#9B9B9B] text-lg">You have 2 unread messages</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <div className="p-5 flex justify-between mt-10 mb-1.5" style={{ boxShadow: '0px 3px 6px #00000029', borderLeft: '3px solid #F0AA0C' }}>
                                <div>
                                    <h2 className="text-[#010101] text-lg">Prince Sharma <span className="text-[#A2A2A2] text-base">request to PBS session</span></h2>
                                    <p className="text-[#A2A2A2] text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                                <div className="flex items-center justify-center">
                                    <ClockCircleOutlined className='text-[#A2A2A2] text-lg' />
                                    <span className="text-[#A2A2A2] text-base pl-2.5">10 mins ago</span>
                                </div>
                            </div>
                        </Col>
                        <Col md={24}>
                            <div className="p-5 flex justify-between mt-10 mb-1.5" style={{ boxShadow: '0px 3px 6px #00000029', borderLeft: '3px solid #F0AA0C' }}>
                                <div>
                                    <h2 className="text-[#010101] text-lg">Prince Sharma <span className="text-[#A2A2A2] text-base">request to PBS session</span></h2>
                                    <p className="text-[#A2A2A2] text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                                <div className="flex items-center justify-center">
                                    <ClockCircleOutlined className='text-[#A2A2A2] text-lg' />
                                    <span className="text-[#A2A2A2] text-base pl-2.5">10 mins ago</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </CounsellorLayout>

        </>
    )
}

export default Notification