import React, { useEffect, useState } from 'react';




const ReadingAnalysis = () => {
    
    return (
        <>
<h1>Palak</h1>
        </>
    )
}


export default ReadingAnalysis