

import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import axios from 'axios';
import { Button, Col, Row, Layout, Space, Radio, Select, Modal, Form } from 'antd';
import { useNavigate } from 'react-router-dom'
import { api_url } from '../components/constants';
import DashboardLayout from '../components/dashboard-layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Content } = Layout;
const { Option } = Select;


const ReadingToolPractice = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);



    const [roundId, setRoundId] = useState(0);
    const [rounds, setRounds] = useState([]);

    const [sessionId, setSessionId] = useState(0);
    const [session, setSession] = useState('');
    
    const [levelId, setLevelId] = useState(0);
    const [levelOptions, setLevelOptions] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState(1);
    const [levelSelected, setLevelSelected] = useState(false);

    const [passageId, setPassageId] = useState(0);
    const [showPassageReading, setShowPassageReading] = useState(false);
    
    const [beginClicked, setBeginClicked] = useState(false);
    const [passage, setPassage] = useState('');

    const [subjectId, setSubjectId] = useState(0);
    const [passage_id, setPassageLevelId] = useState(0);
    const [subjects, setSubjects] = useState([]);
    const [passageList, setPassageList] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedPassage, setSelectedPassage] = useState(null);
    const [subjectSelected, setSubjectSelected] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);
    const [showQuiz, setShowQuiz] = useState(false);


    const [seconds, setSeconds] = useState(0);
    const [startTime, setStartTime] = useState(null);
    const [timer, setTimer] = useState(null);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(5);
    const [playClicked, setPlayClicked] = useState(false);
    const [isReading, setIsReading] = useState(false);
    const [playing, setPlaying] = useState(true); 
    const [readingSpeed, setReadingSpeed] = useState(0);
    const [questions, setQuestions] = useState([]);
    
    const onLevelChange = e => {
        setLevelId(e.target.value);
        setSelectedLevel(e.target.value);
        

        axios.post(api_url + 'reading/get_levelwise_passage', {
            user_id: user_data.id,
            subject_id: subjectId,
            session_id: sessionId,
            level_id: e.target.value,
            round_id: roundId,
          }).then(result => {
              
              if (result.data.status) {
                
                const passageInfo = Object.entries(result.data.passage_list).map(([key, value]) => ({
                    label: `${value} Level`,
                    value: parseInt(key)
                }));
                
                setPassageList(passageInfo);
               
        
              } else {
                  toast.error("Something went wrong", {
                      position: toast.POSITION.TOP_CENTER
                  });
              }
        
        
          }).catch(error => {
              console.error(error);
          });

       
    };


    const onSubjectChange = e => {
        const subjectId = parseInt(e.substring(7));
        setSubjectId(subjectId);
        setSelectedSubject(subjectId);
        if (!beginClicked) {
            setSubjectSelected(true); // Set levelSelected to true when a level is selected
        }
    };


    const onPassageChange = e => {
        const passage_id = e;
        setPassageLevelId(passage_id);
        setSelectedPassage(passage_id);
        
    };


    

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch block info
                const responseInfo = await axios.post(api_url + 'get_practice_block_info', {
                    user_id: user_data.id,
                });

                if (responseInfo.data.status) {
                    const blockInfo = responseInfo.data.block_info[0];
                    setRoundId(blockInfo.round);
                    setSessionId(blockInfo.session);
                    
                    // Set sessions
                    if (blockInfo.session) {
                        setSession(`session-${blockInfo.session}`);
                    }

                    const subjectInfo = blockInfo.subject;
                    const subjectNames = Object.values(subjectInfo);
                    setSubjects(subjectNames);
                   
                    // Set levels
                    const levelInfo = blockInfo.level;
                    const levelOptionsArray = Object.entries(levelInfo).map(([key, value]) => ({
                        label: `${value} Level`,
                        value: parseInt(key)
                    }));
                    setLevelOptions(levelOptionsArray);

                    // Set rounds
                    const roundInfo = responseInfo.data.round_info;
                    const roundNames = Object.values(roundInfo);
                    setRounds(roundNames);
                   
                } else {
                    navigate("/student-dashboard/dashboard");
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        
    }, []);


    const handleBeginClick = async () => {
        if (selectedSubject && selectedLevel) {
            
           if(passage_id === 0 ){
                toast.error("Please select passage", {
                    position: toast.POSITION.TOP_CENTER
                });
                return false;
           }
            try {
                const responsePassage = await axios.post(api_url + 'get_practice_passage_info', {
                    user_id: user_data.id,
                    // subject_id: subjectId,
                    // session_id: sessionId,
                    // level_id: levelId,
                    // round_id: roundId,
                    passage_id : passage_id,
                });
                
                
                if (responsePassage.data.status) {
                    const passageContent = responsePassage.data.passage_info.passage;
                    setShowPassageReading(true);
                    setBeginClicked(true);
                    setPassage(passageContent);
                    setPassageId(responsePassage.data.passage_info.passage_id);

                    if (responsePassage.data.question_list.length > 0) {
                        const parsedQuestions = responsePassage.data.question_list.map(q => ({
                            ...q,
                            options: JSON.parse(q.options)
                        }));
                        setQuestions(parsedQuestions);
                        setQuestions(parsedQuestions);
                        setResponseId(responsePassage.data.response_id);
                        //setQuestions(responsePassage.data.question_list);
                        
                        
                    }else{
                        // const timer = setTimeout(() => {
                        //     window.location.reload();
                        // }, 5000);
                    }
                   
                } else {
                    
                    toast.error(responsePassage.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => {
                        navigate("/student-dashboard/reading-tool-practice")

                    }, 3000);
                }
            } catch (error) {
                console.error('Error fetching passage:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    function formatTime(seconds) {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins < 10 ? '0' + mins : mins}:${secs < 10 ? '0' + secs : secs}`;
    }

    const onFinish = async () => {
        setModalVisible(true);
        const timer = setTimeout(() => {
            if(questions.length > 0){
                setShowQuiz(true);
                setModalVisible(false);
                setShowPassageReading(false);
                const timer = setTimeout(() => {
                    setShowQuiz(true);
                    setShowPassageReading(false);
                    const intervalId = setInterval(() => {
                        setQuizSeconds(prevSeconds => prevSeconds + 1);
                    }, 1000);
                    setQuizTimer(intervalId);
                }, 1000);
            }else{
                window.location.reload();
            }
        }, 5000);
        // try {
            
        //     const formDetails = {
        //         subject_id: subjectId,
        //         level_id: levelId,
        //         session_id : sessionId,
        //         readingSpeed,
        //         round_id: roundId,
        //         passageId,
        //         user_id: user_data.id,

        //     };

        //     const responsePassage = await axios.post(api_url + 'reading/save_practice_passage_response', {
        //         formDetails
        //     });

        //     if (responsePassage) {
        //         if (responsePassage.data.status) {
        //             setModalVisible(true);
                    
        //             if (responsePassage.data.question_list.length > 0) {
                        
        //                 //setQuestions(responsePassage.data.question_list);
        //                 const timer = setTimeout(() => {
        //                     // setShowQuiz(true);
        //                     // setShowPassageReading(false);
        //                     window.location.reload();
        //                 }, 5000);
                        
        //             }else{
        //                 const timer = setTimeout(() => {
        //                     window.location.reload();
        //                 }, 5000);
        //             }
                    
                   
        //         }
        //     } else {
        //         console.error('Error fetching passage data:', responsePassage);
        //     }
        // } catch (error) {
        //     console.error('Error extracting form data:', error);
        // }
    };

    const startTest = () => {
        const intervalId = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds + 1);
        }, 1000);
        setStartTime(new Date()); // Set startTime when test starts
       
        setTimer(setInterval(() => {
           
            setStartIndex(prevIndex => prevIndex + 5);
            setEndIndex(prevIndex => prevIndex + 5);
        }, 1000)); // Adjust the interval based on desired speed
        setPlayClicked(true);
        setPlaying(true); // Start playing
        setIsReading(true); // Set reading mode
        setTestStarted(true);
        setTestPlay(false);
    };

  

    const stopTest = () => {
       
        clearInterval(timer); // Clear the timer
        const endTime = new Date();
        const timeElapsedInSeconds = (endTime - startTime) / 1000;
        let wordsPerLine;
        if(roundId == 1){
             wordsPerLine = 200;
        }else if(roundId == 2){
             wordsPerLine = 350;
        }else{
             wordsPerLine = 500;
        }
        const readingSpeed = Math.round((wordsPerLine / timeElapsedInSeconds) * 60);
        setReadingSpeed(readingSpeed);
        setPlaying(false);
        //setTestStarted(false);
        //setTestPlay(true);
    };


    const word = passage.split(" ");
    const currentWords = word.slice(startIndex, endIndex);


    const [quizSeconds, setQuizSeconds] = useState(0);
    const [quiztimer, setQuizTimer] = useState(null);


    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [responses, setResponses] = useState([]);
    
    const [reponseId, setResponseId] = useState(0);
    const [correctQuestion, setCorrectQuestion] = useState(0);
    
    const [quizmodalVisible, setQuizModalVisible] = useState(false);

    function formatQuizTime(seconds) {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins < 10 ? '0' + mins : mins}:${secs < 10 ? '0' + secs : secs}`;
    }

    const handleNextQuestion = () => {
        setQuizSeconds(0);
        clearInterval(quiztimer);
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
        const interval = setInterval(() => {
            setQuizSeconds(prevSeconds => prevSeconds + 1);
        }, 1000);
        setQuizTimer(interval);
        //setValue(userAnswers[currentQuestion + 1] || 1);
    };

   

    const handleOptionClick = (option, qid ,time,ans) => {

        
        let answer = "";
        if (ans === 'A') {
            answer = 0;
        } else if (ans === 'B') {
            answer = 1;
        } else if (ans === 'C') {
            answer = 2;
        } else if (ans === 'D') {
            answer = 3;
        }

        let isCorrect = option === answer;

        if (isCorrect) {
            setCorrectQuestion(correctQuestion + 1);
        }
        const newData = { qid: qid, answer: option , attempt_time : time};
        
        setResponses([...responses, newData]);
        
      

    };

    const handleQuizSubmit = async () => {
        setQuizModalVisible(true);

        const timer = setTimeout(() => {
            window.location.reload();
        }, 10000);
        
        // try {
            
        //     const formDetails = {
        //         responses: responses,
        //         reponseId: reponseId,
        //         user_id: user_data.id,
        //         passageId,

        //     };

        //     const responseQuiz =  await axios.post(api_url + 'reading/save_quiz_response', {
        //         formDetails
        //     });

        //     if (responseQuiz) {
                
        //         if (responseQuiz.data.status) {
        //             setQuizModalVisible(true);
        //             const timer = setTimeout(() => {
        //                 window.location.reload();
        //             }, 5000);

        //             } else {
        //                 const timer = setTimeout(() => {
        //                     window.location.reload();
        //                 }, 5000);
        //             }
                
        //     } else {
        //         console.error('Error fetching passage data:', responseQuiz);
        //     }
        // } catch (error) {
        //     console.error('Error extracting form data:', error);
        // }
    };

    const [testStarted, setTestStarted] = useState(false);
    const [testPlay, setTestPlay] = useState(true);
    
    
    
    return (
        <>
            <ToastContainer />
            <DashboardLayout>

                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <Row gutter={30}>

                        <Col md={24} xs={24}>

                            <div className="form-layout form-space" style={{ background: '#F5F5F5' }}>

                                <div className='flex  pbs-session-heading bg-[#114D89] px-5 py-2.5 justify-between '>
                                    <h1 className="text-white text-xl text-center font-light">Speed Reading</h1>
                                    <p className="text-right text-white text-xl font-light capitalize">{session}</p>
                                </div>
                                <div className="bg-[#F5F5F5] p-7">

                                    <Form form={form} onFinish={onFinish}>
                                        <Row>
                                            <Col md={24}>

                                                <div className='flex items-center justify-center'>
                                                    {rounds.map((round, index) => (
                                                        <React.Fragment key={index}>
                                                            <div className='p-2.5'
                                                                style={{
                                                                    background: parseInt(roundId) === index + 1 ? '#F0AA0C' : '#fff',
                                                                    borderRadius: 10
                                                                }}
                                                            >
                                                                <div className='flex items-center'>
                                                                    <span className="dot" ></span>
                                                                    <p className={`pl-2.5 text-sm ${parseInt(roundId) === index + 1 ? 'text-[#fff]' : 'text-[#F0AA0C]'}`}>
                                                                        {round}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {index !== rounds.length - 1 && <hr style={{ border: 'none', borderBottom: '1px solid #DBDBDB', width: '20%' }} />}
                                                        </React.Fragment>
                                                    ))}
                                                </div>

                                            </Col>
                                        </Row>



                                        <Row gutter={30} style={{ paddingTop: 40 }}>
                                            <Col md={4}>
                                                <p className='text-[#114D89] text-lg font-light pl-2.5 pb-2.5'>Choose Subject</p>
                                                <Form.Item name="subject_id" >
                                                    <Select
                                                        placeholder="Select Subject"
                                                        onChange={onSubjectChange}
                                                        style={{ width: '100%', borderRadius: 8, boxShadow: '0px 3px 6px #00000029' }}
                                                        disabled={levelSelected || beginClicked}
                                                    >

                                                        {subjects.map((subject, index) => (
                                                            <Option key={index} value={`subject${index + 1}`}>{subject}</Option>
                                                        ))}

                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col md={15}>
                                                {showPassageReading ? (
                                                    <div>
                                                        {loading ? (
                                                            <div className="loader">
                                                                Loading...
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <div className='reading flex justify-between'>
                                                                    <p className='text-base text-[#fff] font-light'>{formatTime(seconds)}</p>
                                                                    <Space>
                                                                        {/* <Button className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }} onClick={handlePauseClick}>
                                                                            PAUSE
                                                                        </Button> */}
                                                                        <Button disabled={!testPlay} onClick={startTest} className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }} >
                                                                            PLAY
                                                                        </Button>
                                                                        <Button disabled={!testStarted} onClick={() => { stopTest();  }} className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }} htmlType="submit">
                                                                            DONE
                                                                        </Button>
                                                                    </Space>
                                                                    <Modal className='quiz-modal' title="" open={modalVisible} footer={null} onCancel={() => setModalVisible(false)}>
                                                                        <div className='flex justify-center items-center'>
                                                                            <img src='/images/prize.png' alt='prize-image' style={{ width: 150, height: 150 }} />
                                                                        </div>
                                                                        <p className='text-[#000000] text-xl text-center mb-7'>Your Reading Speed: {readingSpeed} WPM</p>
                                                                        {/* <p className='text-[#000000] text-lg text-center px-5'>You attempted <span className='text-[#114D89] text-'>20 questions</span> out of which <span className='text-[#F0AA0C]'>18 answers</span> are correct.</p> */}
                                                                        <div className='flex justify-center items-center pt-5'>
                                                                            {/* <Button className='text-[#fff] bg-[#F0AA0C] flex justify-center items-center' style={{ borderRadius: 21 }}>
                                                                                Okay
                                                                            </Button> */}
                                                                        </div>
                                                                    </Modal>
                                                                </div>
                                                                <div className='reading-space pt-5'>
                                                                    {roundId === 2 && playClicked ? (
                                                                        <div className="flex justify-center py-16" dangerouslySetInnerHTML={{ __html: (currentWords.join(" ")) }} />

                                                                    ) : (
                                                                        <div  className='text-[#60666B] text-sm pb-20' dangerouslySetInnerHTML={{ __html: passage }} />
                                                                    )}
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <>{showQuiz ? ('') :(<><div className='reading-space pt-5'>
                                                    <p className='text-[#60666B] text-sm pb-20'>Please Select The Level and Subject, then click on the Begin button.</p>
                                                </div>
                                                <div className='reading flex justify-end'>
                                                    <Button className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }} onClick={handleBeginClick}>
                                                        Begin
                                                    </Button>
                                                </div></>)}
                                                        
                                                    </>
                                                )}

                                            {showQuiz && questions.length > 0 ? (<>
                                                    <div className='reading-space pt-5 '>
                                                        <div className='flex justify-end pb-5'>
                                                            <img src='/images/clock.png' style={{ width: '20px', height: '20px' }} />
                                                            <span className='pl-2.5 text-[#114D89]'>{formatQuizTime(quizSeconds)}</span>
                                                        </div>
                                                        <div className='text-[#062646] text-base pb-5 flex'>
                                                            {/* <span className='pr-1.5'>Q{questions[currentQuestion]?.id}:</span> */}
                                                            <span dangerouslySetInnerHTML={{ __html: questions[currentQuestion]?.question }} />
                                                        </div>

                                                        <Form form={form}>
                                                            <Form.Item name={`question-${questions[currentQuestion].id}`} rules={[{ required: true }]}>

                                                                <Radio.Group className='pb-5'  style={{ display: 'block' }}>
                                                                    {questions[currentQuestion].options.map((option, index) => (
                                                                        <Radio onChange={(e) => handleOptionClick(index, questions[currentQuestion].id, formatQuizTime(quizSeconds) , questions[currentQuestion].correct_answer)}
                                                                        key={option} value={option} className='options mb-2.5' >
                                                                             {option}
                                                                        </Radio>
                                                                    ))}
                                                                </Radio.Group>

                                                            </Form.Item>
                                                        </Form>


                                                    </div>
                                                    <div className='reading flex justify-center'>
                                                        <Space>
                                                            {currentQuestion < questions.length - 1 && (
                                                                <Button className='text-white font-light bg-[#F8B133]' style={{ borderRadius: 10, border: '1px solid #F5C964' }} onClick={handleNextQuestion}>
                                                                    NEXT
                                                                </Button>
                                                            )}
                                                            {currentQuestion === questions.length - 1 && (
                                                                <Button className='text-white font-light bg-[#F8B133]' style={{ borderRadius: 10, border: '1px solid #F5C964' }} onClick={handleQuizSubmit}>
                                                                    DONE
                                                                </Button>
                                                            )}
                                                        </Space>

                                                    </div></>
                                                ) : null}
                                            </Col>

                                            <Col md={5}>
                                                <p className='text-[#114D89] text-lg font-light pl-2.5 pb-2.5'>Choose Level</p>
                                                <div className='level'>
                                                    <Form.Item name="level_id">
                                                        <Radio.Group className='custom-level'
                                                            onChange={onLevelChange} 

                                                            disabled={levelSelected || beginClicked}>

                                                            {levelOptions.map((option, index) => (
                                                                <Radio key={index} value={option.value} className='choose-level'>
                                                                    {option.label}
                                                                </Radio>
                                                            ))}
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    

                                                    <p className='text-[#114D89] text-lg font-light pl-2.5 pb-2.5'>Select Passage</p>
                                                    <Form.Item name="passage_id" >
                                                        <Select
                                                            placeholder="Select Passage"
                                                            onChange={onPassageChange}
                                                            style={{ width: '100%', borderRadius: 8, boxShadow: '0px 3px 6px #00000029' }}
                                                        >

                                                         
                                                            {passageList.map((option, index) => (
                                                                <Radio key={index} value={option.value} >
                                                                    {option.label}
                                                                </Radio>
                                                            ))}

                                                        </Select>
                                                    </Form.Item>
                                                    
                                                </div>

                                                

                                                
                                            </Col>

                                           
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>


                    <Modal className='quiz-modal' title="" open={quizmodalVisible} footer={null} onCancel={() => setQuizModalVisible(false)}>
                        <div className='flex justify-center items-center'>
                            <img src='/images/prize.png' alt='prize-image' style={{ width: 150, height: 150 }} />
                        </div>
                        <p className='text-[#000000] text-xl text-center mb-7'>Congratulations, you have submitted the quiz.</p>
                        <p className='text-[#000000] text-xl text-center mb-7'>You have answered {correctQuestion} out of {responses.length} questions correctly.</p>
                        <div className='flex justify-center items-center pt-5'>
                            <Button className='text-[#fff] bg-[#F0AA0C] flex justify-center items-center' style={{ borderRadius: 21 }}>
                                Okay
                            </Button>
                        </div>
                    </Modal>


                </Content>

            </DashboardLayout>
        </>
    )
}




export default ReadingToolPractice