import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Skeleton, Button, Row, Col, Tabs, Timeline, Modal, Input, Layout } from 'antd';

import Header from "../components/header";
import CounsellorLayout from "./counsellor-layout";
import Footer from "../components/footer";
import { useParams, useNavigate } from "react-router-dom";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { api_url, studentPath } from '../components/constants';
const { TextArea } = Input;
const { Content } = Layout;

const RecordTimeline = () => {
    const { id } = useParams();

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [pbsData, setPBSData] = useState([{ id: "", date: "", time: "", status: "", mentor: "", link: "", test_records: "", academic_records: "", internship_records: "", suggestion_records: "" }]);
    const [testData, setTestData] = useState(null);
    const [academicData, setAcademicData] = useState(null);
    const [internData, setInternData] = useState(null);
    const [suggestioData, setSuggestionData] = useState(null);
    const [studentData, setStudentData] = useState({
        name: '', form_number: '', course_name: '', profile_picture: '', batch: '', dob: '', class: '', school: '', address: '', father_name: '', mother_name: '', father_occupation: '', mother_occupation: '',
    })
    useEffect(() => {
        pbsTimeline()
        fetchUserData()
    }, [])

    const fetchUserData = async () => {

        await axios.post(api_url + 'user/get_profile_data', {
            user_id: decrypt(id, encriptionKey)

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const user_info = [res.data.user_info];

            const stArray = user_info.map(item => ({
                'user_id': item.user_id,
                'name': decrypt(item.name, encriptionKey),
                'center': item.cemter,
                'email': decrypt(item.email, encriptionKey),
                'form_number': decrypt(item.form_number, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'mother_name': decrypt(item.mother_name, encriptionKey),
                'father_name': decrypt(item.father_name, encriptionKey),
                'dob': decrypt(item.dob, encriptionKey),
                'city': decrypt(item.city, encriptionKey),
                'psychrometric_report': item.psychrometric_report,
                'batch': item.batch,
                'father_occupation': item.father_occupation,
                'mother_occupation': item.mother_occupation,
                'diagnostic_report': item.diagnostic_report,
                'admission_date': item.admission_date,
                'address': item.address,
                'class': decrypt(item.class, encriptionKey),
                'course': decrypt(item.course, encriptionKey),
                'school': item.school,
                'photo': item.photo,

            }));
            setStudentData(stArray[0]);
            setLoading(false)

        })
    }

    const pbsTimeline = () => {
        axios.post(api_url + 'student-pbs-timeline', {
            user_id: id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            if (res.data.pbs_data.length > 0) {

                const st_list = res.data.pbs_data;
                const test_target = res.data.test_target_array;
                const academic_target = res.data.academic_target_array;
                const st_intern_data = res.data.st_intern_array;
                const st_suggestion_array = res.data.st_suggestion_array;
                if (academic_target.length > 0) {
                    setAcademicData(JSON.parse(academic_target))
                }
                if (test_target.length > 0) {
                    setTestData(JSON.parse(test_target));
                }
                if (st_intern_data.length > 0) {
                    setInternData(JSON.parse(st_intern_data))
                }
                if (st_suggestion_array.length > 0) {
                    setSuggestionData(JSON.parse(st_suggestion_array))
                }



                setPBSData(st_list);


            }

        })
    }

    const items = [
        {
            key: '1',
            label: `STUDENT TARGETS`,
            children: <AcademicTarget data={academicData} />,
        },
        {
            key: '2',
            label: `ACADEMICS TARGETS`,
            children: <StudentTarget data={testData} />,
        },
        {
            key: '3',
            label: `INTERNSHIP ASSISTANCE`,
            children: <InternshipAssistance data={internData} />,
        },
        {
            key: '4',
            label: `PROFILE BUILDING SUGGESTIONS`,
            children: <ProfileBuilding data={suggestioData} />,
        },
    ];


    return (
        <>
            <CounsellorLayout>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mx-0 my-0 form-layout form-space">
                        <Row>
                            <Col md={24} xs={24}>
                                <h1 className="bg-[#0f4275] text-white py-2.5 px-5 text-xl font-medium">Student Information</h1>
                            </Col>
                        </Row>
                        {loading ? (
                            <div className="loaders"><img src='/images/loader.gif' /></div>
                        ) : (
                            <>
                                <div className="mx-7 my-10">

                                    <Row gutter={30}>
                                        <Col md={6}>
                                            <div className="profile-pic">
                                                {studentData.photo === null || studentData.photo === "" ? (
                                                    <img src='/images/avatar.png' className="profile-pic flex justify-start items-center" alt="user avatar" />
                                                ) : (
                                                    <img src={studentPath + studentData.photo} className="profile-pic flex justify-start items-center" alt="user avatar" />
                                                )}
                                            </div>
                                        </Col>
                                        <Col md={18}>
                                            <Row gutter={30}>
                                                <Col md={8}>
                                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Form Number :</strong> {studentData.form_number}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Admission Date :</strong> {studentData.admission_date}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Center Name :</strong> {studentData.center}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Name :</strong> {studentData.name}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Mobile :</strong> {studentData.mobile}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Email :</strong> {studentData.email}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p className="mb-5 text-base text-[#6c757d]"><strong>DOB :</strong> {studentData.dob}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Course :</strong> {studentData.course}</p>
                                                </Col>
                                                <Col md={8}>
                                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Class :</strong> {studentData.class}</p>
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>

                                </div>
                                <div className="mx-5">
                                    <Tabs defaultActiveKey="1" items={items} />
                                </div>
                            </>
                        )}
                    </div>
                </Content>

            </CounsellorLayout>

        </>
    )
}

const StudentTarget = (props) => {

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (


                                <Timeline.Item key={index} label={row.date}>
                                    <div className="bg-white p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Exam</th>
                                                        <th>Your Achivement</th>
                                                        <th>Assign Target</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.test_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.test}</td>
                                                            <td>{value.achive}</td>
                                                            <td>{value.assign}</td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}

                    </Timeline>

                </Col>
            </Row>
        </>

    )
}

const AcademicTarget = (props) => {

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={row.date}>
                                    <div className="bg-white p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table" >
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Class</th>
                                                        <th>Your Achivement</th>
                                                        <th>Assign Target</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.academic_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.grade}</td>
                                                            <td>{value.achive}</td>
                                                            <td>{value.assign}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}
                    </Timeline>
                </Col>
            </Row>
        </>
    )
}

const InternshipAssistance = (props) => {

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={row.date}>
                                    <div className="bg-white p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Suggestion</th>
                                                        <th>Subject</th>
                                                        <th>Deadline</th>
                                                        <th>Remark</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.intern_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.suggestion}</td>
                                                            <td>{value.subject}</td>
                                                            <td>{value.deadline}</td>
                                                            <td>{value.remark}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}

                    </Timeline>
                </Col>
            </Row>
        </>

    )
}

const ProfileBuilding = (props) => {
    const [modalVisible, setIsModalVisible] = useState(false);
    return (
        <>
            <Row>
                <Col md={24} className='form-space'>
                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={row.date}>
                                    <div className="bg-white p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th width='20%'>Type</th>
                                                        <th width='30%'>Suggestion</th>
                                                        <th width='30%'>Student Output</th>
                                                        <th width='20%'>Follow Up Date</th>


                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {row.suggestion_data.map((value, k) => (


                                                        <tr key={k}>
                                                            <td>{value.type}</td>
                                                            <td>{value.suggestion}</td>
                                                            <td>{value.output}</td>
                                                            <td>{value.deadline}</td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}
                    </Timeline>
                </Col>
            </Row>
        </>

    )
}


export default RecordTimeline