import React, { useEffect, useState } from 'react';
import '../styles/faculty.css';
import axios from 'axios';
import { Layout, Button, Form, Col, Row, Input, DatePicker, Table, Modal } from 'antd';
import TeacherLayout from "../components/teacher-layout";
import { useParams, useNavigate } from "react-router-dom";
import { DownloadOutlined } from '@ant-design/icons';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { api_url, base_url } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
const { Content } = Layout;
const { RangePicker } = DatePicker;

const AttendanceReport = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const login_user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(true);
    const [classList, setClassList] = useState([]);
    const [studentData, setStudentData] = useState({ 'username': '', 'course': '', 'batch': '', 'form_number': '' });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [studentUniqueData, setUniqueStudentData] = useState({ 'key' : '', 'joinTime' : '', 'endTime': '' });
    const [loadingSt, setLoadingSt] = useState(true);
    

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;


    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
        },
        {
            key: "2",
            title: "Class topic with Class no.",
            dataIndex: "topic",
            align: 'center'
        },
        {
            key: "3",
            title: "Faculty",
            dataIndex: "faculty",
            align: 'center'
        },

        {
            key: "4",
            title: "Class Date & Time",
            dataIndex: "date_time",
            align: 'center'
        },
        {
            key: "5",
            title: "Class Duration(Min)",
            dataIndex: "class_duration",
            align: 'center'
        },
        // {
        //     key: "8",
        //     title: "Join Time",
        //     dataIndex: "join_time",
        //     align: 'center'
        // },
        // {
        //     key: "9",
        //     title: "Leave Time",
        //     dataIndex: "leave_time",
        //     align: 'center'
        // },
        {
            key: "6",
            title: "Present Duration(Min)",
            dataIndex: "duration",
            align: 'center',
            render: (duration, record) => (
                <a   onClick={() => {  handleColumnClick(record); }}   style={{ color: "#166fc7",cursor: 'pointer' }}>
                    {duration}
                </a>
            ),
        },
        {
            key: "7",
            title: "Status(A/P)",
            dataIndex: "status",
            align: 'center'
        },

    ];

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const searchItems = (searchValue) => {

        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = classList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(classList)
        }
    }


    const fetchAttendanceList = async () => {

        await axios.post(api_url + 'faculty/get-student-attendance', {
            faculty_id: user_data.id

        }).then((res) => {
            //console.log(res.data.live_class);
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const class_list = res.data.class_info;
            console.log(class_list);
            const k = class_list.map((item, index) =>
            ({
                'sno': index + 1,
                'course': item.course,
                'faculty': decrypt(item.faculty, encriptionKey),
                'topic': item.class_name,
                'date_time': item.date + ' ' + item.start_time,
                'join_time': item.join_time,
                'leave_time': item.leave_time,
                'duration': item.duration,
                'class_duration': item.class_duration,
                'batch': item.batch,
                'status': item.status,
                'meeting_id': item.meeting_id,
                'user_id': item.user_id,

            })
            );


            setClassList(k);
            setLoading(false)

        })
    }
    useEffect(() => {
        //fetchAttendanceList()
    }, [])

    const [formValues, setFormValues] = useState({
        formNo: '',
        dateRange: [],
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleDateRangeChange = (dates) => {
        setFormValues({ ...formValues, dateRange: dates });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(api_url + 'faculty/get-student-attendance', {
            record: formValues

        }).then((res) => {


            if (res.data.class_info) {
                const class_list = res.data.class_info;
                const k = class_list.map((item, index) =>
                ({
                    'sno': index + 1,
                    'course': item.course,
                    'faculty': decrypt(item.faculty, encriptionKey),
                    'topic': item.class_name,
                    'date_time': item.date + ' ' + item.start_time + ' to ' + item.end_time,
                    'join_time': item.join_time,
                    'leave_time': item.leave_time,
                    'duration': Math.round(item.total_duration / 60),
                    'class_duration': item.class_duration,
                    'batch': item.batch,
                    'status': item.status,
                    'meeting_id': item.meeting_id,
                    'user_id': item.user_id,

                })
                );

                setClassList(k);

                setStudentData(res.data.user_info);
                setLoading(false)
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }


        })
    };

    const handleClear = () => {
        // Clear the form fields
        setFormValues({
            formNoOrStudentName: '',
            dateRange: [],
        });
    };

    const handleColumnClick = (record) => {
        axios.post(api_url + 'faculty/get-unique-student-attendance', {
            user_id: record.user_id,
            meeting_id : record.meeting_id,

        }).then((res) => {

           
            if (res.data.class_info) {
                setUniqueStudentData(res.data.class_info);
                setLoadingSt(false);
                setIsModalVisible(true);
            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }


        })
        // 
    };

    const durationColumns = [
        {
            title: 'Join Time',
            dataIndex: 'joinTime',
            key: 'joinTime',

        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',

        },
    ];

    const dataSource = [
        {
            key: '1',
            joinTime: '9:00am',
            endTime: '10:00am',
        },
        {
            key: '2',
            joinTime: '9:00am',
            endTime: '10:00am',
        },
    ];

    return (
        <>
            <TeacherLayout>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >

                    <div className="mx-16 mt-7" style={{ border: '1px solid #E5E5E5' }}>
                        <Row>
                            <Col md={24}>
                                <div className=' bg-[#114D89] px-5 py-2.5'>
                                    <h1 className="text-white text-lg">Student Attendance Report</h1>
                                </div>
                            </Col>
                        </Row>

                        <div className='bg-[#F1F3F6]'>
                            {/* <Row gutter={30} style={{ padding: '20px 30px' }}>
                                <div className='flex justify-between'>
                                    <div className='flex'>
                                        <Input  placeholder="Form No/ Student Name" className='mx-2.5' style={{ width: 200 }} />
                                        <RangePicker className='mx-2.5' />
                                        <Button className="bg-[#205584] mx-2.5 text-white" htmlType='search' >Search</Button>
                                        <Button className="bg-[#205584] mx-2.5 text-white" htmlType='clear' >Clear Filter</Button>
                                    </div>
                                    <DownloadOutlined className='text-lg' />
                                </div>
                            </Row> */}

                            <form onSubmit={handleSubmit}>
                                <Row gutter={30} style={{ padding: '20px 30px' }}>
                                    <div className='flex justify-between'>
                                        <div className='flex'>
                                            <Input
                                                name="formNo"
                                                placeholder="Form No/ Student Name"
                                                className='mx-2.5'
                                                style={{ width: 200 }}
                                                value={formValues.formNo}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {/* <DatePicker.RangePicker
                                                className='mx-2.5'
                                                value={formValues.dateRange}
                                                onChange={handleDateRangeChange}
                                            /> */}
                                            <Button htmlType="submit" className="bg-[#205584] mx-2.5 text-white"> Search</Button>
                                            {/* <Button type="button" className="bg-[#205584] mx-2.5 text-white" onClick={handleClear}>Clear Filter
                                            </Button> */}
                                        </div>
                                        {/* <DownloadOutlined className='text-lg' /> */}
                                    </div>
                                </Row>
                            </form>
                            {!loading ? (<Row>
                                <Col md={24}>
                                    <div className='bg-[#FFFDDC] p-2.5 mx-2.5 my-5 flex' style={{ border: '1px solid #E9E9E9' }}>
                                        <p className='text-sm text-[#707070] px-2.5'><strong className='text-[#0C3762]'>Student Name : </strong>{decrypt(studentData.username, encriptionKey)}</p>
                                        <p className='text-sm text-[#707070] px-2.5'><strong className='text-[#0C3762]'>Form Number : </strong>{decrypt(studentData.form_number, encriptionKey)}</p>
                                        <p className='text-sm text-[#707070] pl-2.5'><strong className='text-[#0C3762]'>Center : </strong>{studentData.center}</p>
                                        <p className='text-sm text-[#707070] pl-2.5'><strong className='text-[#0C3762]'>Course Name : </strong>{studentData.course}</p>
                                        <p className='text-sm text-[#707070] pl-2.5'><strong className='text-[#0C3762]'>Batch : </strong>{studentData.batch}</p>
                                        {/* <p className='text-sm text-[#707070] pl-2.5'><strong className='text-[#0C3762]'>Total Class:</strong>25</p>
                                        <p className='text-sm text-[#707070] pl-2.5'><strong className='text-[#0C3762]'>Total present:</strong>25</p>
                                        <p className='text-sm text-[#707070] pl-2.5'><strong className='text-[#0C3762]'>Total absent:</strong>0</p> */}
                                    </div>
                                </Col>
                            </Row>) : ('')}


                            <Row>
                                <Col md={24}>
                                    <Table
                                        className="attendance mx-2.5"
                                        bordered
                                        columns={columns}
                                        //pagination={tableParams.pagination}
                                        dataSource={classList}
                                    />
                                    <Modal
                                        title="Present Duration Details"
                                        open={isModalVisible}
                                        onCancel={() => setIsModalVisible(false)}
                                        footer={null}
                                    >

                                        <Table dataSource={studentUniqueData} columns={durationColumns} />
                                    </Modal>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Content>
            </TeacherLayout>
        </>
    )
}

export default AttendanceReport