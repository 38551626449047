import React, { useEffect, useState } from 'react';
import '../styles/faculty.css';
import axios from 'axios';
import { Layout, Form, Col, Row, Input, Spin } from 'antd';
import { BackwardOutlined } from '@ant-design/icons';
import DashboardLayout from '../components/dashboard-layout';
import { useParams, useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
const { Content } = Layout;
const { TextArea } = Input;

const ViewPerformanceReport = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({ username: "", centername: "", classname: "", coursename: "", email: '' });
    const [reportColumn, setReportColumn] = useState({ id: "", user_id: "", month: "", report_data: "", created_by: "" });
    const [reportData, setReportData] = useState({
        month: '', classes: '', present: '', absent: '', remark: '', communication: '', participation: '', presentation_skills: '', assignment: '', behaviour: '', performance: "", grammar: "",
        noun_score: '', pronoun_score: '', verb_score: '', adjective_score: '', adverb_score: '', improvement: '', gen_remarks: '',
    });

    const fetchPerfomanceDetails = async () => {

        await axios.post(api_url + 'faculty/get_performance_data', {
            id: id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }

            const report_list = res.data.report_data;

            setReportColumn(report_list);
            const obj = JSON.parse(report_list.report_data);
            const st_list = res.data.user_data;
            setReportData(obj);
            setUserData(st_list);
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchPerfomanceDetails()
    }, [])


    const onBackRequest = (record) => {
        navigate('/student-dashboard/performance-report')
    }

    return (
        <DashboardLayout>
            <Content
                className="site-layout-background"
                style={{
                    padding: 20,
                    background: '#fff',
                    width: '100%'
                }}
            >
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <div className="mt-1 mb-10" style={{ border: '1px solid #E5E5E5' }}>
                        <Row>
                            <Col md={24} xs={24}>
                                <div className='flex bg-[#114D89] px-5 py-2.5 justify-between '>
                                    <div className="">
                                        <h1 className="text-white text-xl text-center">Student Performance Report</h1>
                                        <span className='text-sm text-white text-center'>For the month of April & May</span>
                                    </div>
                                    <div>

                                    </div>
                                    <BackwardOutlined onClick={onBackRequest} className='text-white text-3xl flex items-center justify-center' />
                                </div>
                            </Col>

                        </Row>
                        <div className='bg-[#F1F3F6] student-report-info'>
                            <Row gutter={30} style={{ padding: '20px 30px' }}>
                                <Col md={24} xs={24} className="report-top-section" style={{ border: '1px solid #ccc', borderBottom: 'none' }}>
                                    <Row gutter={30}>
                                        <Col md={12} xs={24} style={{ borderBottom: '1px solid #ccc' }}>
                                            <div className='flex py-1.5'>
                                                <h3 className='text-base text-[#0C3762] font-bold'>Name :-</h3>
                                                <span className='report-list text-base text-[#787878] font-medium'>{decrypt(userData.username, encriptionKey)}</span>
                                            </div>
                                        </Col>
                                        <Col md={12} xs={24} style={{ borderBottom: '1px solid #ccc' }}>
                                            <div className='flex py-1.5 '>
                                                <h3 className='text-base text-[#0C3762] font-bold'>Contact No :-</h3>
                                                <span className='report-list text-base text-[#787878] font-medium'>{decrypt(userData.mobile, encriptionKey)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col md={12} xs={24} style={{ borderBottom: '1px solid #ccc' }}>
                                            <div className='flex py-1.5'>
                                                <h3 className='text-base text-[#0C3762] font-bold'>Centre :-</h3>
                                                <span className='report-list text-base text-[#787878] font-medium'>{userData.centername}</span>
                                            </div>
                                        </Col>
                                        <Col md={12} xs={24} style={{ borderBottom: '1px solid #ccc' }}>
                                            <div className='md:flex py-1.5'>
                                                <h3 className='text-base text-[#0C3762] font-bold'>Email id :-</h3>
                                                <span className='report-list text-base text-[#787878] font-medium'>{decrypt(userData.email, encriptionKey)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col md={12} xs={24} style={{ borderBottom: '1px solid #ccc' }}>
                                            <div className='flex py-1.5'>
                                                <h3 className='text-base text-[#0C3762] font-bold'>Class :-</h3>
                                                <span className='report-list text-base text-[#787878] font-medium'>{decrypt(userData.classname, encriptionKey)}</span>
                                            </div>
                                        </Col>
                                        <Col md={12} xs={24} style={{ borderBottom: '1px solid #ccc' }}>
                                            <div className='flex py-1.5'>
                                                <h3 className='text-base text-[#0C3762] font-bold'>Month Report :-  </h3>
                                                <span className='report-list    text-base text-[#787878] font-medium'>{reportData.month}</span>
                                            </div>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </div>

                        <Form>
                            {/* <div>
                                <Row>
                                    <Col md={24}>
                                        <h2 className='text-[#0C3762] text-lg p-5 text-center font-bold'>REPORT OF MONTH</h2>
                                    </Col>
                                </Row>
                                <div className='bg-[#F1F3F6] py-5 px-7'>
                                    <div className='attendance py-5'>
                                        <Row>
                                            <Col md={6}>
                                                <h4 className='text-[#0C3762] font-bold'>Month</h4>
                                                <p>{reportData.month}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div> */}

                            <div>

                                <div className='bg-[#F1F3F6] pt-5 px-5 report-block' >
                                    <Row>
                                        <Col md={24}>
                                            <h2 className='text-[#0C3762] text-lg mb-3 text-center font-bold'>MONTHLY ATTENDANCE</h2>
                                        </Col>
                                    </Row>
                                    <div className='attendance pt-5 px-5' style={{ borderradius: '5px', background: '#fae3b7' }}>
                                        <Row gutter={30}>
                                            <Col md={6} xs={24}>
                                                <h4 className='text-base text-[#0C3762] font-bold'>Classes</h4>
                                                <p className=''>{reportData.classes}</p>
                                            </Col>
                                            <Col md={6} xs={24}>
                                                <h4 className='text-base text-[#0C3762] font-bold'>Total Present</h4>
                                                <p>{reportData.present}</p>
                                            </Col>
                                            <Col md={6} xs={24}>
                                                <h4 className='text-base text-[#0C3762] font-bold'>Total Absent</h4>
                                                <p>{reportData.absent}</p>
                                            </Col>
                                            <Col md={6} xs={24}>
                                                <h4 className='text-base text-[#0C3762] font-bold'>Remark*</h4>
                                                <p>{reportData.remark}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>


                            <div className='bg-[#F1F3F6] pt-10 px-5 report-block'>
                                <Row>
                                    <Col md={24}>
                                        <h2 className='text-[#0C3762] text-lg mb-3 text-center font-bold'>PERFORMANCE EVALUATION</h2>
                                    </Col>
                                </Row>
                                <div className='evaluation pt-5 px-5' style={{ borderradius: '5px', background: '#fae3b7' }}>
                                    <Row gutter={30}>
                                        <Col md={6} xs={24}>
                                            <h4 className='text-base text-[#0C3762] font-bold'>Communication</h4>
                                            <p>{reportData.communication}</p>
                                        </Col>
                                        <Col md={6} xs={24}>
                                            <h4 className='text-base text-[#0C3762] font-bold'>Class Participation</h4>
                                            <p>{reportData.participation}</p>
                                        </Col>
                                        <Col md={6} xs={24}>
                                            <h4 className='text-base text-[#0C3762] font-bold'>Presentation Skills</h4>
                                            <p>{reportData.presentation_skills}</p>
                                        </Col>
                                        <Col md={6} xs={24}>
                                            <h4 className='text-base text-[#0C3762] font-bold'>Home Assignment</h4>
                                            <p>{reportData.assignment}</p>
                                        </Col>
                                        <Col md={6} xs={24}>
                                            <h4 className='text-base text-[#0C3762] font-bold'>Grammar</h4>
                                            <p>{reportData.grammar}</p>
                                        </Col>
                                        <Col md={6} xs={24}>
                                            <h4 className='text-base text-[#0C3762] font-bold'>Class Behaviour</h4>
                                            <p>{reportData.behaviour}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div>

                                <div className='bg-[#F1F3F6] pt-10 px-5 report-block'>
                                    <Row>
                                        <Col md={24}>
                                            <h2 className='text-[#0C3762] text-lg mb-3 text-center font-bold'>TEST RESULT</h2>
                                        </Col>
                                    </Row>
                                    <div className='evaluation'>
                                        <Row>
                                            <Col md={24} xs={24}>
                                                <div className="report-table-outer">
                                                    <table className='test-result'>
                                                        <thead>
                                                            <tr>
                                                                <th>Topics</th>
                                                                <th>Total</th>
                                                                <th>Score</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className='topic'>Noun</td>
                                                                <td>500</td>
                                                                <td>{reportData.noun_score}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='topic'>Pronoun</td>
                                                                <td>500</td>
                                                                <td>{reportData.pronoun_score}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='topic'>Verbs</td>
                                                                <td>500</td>
                                                                <td>{reportData.verb_score}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='topic'>Adjective</td>
                                                                <td>500</td>
                                                                <td>{reportData.adjective_score}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='topic'>Adverb</td>
                                                                <td>500</td>
                                                                <td>{reportData.adverb_score}</td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td></td>
                                                                <td className='score'>Grand Total 2500</td>
                                                                <td className='score'>Total Scored 2500</td>
                                                            </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='mt-7 bg-[#F1F3F6] p-5 '>
                                    <Row>
                                        <Col md={24}>
                                            <h2 className='text-[#0C3762] text-lg font-bold mb-3'>IMPROVEMENT SINCE JOINING</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <p className='text-msg-improvement'>{reportData.improvement}</p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='mt-7 bg-[#F1F3F6] p-5'>
                                    <Row>
                                        <Col md={24}>
                                            <h2 className='text-[#0C3762] text-lg font-bold mb-3'>GENERAL REMARKS</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <p className='text-msg-improvement'>{reportData.gen_remarks}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Form>
                    </div>
                )}
            </Content>
        </DashboardLayout>
    )
}

export default ViewPerformanceReport