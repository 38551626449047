import React, { useState } from "react";
import { Button, Modal, } from 'antd';
import Header from '.././components/header';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import { Link } from 'react-router-dom';
import { encriptionKey, encrypt, decrypt } from "../components/utils";

const DiagnosticReport = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    return (
        <div className="template">
            <Header />
            <div className="pyscho-report">
                <div className="page-wrapper">
                    <div className="page-corners">
                        <div className="report-frame">
                            <TopBanner />
                            <div>
                                <h1 className="text-[#0F4275] text-2xl flex justify-center text-center mb-16">Student Diagnostic Report</h1>
                            </div>
                            <div className="container" style={{ border: '2px dashed #F0AA0C' }}>
                                <div className="flex justify-between md:flex-row flex-col py-5 px-10">
                                    <div className="text-lg text-[#646464] leading-10">
                                        <p>Student Name : {decrypt(user_data.name, encriptionKey)}</p>
                                        <p>Form Number : {decrypt(user_data.form_number, encriptionKey)}</p>
                                        <p>Class : {decrypt(user_data.class, encriptionKey)}</p>
                                        <p>Report Name : Diagnostic Report</p>
                                    </div>
                                    <div>
                                        <div className="flex justify-center">
                                            <img src='/images/pdf-icon.png' alt="pdf" />
                                        </div>

                                        {user_data.diagnostic_report != null ? (
                                            <div className="flex justify-center mt-5">
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    className="text-lg text-[#fff] bg-[#0D3761] rounded-lg pb-1.5 px-5 flex justify-center mr-2.5"
                                                    style={{ height: 'auto' }}
                                                >
                                                    View
                                                </Button>
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    className="text-lg text-[#0D3761] bg-[#FCC222] rounded-lg pb-1.5 px-5 flex justify-center"
                                                    style={{ height: 'auto' }}
                                                >
                                                    Download
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="flex justify-center mt-5">
                                                <Button type="primary" htmlType="submit"
                                                    className="text-lg text-[#fff] bg-[#0D3761] rounded-lg pb-1.5 px-5 flex justify-center mr-2.5"
                                                    style={{ height: 'auto' }}
                                                    onClick={() => window.open('https://agsdibt.allen.ac.in/candidate/login', '_blank')}
                                                >Start Test</Button>
                                                <a className="text-sm pt-2.5 font-light text-[#FCC222]" href='/student-dashboard/dashboard'>Skip</a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center mt-10 mb-24">
                                <Link type="primary"
                                    // onClick={popPage('student-profile-report')}
                                    to={'/student-portal/student-profile-report'}
                                    htmlType="submit"
                                    className="text-lg text-[#fff] bg-[#0D3761] rounded-lg py-1.5 px-5 flex justify-center mr-2.5"
                                    style={{ height: 'auto' }}
                                >
                                    Back
                                </Link>
                                <Link type="primary"
                                    to={'/student-dashboard/dashboard'}
                                    htmlType="submit"
                                    className="text-lg text-[#0D3761] bg-[#FCC222] rounded-lg py-1.5 px-5 flex justify-center"
                                    style={{ height: 'auto' }}
                                >
                                    Continue
                                </Link>
                                <Modal header={null}
                                    open={modalVisible}
                                    onCancel={() => setModalVisible(false)}
                                    footer={null}
                                    width={450}
                                    centered
                                    bodyStyle={{ background: '#0D3761', borderRadius: 8 }}
                                // style={{ border: '1px dashed #F5F5F5' }}
                                >
                                    <div className="px-16 py-20">
                                        <h3 className="text-4xl text-white text-center pb-5">Thank You !</h3>
                                        <p className="text-xl text-white text-center pb-7">Your response has been saved successfully.</p>
                                        <div className="flex justify-center">
                                            <Button type="primary"
                                                htmlType="submit"
                                                className="text-lg text-white bg-[#FCC222] rounded-lg pb-1.5 px-5 flex justify-center"
                                                style={{ height: 'auto' }}
                                            >
                                                Schedule PBS Session
                                            </Button>

                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )

}

export default DiagnosticReport