import React, { useEffect, useState,useRef } from 'react';
import '../styles/dashboard.css';
import { Col, Row, Layout, Button, Form, Input, Modal, Popover} from 'antd';
import CounsellorLayout from "./counsellor-layout";
import axios from 'axios';
import { api_url, studentPath,counsellorPath } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from "react-router-dom";
import { encrypt, decrypt, encriptionKey } from '../components/utils';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Content } = Layout;



const Documents = () => {
    const { docid } = useParams();
    const [loading, setLoading] = useState(true);
  
    const [content, setContent] = useState('');
    const [historyContent, setContentHistory] = useState('');
    const [commentContent, setCommentContent] = useState('');
    const [worldLimit, setWordLength] = useState(0);
    const [selectedWorld, setSelectedWord] = useState(0);
    
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

  
    const fetchDocumentData = () => {

        axios.post(api_url + 'application/get_document_content', {
            document_id: docid,
        }).then((res) => {
            
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const content_data = res.data.data;
            const history = res.data.history;
            if(content_data){
                setContent(content_data.content);
                const words = content_data.content.trim().split(/\s+/);
                setWordLength(words.length);
            }

            if(history){
                setContentHistory(history);
            }
            
            
            setLoading(false)

        })
    }
    useEffect(() => {
       
        fetchDocumentData()
    }, [])

    const onFinish = (values) => {
        
        axios.post(api_url + 'application/update_document_content', {
            document_id: docid,
            created_by: user_data.id,
            usertype : 'Editor',
            content: content,
            comments : commentContent,
        })

        .then((response) => {
            
            if (response.data.status) {

                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                const timer = setTimeout(() => {
                    window.location.reload();
                }, 3000);

            } else {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

            }
        })
    }


    // Handle changes in the editor content
    const handleEditorChange = (value) => {
        setContent(value);
        const words = value.trim().split(/\s+/);
        setWordLength(words.length);
    };

    const handleCommentChange = (e) => {
       setCommentContent(e.target.value);
    };

    

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ size: [] }], 
        [{ color: [] }, { background: [] }], 
        ['link', 'image'],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }], 
    ];

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const quillRef = useRef(null); // Create a ref for ReactQuill

    const [selectedSentence, setSelectedSentence] = useState('');
    const [selectedStartIndex, setSelectedStartIndex] = useState(null);
    const [gefModalVisible, setGefModalVisible] = useState(false);
    const [loadingVisible, setSelectedSentenceLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [commentList, setDocumentCommentData] = useState("");
    const [contentId, setContentId] = useState("");
    const [isCountModalOpen, setIsCountModalOpen] = useState(false);
      
    const handleGetSelectedSentence = () => {
       
        if (quillRef.current) {
          const quillEditor = quillRef.current.getEditor();
          const selection = quillEditor.getSelection(); // Get the selected range
          
          if (selection) {
            
            
            const [startIndex, endIndex] = [selection.index, selection.index + selection.length];
            setSelectedStartIndex(startIndex); // Set the selected start index
            const editorContent = quillEditor.getText();
            const fullText = editorContent.slice(0, endIndex);
      
            // Find the start of the sentence by searching backward for the first period or the beginning of the editor content.
            let sentenceStart = startIndex - 1;
            sentenceStart = sentenceStart !== -1 ? sentenceStart + 1 : 0;
      
            // Find the end of the sentence by searching forward for the next period or the end of the editor content.
            const sentenceEnd = fullText.indexOf('.', endIndex);
            const selectedSentence = fullText.slice(sentenceStart, sentenceEnd !== -1 ? sentenceEnd + 1 : undefined);
            //console.log(selectedSentence);
            setSelectedSentence(selectedSentence);
            //setSelectedSentenceLoading(false);
            const words = selectedSentence.trim().split(/\s+/);
            setSelectedWord(words.length);
          }
        }

        setGefModalVisible(true)
        
    };
    
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCountCancel = () => {
        setIsCountModalOpen(false);
    };

    const handleChange = (name, value) => {
        setSelectedSentence(value);
    };
    
    const handleCommentSubmit = (e) => {
        e.preventDefault();
        if (commentContent.trim() !== '') {
            axios.post(api_url + 'application/update_document_comment', {
                content_id : contentId,
                usertype: 'Editor',
                created_by : user_data.id,
                comment: commentContent,
            })
                .then(response => {
                    if (response.data.status) {
    
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        const timer = setTimeout(() => {
                            window.location.reload();
                        }, 3000);
    
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
    
                    }
                })
          } else {
            console.log('Comment is empty');
          }
       
    }

    const handleCommentHistory = (id) => {
        
        axios.post(api_url + 'application/get_document_history', {
            content_id : id,
        })
        .then(response => {
            if(response.data.data){
                setDocumentCommentData(response.data.data);
                setContentId(id);
                setIsModalOpen(true);
            }
            
        })
    }

    const getWordPopup = () =>{

        const selectedText = window.getSelection().toString();
        if(selectedText){
            setSelectedSentence(selectedText);
        }else{
            setSelectedSentence("");
        }
        
        setIsCountModalOpen(true)
        
    }
    
    return (
        <>
          
            <CounsellorLayout>
                <Content
                    className="site-layout-background lg:p-7 md:p-5"
                    style={{
                        margin: '20px 16px 24px',
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mb-16 mx-10" >
                        <Row style={{ height: '100%' }}>
                            <Col md={20} style={{ height: '100%' }}>
                                <div className="form-layout bg-[#F6F6F6]" style={{ height: '100%', marginBottom: '30px' }}>
                                    <div className=" bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-base">Document Editor</h1>
                                        <p style={{ float: 'right',marginTop : '-25px' }} className="text-[#fff] "> Word Count : <span onClick={getWordPopup}>{selectedWorld > 0 ? selectedWorld + '/' : ''} {worldLimit}</span></p>
                                    </div>
                                    <div className="bg-[#FCFCFC] p-2.5" style={{ height: '100%' }}>
                                        <Form onFinish={onFinish}>
                                            <div>
                                                
                                                <ReactQuill spellCheck={true} ref={quillRef} id="getText" modules={{ toolbar: toolbarOptions }} value={content} onChange={handleEditorChange} style={{ height: '100%' }} />
                                            </div>

                                            <Button style={{ marginTop: '20px' }} htmlType='submit' className='bg-[#0C3762] text-sm text-white rounded-full  flex justify-center items-center px-7 border'>
                                                Save
                                            </Button>
                                        </Form>


                                        <Popover trigger="click" content={<div><h3 className='h3'>Comment Box  </h3>

                                            <Input  style={{ width: '100%', }} name="comment" onChange={(e) => handleCommentChange(e)} />

                                        </div>} placement="right">
                                            <Button className='bg-[#0C3762] text-sm text-white rounded-full flex justify-right items-center px-7 margin-left border'>Comment
                                            </Button>


                                        </Popover>

                                    
                                        <Button onClick={getWordPopup} className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>Get Word Count
                                        </Button>


                                    </div>
                                </div>

                            
                                {loading ? (
                            <div className="loaders"><img src='/images/loader.gif' /></div>
                        ) : (

                            historyContent.length > 0 ? (historyContent.map((row, index) => (
                                <>
                                <div className="msger-chat" key={index}>
                                    {row.userType === "Student" ? (
                                    <div className="msg left-msg">
                                        <div className="msg-img">
                                             
                                             {row.image == null ? (
                                                <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                                            ) : (
                                                <img src={studentPath + row.image} width="100%" alt="user" />
                                            )}
                                            </div>
                                        <div className="msg-bubble">
                                            <div className="msg-info">
                                                <div className="msg-info-name">{row.created_by}</div>
                                                <div className="msg-info-time">{row.created_date}</div>
                                            </div>

                                            <div className="msg-text1" dangerouslySetInnerHTML={{ __html: row.content }}></div>

                                            {row.comment > 0 ? (<div className='comment__footer '>
                                                    <div className='comment-footer__menu'>
                                                       
                                                        <li className='bullet'>•</li><li  onClick={() => handleCommentHistory(row.id)}  >Comment <span>{row.comment}</span></li>
                                                    </div>
                                                </div>) : (<div className='comment__footer '>
                                                    <div className='comment-footer__menu'>
                                                       <li className='bullet'>•</li><li  onClick={() => handleCommentHistory(row.id)}  >Click Here for Comment <span></span></li>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>
                                    ) : (
                                        <div className="msg right-msg">
                                            <div className="msg-img">
                                            {row.image == null ? (
                                                <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                                            ) : (
                                                <img src={counsellorPath + row.image} width="100%" alt="user" />
                                            )}
                                                
                                                </div>
                                            <div className="msg-bubble">
                                                <div className="msg-info">
                                                    <div className="msg-info-time">{row.created_date}</div>
                                                    <div className="msg-info-name">{row.created_by}</div>
                                                </div>
                                                <div className="msg-text1" dangerouslySetInnerHTML={{ __html: row.content }}></div>
                                                {row.comment > 0 ? (<div className='comment__footer '>
                                                    <div className='comment-footer__menu'>
                                                       
                                                        <li className='bullet'>•</li><li  onClick={() => handleCommentHistory(row.id)}  >Comment <span>{row.comment}</span></li>
                                                    </div>
                                                </div>) : (<div className='comment__footer '>
                                                    <div className='comment-footer__menu'>
                                                       <li className='bullet'>•</li><li  onClick={() => handleCommentHistory(row.id)}  >Click Here for Comment <span></span></li>
                                                    </div>
                                                </div>)}

                                            </div>
                                        </div>

                                    )}

                                    

                                </div></>
                                ))) : ('')
                            )}

                                <Modal  className="modalStyle" title="Comment" open={isModalOpen}  onCancel={handleCancel} footer={null}>
                                    <div className="actionBox">
                                        <ul className="commentList">
                                        {commentList.length > 0  && commentList.map((row, index) => (
                                             <li>
                                                <div className="commenterImage">
                                                {row.userType === "Student" ? (
                                                    row.image == null ? (
                                                        <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                                                    ) : (
                                                        <img src={studentPath + row.image} width="100%" alt="user" />
                                                    )) : (row.image == null ? (
                                                        <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                                                    ) : (
                                                        <img src={counsellorPath + row.image} width="100%" alt="user" />
                                                    ))}
                                                    
                                                </div><h3>{row.created_by}</h3>
                                                <div className="commentText">
                                                    <p className="">{row.comment}</p> <span className="date sub-text">{row.created_date}</span>

                                                </div>
                                            </li>
                                        ))}
                                    </ul>

                                    </div>
                                    
                                    <form className="msger-inputarea">
                                        <img className='userimg' src="/images/user.png" alt="user" />
                                        <input required onChange={(e) => handleCommentChange(e)} type="text" className="msger-input" placeholder="Enter your message..." />
                                        <button   onClick={(e) => handleCommentSubmit(e)}  type="button" className="msger-send-btn"> <img
                                            src="/images/send.png"
                                            width="24px"
                                            alt="button"  />
                                        </button>
                                    </form>
                                </Modal>

                                <Modal  className="modalStyle" title="Word Count" open={isCountModalOpen}  onCancel={handleCountCancel} footer={null}>
                                    <Col md={18}>
                                        <Row gutter={30}>
                                            <Col md={24}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Total Words :</strong> {worldLimit}</p>
                                            </Col>
                                            <Col md={24}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Selected Words :</strong> {selectedSentence ? selectedSentence.trim().split(/\s+/).length : 0}</p>
                                            </Col>
                                            <Col md={24}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Characters (no spaces) :</strong> {selectedSentence.replace(/\s+/g, '').length}</p>
                                            </Col>
                                            <Col md={24}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Characters (with spaces)</strong> {selectedSentence.length}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Modal>
                            </Col>
                        </Row>

                      

                    </div>
                </Content>
            </CounsellorLayout>
        </>
    )
}

export default Documents