import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import { Col, Row, Layout, Button, Tabs, Modal } from 'antd';
import CounsellorLayout from "./counsellor-layout";
import axios from 'axios';
import { api_url, studentPath } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from "react-router-dom";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { gapi } from 'gapi-script';
const { Content } = Layout;
const { TabPane } = Tabs;


const StudentApplication = () => {

    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [myCountry, setMyCountry] = useState(null);
    const [showComponent, setShowComponent] = useState(false);
    const [showLOR, setShowLOR] = useState(false);
    const [showSOP, setShowSOP] = useState(false);
    const [showAllEssay, setShowAllEssay] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [countryMapping, setCountryMapping] = useState(null);
    const [documentData, setDocData] = useState(null);
    const [subjectData, setSubjectData] = useState(null);
    const [englishData, setEnglishData] = useState(null);
    const [principleData, setPrincipleData] = useState(null);
    const [sopData, setSOPData] = useState(null);
    const [allData, setAllData] = useState(null);
    const [commonData, setCommonData] = useState(null);
    const [universityData, setUniversity] = useState(null);


    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const getCountryList = () => {

        axios.post(api_url + 'ajax/get_user_application_country', {
            user_id: id,

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.country_list;
            console.log(st_list[0].country_id);
            setMyCountry(st_list);
            setCommonData(res.data.common_essay)
            setSubjectData(res.data.subject)
            setEnglishData(res.data.english)
            setPrincipleData(res.data.principle)
            setDocData(res.data.document_data);
            setSOPData(res.data.sop)
            setAllData(res.data.document_editor_data[st_list[0].country_id]);
            setShowAllEssay(true);
            setLoading(false)

        })
    }
    useEffect(() => {
        getCountryList()

    }, [])

    const handleChange = (result) => {

        axios.post(api_url + 'ajax/get_user_application_country', {
            user_id: id,
            country_id: result.country_id,

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.country_list;

            setCommonData(res.data.common_essay)
            setSubjectData(res.data.subject)
            setEnglishData(res.data.english)
            setPrincipleData(res.data.principle)
            setDocData(res.data.document_data);
            setSOPData(res.data.sop)
            setUniversity(res.data.university_data)
            setAllData(res.data.document_editor_data[st_list[0].country_id]);
            setLoading(false)
            setShowAllEssay(true);
            setCountryMapping(result);
            if (result.common_essay == 1 || result.university_essay == 1) {
                setShowComponent(true);
            }

            if (result.sop == 1) {
                setShowSOP(true);
            }

            if (result.english_lor == 1 || result.principle_lor == 1 || result.subject_lor == 1) {
                setShowLOR(true);
            }

        })


    };


    const handleButton = () => {
        setIsActive(true);
    };

    return (
        <>
            <CounsellorLayout>
                <Content
                    className="site-layout-background lg:p-7 md:p-5"
                    style={{
                        margin: '20px 16px 24px',
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mb-16 mx-10">
                        <Row>
                            <Col md={24}>
                                <div className="form-layout form-space bg-[#F6F6F6]">
                                    <div className=" bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-base">Application</h1>
                                    </div>
                                    <div className="bg-[#F5F5F5] p-2.5">
                                        <div className="mx-2.5 bg-white" style={{ border: '1px solid #FFEFCC' }}>
                                            <div className='bg-[#FFEFCC]'>
                                                <h2 className='text-base text-[#0C3762] p-2.5'>Document Process</h2>
                                            </div>
                                            <Row gutter={30} style={{ marginTop: 20, marginBottom: 50, marginLeft: 10, marginRight: 3 }}>
                                                <div className='flex justify-between'>
                                                    {loading ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (

                                                        myCountry.map((row, index) => (
                                                            <Col key={index} >
                                                                <div className='mb-5'>
                                                                    <Button className='custom-button bg-[#FFEFCC] text-[#0C3762] text-base rounded-lg flex justify-center items-center py-5 px-32'
                                                                        style={{ border: '1px solid #F2B11F' }}
                                                                        onClick={(e) => handleChange(row)}
                                                                    >
                                                                        {row.country}
                                                                    </Button>

                                                                </div>
                                                            </Col>
                                                        ))

                                                    )}
                                                </div>


                                                {/* <div class="watch-video mb-7">
                                                    <a class="bg-[#0C3762] text-white text-sm font-light px-3.5 py-1.5" style={{ float: 'right' }}>
                                                        <img src='/images/video-camera.png' alt='video-camera' /><span>watching the tutorial for guides</span> </a>
                                                </div> */}
                                                <Col md={24}>
                                                    {showComponent && <Essay mapping={countryMapping} user_id={id} userdata={user_data} university={universityData} common_essay={commonData} />}
                                                </Col>
                                                {/* <Col md={24} style={{ marginTop: 20 }}>
                                                    {showLOR && <Lor mapping = {countryMapping} user_id={id} userdata ={user_data} subject = {subjectData} english = {englishData} principle = {principleData} />}
                                                </Col>
                                                <Col md={24} style={{ marginTop: 20 }}>
                                                    {showSOP && <Sop mapping = {countryMapping} user_id={id} userdata ={user_data}  sop = {sopData}/>}
                                                </Col> */}

                                                <Col md={24} style={{ marginTop: 20 }}>
                                                    {showAllEssay && <ALLEssay mapping={countryMapping} user_id={id} userdata={user_data} data={allData} />}
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Content>
            </CounsellorLayout>
        </>
    )
}

const Essay = (props) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [universityInfo, setUniversityInfo] = useState('')
    const data = props.mapping;
    const docs = props.common_essay;
    const uni = props.university;

    const navigate = useNavigate();

    const editFile = (id) => {
        navigate("/counsellor-dashboard/documents/" + id)
    }

    const uniInfo = (id) => {

        axios.post(api_url + 'ajax/get_university_info', {
            university_id: id

        }).then((res) => {
            console.log(res.data)
            setUniversityInfo(res.data.university_info)
            setModalVisible(true)

        })

    }

    // useEffect(() => {
    //     uniInfo()

    // }, [])

    return (
        <div className="mx-2.5 bg-white" style={{ border: ' 1px solid #FFEFCC' }}>
            <Row>
                <Col md={24}>
                    <div className='bg-[#FFEFCC]'>
                        <h2 className='text-base text-[#0C3762] p-2.5'>Essay</h2>
                    </div>
                </Col>
                <Col md={24}>

                    <div className='bg-[#F6F6F6] px-5 py-5'>

                        {/* <div className=' my-2.5 mr-5 bg-white' style={{ border: '2px dashed #FFEFCC' }}>
                            <div className='px-3.5 py-5'>
                                <div className="bg-[#fff]" style={{ borderRadius: 15 }}> */}
                        <table className="edit-essay">
                            <thead>
                                <tr>
                                    <th>University Name</th>
                                    <th>Stream</th>
                                    <th>Write Essay</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uni.map((row, index) => (
                                    <tr key={index}>
                                        <td width="40%">
                                            <span className='cursor-pointer' onClick={() =>
                                                uniInfo(row.doc_type_id)}>{row.university_name}</span>
                                            <Modal title="University Information"
                                                footer={null}
                                                open={modalVisible}
                                                onOk={() => setModalVisible(false)}
                                                onCancel={() => setModalVisible(false)}>
                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>QS Ranking 2024</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.qs_rank_2024}</p>
                                                    </Col>
                                                </Row>

                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>QS Ranking 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.rank}</p>
                                                    </Col>
                                                </Row>

                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>QS Engg & Technology Ranking 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.qs_engg_tech_rank_2023}</p>
                                                    </Col>
                                                </Row>
                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>QS Physics & Astronomy Ranking 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.qs_phy_asto_rank_2023}</p>
                                                    </Col>
                                                </Row>

                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>QS Mechnical Ranking 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.qs_mech_rank_2023}</p>
                                                    </Col>
                                                </Row>
                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>QS Medicine Ranking 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.qs_medicine_rank_2023}</p>
                                                    </Col>
                                                </Row>

                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>QS Biological Science Ranking 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.qs_bio_science_rank_2023}</p>
                                                    </Col>
                                                </Row>
                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>Early Action Deadline 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.early_deadline}</p>
                                                    </Col>
                                                </Row>

                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>Early Decision Deadline 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.early_decision_deadlin}</p>
                                                    </Col>
                                                </Row>

                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>Regular Action Deadline 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.regular_action_deadline}</p>
                                                    </Col>
                                                </Row>

                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong className='text-[#2A6496]'>Regular Decision Deadline 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <p className='text-[#9B9B9B]'>{universityInfo.regular_decision_deadline}</p>
                                                    </Col>

                                                </Row>
                                                {/* <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong>QS CS Rank 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <span className='text-[#84acd4]'>{universityInfo.qs_cs_rank_2023}</span>
                                                    </Col>
                                                </Row>

                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong>Regular action deadline</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <span className='text-[#84acd4]'>{universityInfo.regular_action_deadline}</span>
                                                    </Col>
                                                </Row> */}
                                            </Modal>
                                        </td>
                                        <td><span>{row.stream}</span></td>
                                        <td>
                                            <p>
                                                <Button onClick={() => editFile(row.id)} className="bg-[#0C3762] text-white" style={{ borderRadius: 7 }}>Open Editor</Button>
                                            </p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* </div>

                            </div>
                        </div> */}

                    </div>


                </Col>
            </Row>
        </div>
    )
}

// const Lor = (props) => {
//     const data = props.mapping;
//     const subject = props.subject;
//     const english = props.english;
//     const principle = props.principle;

//     const editFile = (doc_id) => {
//         window.open('https://docs.google.com/document/d/'+doc_id+'/edit','_blank');
//     }



//     return (
//         <>
//             <div className="mx-2.5 bg-white" style={{ border: ' 1px solid #FFEFCC' }}>
//                 <Row>
//                     <Col md={24}>
//                         <div className='bg-[#FFEFCC]'>
//                             <h2 className='text-base text-[#0C3762] p-2.5'>Letter & Recommendation (LOR)</h2>
//                         </div>
//                     </Col>
//                     <Col md={24} style={{ margin: 20 }}>
//                         <Row gutter={10}>
//                             {data.subject_lor === "1"  ? (
//                                 <Col md={6}>
//                                     <div className=' flex'>


//                                         {subject === null ? (
//                                             '')  
//                                         : (
//                                             <><Button onClick={() => editFile(subject)} className=' bg-[#DBE7F3] text-[#0C3762] text-base rounded-lg flex justify-center items-center py-5 px-16'
//                                             style={{ border: '1px solid #A6CAEE' }}
//                                         ><img src='/images/edit.png' alt='edit-icon' style={{ height: 25, paddingRight: 5 }} /> Subject
//                                         </Button>
//                                             </>
//                                         )}

//                                     </div>
//                                 </Col>
//                             ) :('')}

//                             {data.english_lor === "1"  ? (
//                                 <Col md={6}>
//                                     <div className=' flex'>

//                                         {english === null ? (
//                                             <>
//                                             </>)  
//                                         : (
//                                             <><Button onClick={() => editFile(english)} className=' bg-[#DBE7F3] text-[#0C3762] text-base rounded-lg flex justify-center items-center py-5 px-16'
//                                             style={{ border: '1px solid #A6CAEE' }}
//                                         >
//                                             <img src='/images/edit.png' alt='edit-icon' style={{ height: 25, paddingRight: 5 }} /> English
//                                         </Button>
//                                             </>
//                                         )}


//                                     </div>
//                                 </Col>
//                             ) :('')}
//                             {data.principle_lor === "1"  ? (
//                                 <Col md={6}>
//                                     <div className=' flex'>
//                                         {principle === null ? (
//                                             <>
//                                             </>)  
//                                         : (
//                                             <><Button onClick={() => editFile(principle)} className=' bg-[#DBE7F3] text-[#0C3762] text-base rounded-lg flex justify-center items-center py-5 px-16'
//                                             style={{ border: '1px solid #A6CAEE' }}
//                                         ><img src='/images/edit.png' alt='edit-icon' style={{ height: 25, paddingRight: 5 }} /> Principle
//                                         </Button>
//                                             </>
//                                         )}


//                                     </div>
//                                 </Col>
//                             ) :('')}
//                         </Row>
//                         {/* <div className='flex justify-center items-center mt-10'>
//                             <Button className='bg-[#0C3762] text-white text-sm px-7' style={{ border: '1px solid #EEE6E6', borderRadius: 5 }} type='submit'>Submit</Button>
//                         </div> */}
//                     </Col>
//                 </Row>
//             </div>
//         </>
//     )
// }

// const Sop = (props) => {
//     const data = props.mapping;
//     const sop = props.sop;



//     const editFile = (doc_id) => {
//         window.open('https://docs.google.com/document/d/'+doc_id+'/edit','_blank');
//     }
//     return (
//         <div className="mx-2.5 bg-white" style={{ border: ' 1px solid #FFEFCC' }}>
//             {data.sop === "1"  ? (
//             <Row>
//                 <Col md={24}>
//                     <div className='bg-[#FFEFCC]'>
//                         <h2 className='text-base text-[#0C3762] p-2.5'>SOP</h2>
//                     </div>
//                 </Col>
//                 <Col md={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                     <div className='my-5 bg-white' style={{ border: '2px dashed #FFEFCC' }}>
//                         <div className='flex justify-center items-center py-16 px-32'>
//                             {sop === null ? (
//                                 <><h3 className='text-[#0C3762] text-xl'>Write Your Essay </h3> 

//                                 </>)  
//                             : (
//                                 <><h3 className='text-[#0C3762] text-xl'>Edit Your Essay </h3> 
//                                     <Button className='text-white text-base bg-[#0C3762] ml-2.5' onClick={() => editFile(sop)} style={{ borderRadius: 7 }}>Click Here !</Button>
//                                 </>
//                             )}

//                         </div>



//                     </div>
//                 </Col>

//             </Row>
//             ):('')}
//             {/* <div className='flex justify-center items-center my-5'>
//                 <Button className='bg-[#0C3762] text-white text-sm px-7' style={{ border: '1px solid #EEE6E6', borderRadius: 5 }} type='submit'>Submit</Button>
//             </div> */}
//         </div>
//     )
// }


const ALLEssay = (props) => {
    const data = props.mapping;
    const navigate = useNavigate();
    const alldata = props.data;
    const userdata = props.userdata;

    const editFile = (row_data) => {

        axios.post(api_url + 'application/get_user_document_permission', {
            document_id: row_data.document_id,
            editor_id: userdata.id,

        }).then((res) => {
            if (res.data.data) {
                if (userdata.counsellor_type === "Country Counsellor Head" || userdata.counsellor_type === "Editor Counsellor Head" || props.userdata.id === res.data.data.editor_id) {
                    navigate("/counsellor-dashboard/documents/" + row_data.document_id)
                } else {
                    toast.error('You do not have permission to aceess document', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                if (userdata.counsellor_type === "Country Counsellor Head" || userdata.counsellor_type === "Editor Counsellor Head") {
                    navigate("/counsellor-dashboard/documents/" + row_data.document_id)
                } else {
                    toast.error('You do not have permission to aceess document', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }

            }

        })
    }

    return (
        <>
            <div className="mx-2.5 bg-white" style={{ border: ' 1px solid #FFEFCC' }}>
                <Row>
                    <Col md={24}>
                        <div className='bg-[#FFEFCC]'>
                            <h2 className='text-base text-[#0C3762] p-2.5'>Essay</h2>
                        </div>
                    </Col>
                    <Col md={24} style={{ margin: 20 }}>
                        <Row gutter={10}>
                            {alldata.map((row, index) => (
                                <Col>
                                    <div className='flex mb-5'>
                                        <Button onClick={() => editFile(row)} className=' bg-[#DBE7F3] text-[#0C3762] text-base rounded-lg flex justify-center items-center py-5 px-16'
                                            style={{ border: '1px solid #A6CAEE' }}
                                        ><img src='/images/edit.png' alt='edit-icon' style={{ height: 25, paddingRight: 5 }} /> {row.document_type}
                                        </Button>
                                    </div>
                                </Col>))}
                        </Row>

                    </Col>
                </Row>
            </div>
        </>
    )
}

export default StudentApplication