import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import { Button, Col, Row, Collapse, Layout, Modal, Input, Form,Select,Spin,Badge,Pagination } from 'antd';
import TeacherLayout from "../components/teacher-layout";
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
const { Content } = Layout;
const { Search } = Input;


const DoubtSection = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [doubtData, setDoubtData] = useState([{ id: "", form_number:"",st_class:"",st_course:"",username:"",user_id: "", query: "", created_at: "", faculty_id: ""}]);
    
    
    const fetchDoubtList = async () => {
        await axios.post(api_url + 'faculty/get_doubt_list', {
            user_id: user_data.id

        }).then((res) => {
            
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const doubt_data = res.data.doubt;
            setDoubtData(doubt_data);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchDoubtList()
    }, [])

   
    const viewConversation = (id) => {
        navigate('/teacher-dashboard/doubt-area/'+id)
    }

    
    return (
        <>
            <TeacherLayout>
                <Content
                    className="site-layout-background lg:p-7 md:p-5"
                    style={{
                        margin: '20px 16px 24px',
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mb-16 mx-10">
                        <Row gutter={30}>

                            <Col md={24} xs={24}>
                                <div className="form-layout form-space" style={{ background: '#F5F5F5' }}>
                                    <div className="bg-[#0F4275] py-2.5 px-7 flex justify-between">
                                        <h1 className="text-[#fff] text-2xl uppercase text-center">Doubt Section</h1>
                                        <Search placeholder="search here...." style={{ width: 200 }} />
                                    </div>
                                    {loading ? (
                                        <div className="loaders"><img src='/images/loader.gif' /></div>
                                    ) : (
                                        doubtData.length > 0 ? (doubtData.map((row, index) => (
                                            <Row key={index}>
                                                <Col md={24}>
                                                    <div className='flex justify-between bg-[#F2EEEB] px-7 py-5 m-5' style={{ borderRadius: 13 }}>
                                                        <div>
                                                            <h4 className='text-[#F1AC12] text-base mb-2.5'>Date : {row.created_at}</h4>
                                                            {/* <p className='text-[#707070] text-base'>{row.query}</p> */}
                                                            <Row gutter={20}>

                                                                <div className='bg-[#FFF5DE] rounded-md'>
                                                                    <p className='text-base text-[#F0AA0C] p-2.5'>Name <span className='text-[#0C3762]'>: {decrypt(row.username,encriptionKey)}</span></p>
                                                                </div>

                                                                <div className='bg-[#FFF5DE] rounded-md ml-5'>
                                                                    <p className='text-base text-[#F0AA0C] p-2.5'>Class <span className='text-[#0C3762]'>: {decrypt(row.st_class,encriptionKey)}</span></p>
                                                                </div>

                                                                <div className='bg-[#FFF5DE] rounded-md ml-5'>
                                                                    <p className='text-base text-[#F0AA0C] p-2.5'>Course Name  <span className='text-[#0C3762]'>: {decrypt(row.st_course,encriptionKey)}</span></p>
                                                                </div>

                                                                <div className='bg-[#FFF5DE] rounded-md ml-5'>
                                                                    <p className='text-base text-[#F0AA0C] p-2.5'>Form Number <span className='text-[#0C3762]'>: {decrypt(row.form_number,encriptionKey)} </span></p>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className='flex justify-center items-center'>

                                                            <Button onClick={() => { viewConversation(row.id) }} className='bg-[#2E6798] text-white rounded-full'>View Conversation</Button>

                                                        </div>

                                                    </div>

                                                </Col>
                                            </Row>
                                        ))) : (<div className="my-24">
                                            <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                        </div>)
                                    )}
                                </div>
                            </Col>

                        </Row>

                        {/* <Row>
                            <Col md={24}>
                                <Pagination className='flex justify-center doubt' defaultCurrent={6} total={50} />
                            </Col>
                        </Row> */}

                    </div>
                </Content>
            </TeacherLayout>
        </>
    )
}

export default DoubtSection