import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import axios from 'axios';
import { Button, Col, Row, Layout, Dropdown, Space, message, Radio, Select, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { api_url, reportPath } from '../components/constants';
import DashboardLayout from '../components/dashboard-layout';
const { Content } = Layout;
const { Option } = Select;


const Round2Level1 = () => {

    const [value, setValue] = useState(1);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(1);
    const [showPassageReading, setShowPassageReading] = useState(false);
    const [showLevel2, setShowLevel2] = useState(false);



    const onChange = e => {
        setValue(e.target.value);
    };


    // Function to handle subject selection
    const handleSubjectSelection = (value) => {
        setSelectedSubject(value);
    };

    // Function to handle level selection
    const handleLevelSelection = (e) => {
        setSelectedLevel(e.target.value);
    };

    // Function to handle Begin button click
    const handleBeginClick = () => {
        if (selectedSubject && selectedLevel) {
            setShowPassageReading(true);
        }
    };

    const handlePassageReadingDone = () => {
        setShowLevel2(true);
    };



    return (
        <>
            <DashboardLayout>

                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <Row gutter={30}>

                        <Col md={24} xs={24}>

                            <div className="form-layout form-space" style={{ background: '#F5F5F5' }}>

                                <div className='flex  pbs-session-heading bg-[#114D89] px-5 py-2.5 justify-between '>
                                    <h1 className="text-white text-xl text-center font-light">Speed Reading</h1>
                                    <p className="text-right text-white text-xl font-light">Level-1</p>
                                </div>
                                <div className="bg-[#F5F5F5] p-7">
                                    <Row>
                                        <Col md={24}>
                                            <div className='flex items-center justify-center'>

                                                <div className='p-2.5' style={{ background: '#F0AA0C', borderRadius: 10 }}>
                                                    <div className='flex items-center'>
                                                        <span className="dot"></span>
                                                        <p className='text-[#fff] pl-2.5 text-sm'>
                                                            Round 1
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr style={{ border: 'none', borderBottom: '1px solid #DBDBDB', width: '20%' }} />

                                                <div className='p-2.5' style={{ background: '#F0AA0C', borderRadius: 10 }}>
                                                    <div className='flex items-center'>
                                                        <span className="dot"></span>
                                                        <p className='text-[#fff] pl-2.5 text-sm'>
                                                            Round 2
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr style={{ border: 'none', borderBottom: '1px solid #DBDBDB', width: '20%' }} />

                                                <div className='p-2.5' style={{ background: '#fff', borderRadius: 10 }}>
                                                    <div className='flex items-center'>
                                                        <span className="dot"></span>
                                                        <p className='text-[#F0AA0C] pl-2.5 text-sm'>
                                                            Round 3
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>



                                    <Row gutter={30} style={{ paddingTop: 40 }}>
                                        <Col md={4}>
                                            <p className='text-[#114D89] text-lg font-light pl-2.5 pb-2.5'>Choose Subject</p>
                                            <Select
                                                placeholder="Select Subject"
                                                onChange={handleSubjectSelection}
                                                style={{ width: '100%', borderRadius: 8, boxShadow: '0px 3px 6px #00000029' }}
                                            >
                                                <Option value="subject1">Subject 1</Option>
                                                <Option value="subject2">Subject 2</Option>
                                                {/* Add more options as needed */}
                                            </Select>
                                        </Col>

                                        <Col md={15}>
                                            {showPassageReading ? (
                                                <PassageReading

                                                />
                                            ) : (
                                                <>
                                                    <div className='reading-space pt-5'>
                                                        <p className='text-[#60666B] text-sm pb-20'>Please Select The Level and Subject, then click on the Begin button.</p>

                                                    </div>
                                                    <div className='reading flex justify-end'>
                                                        <Button
                                                            className='text-[#114D89] bg-[#F5C964]'
                                                            style={{ borderRadius: 10, border: '1px solid #F5C964' }}
                                                            onClick={handleBeginClick}
                                                        >
                                                            Begin
                                                        </Button>
                                                    </div>
                                                </>

                                            )}
                                        </Col>

                                        <Col md={5}>
                                            <p className='text-[#114D89] text-lg font-light pl-2.5 pb-2.5'>Select Words Level</p>
                                            <Select
                                                placeholder="Select words level"
                                                onChange={handleSubjectSelection}
                                                style={{ width: '100%', borderRadius: 8, boxShadow: '0px 3px 6px #00000029' }}
                                            >
                                                <Option value="5words">5 Words/ 1 Sec</Option>
                                                <Option value="3words">3 Words/ 1 Sec</Option>
                                                {/* Add more options as needed */}
                                            </Select>


                                            <p className='text-[#114D89] text-lg font-light pl-2.5 py-2.5'>Choose Level</p>
                                            <div className='level'>
                                                <Radio.Group className='custom-level' onChange={onChange} onClick={handleLevelSelection}>
                                                    <Radio value={1} className='choose-level'>Easy Level</Radio>
                                                    <Radio value={2} className='choose-level'>Medium Level</Radio>
                                                    <Radio value={3} className='choose-level'>Hard Level</Radio>
                                                </Radio.Group>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Col>
                    </Row>

                </Content>

            </DashboardLayout>
        </>
    )
}


const PassageReading = () => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <div>
            <div className='reading-space pt-5'>
                <p className='text-[#60666B] text-sm pb-20'>
                    text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                    It was popularised in the 1960s with the release of Letraset sheets containing
                    Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

            </div>
            <div className='reading flex justify-between'>
                <p className='text-base text-[#fff] font-light'>1280 Current status : 113 words at 300 wpm</p>
                <Space>
                    {/* <Button className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }}>PAUSE</Button>
                    <Button className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }}>PLAY</Button> */}
                    <Button className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }} onClick={() => setModalVisible(true)}>DONE</Button>
                </Space>
                <Modal open={modalVisible} footer={null} header={null} onCancel={() => setModalVisible(false)}>
                    <img src='/images/prize.png' alt='prize-image' />
                    <p className='text-[#000000] text-xl text-center'>Quiz Completed Successfully.</p>
                    <p className='text-[#000000] text-lg text-center px-5'>You attempted <span className='text-[#114D89] text-'>20 questions</span> out of which <span className='text-[#F0AA0C]'>18 answers</span> are correct.</p>
                    <div className='flex justify-center items-center pt-5'>
                        <Button className='text-[#fff] bg-[#F0AA0C] flex justify-center items-center' style={{ borderRadius: 21 }}>Check Analysis</Button>
                    </div>

                </Modal>
            </div>

        </div>
    )
}

export default Round2Level1