import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/dashboard.css';
import { Row, Col, Layout, Modal, Form, Space, DatePicker, Select ,Button } from 'antd';

import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { api_url } from '../components/constants';
import DashboardLayout from '../components/dashboard-layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
const { Content } = Layout;

const PbsSession = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [pbsData, setpbsData] = useState([{ id: "", date: "", time: "", status: "", mentor: "", link: "", meeting_id: "", duration: "" }]);
    const [pbsMoadalVisible, setPbsMoadalVisible] = useState(false);
    const [pbsDate, setPbsDate] = useState('');
    const [pbsTime, setPbsTime] = useState('');
    const [timeSlot, settimeSlot] = useState({ label: '', value: '' });
    const [tymodalVisible, setTyModalVisible] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const dateAfterAddition = new Date(currentDateTime);

    

    const fetchPBSData = async () => {
        await axios.post(api_url + 'get_pbs_session', {
            user_id: user_data.id

        }).then((res) => {
            console.log(res);
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const pbs_data = res.data.data;
            setpbsData(pbs_data);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchPBSData()
    }, [])

    const handleApi = () => {
        axios.post(api_url + 'pbs-request', {
            date: pbsDate,
            time: pbsTime,
            user_id: user_data.id,
        })
            .then(result => {
                if (result.data.status) {
                    setPbsMoadalVisible(false);
                    setTyModalVisible(true);
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }

    const joinZoomMeeting = (record) => {
        navigate("/student-dashboard/join-session/"+record.meeting_id);
    };

    const handleDateChange = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        setPbsDate(formattedDate);
    };
    const handleTimeChange = (value) => {
        setPbsTime(value);
    };
    const getTimeSlot = async () => {

        await axios.post(api_url + 'pbs-time-slot', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const time_slot = res.data.data;

            let options = [];
            time_slot.map((element, index) => {
                options.push({
                    label: element.slot,
                    value: element.slot
                });
            })

            settimeSlot(options);
            setLoading(false);
        })

    }
    useEffect(() => {

        getTimeSlot()

    }, [])
    const TyModalVisibleNew = () => {
        setTyModalVisible(false);
        const timer = setTimeout(() => {
            navigate(window.location.pathname);
        }, 1000);

    }

    return (
        <>
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >

                        <Row gutter={30}>

                            <Col md={24} xs={24}>
                                <div className="form-layout form-space" style={{ background: '#F5F5F5' }}>
                                    {/* <div className="bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-2xl uppercase text-center">Student PBS Session</h1>
                                    </div> */}
                                    <div className="flex pbs-session-heading justify-between bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-2xl uppercase text-center">Student PBS Session</h1>
                                        <a className="text-right text-white text-xl" onClick={() => setPbsMoadalVisible(true)}>Schedule PBS</a>
                                        {pbsMoadalVisible && (
                                            <Modal title="Schedule PBS Session"

                                                open={pbsMoadalVisible}
                                                okText='Schedule PBS'
                                                onOk={() => {
                                                    handleApi()
                                                }}
                                                onCancel={() => setPbsMoadalVisible(false)}
                                                okButtonProps={{ style: { background: '#F0AA0C', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16 } }}
                                                cancelButtonProps={{ style: { background: '#717070', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16, color: '#fff' } }}
                                            >
                                                <Form>
                                                    <Row gutter={30} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 20 }}>
                                                        <Col md={12}>
                                                            <Form.Item
                                                                name="pbs_date"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                                label="Select Date"
                                                                initialValue={pbsDate.pbs_date}
                                                            >
                                                                <Space direction="vertical" style={{ width: '100%' }}>
                                                                    <DatePicker onChange={handleDateChange} style={{ width: '100%' }} />
                                                                </Space>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col md={12}>
                                                            <Form.Item
                                                                name="pbs_time"
                                                                label="Select Time"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                                initialValue={pbsTime.pbs_time}
                                                            >

                                                                <Select
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    placeholder="Select Slot"
                                                                    onChange={handleTimeChange}
                                                                    options={timeSlot}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Form>

                                            </Modal>
                                        )}
                                    </div>
                                    <Modal title={<img src='/images/send-mail.png' />} open={tymodalVisible}
                                        onOk={TyModalVisibleNew}
                                        onCancel={() => setTyModalVisible(false)}
                                        okButtonProps={{ style: { background: '#0F4275', color: '#fff', borderRadius: 7 } }}
                                        cancelButtonProps={{ style: { display: 'none' } }}
                                    >
                                        <h4 className="text-[#2A6496] text-xl text-center">PBS Request</h4>
                                        <h3 className="text-[#FFC040] text-2xl text-center">Successfully Send</h3>
                                        <p className="text-[#5B5B5B] text-base text-center">Your counsellor contact to you very soon</p>
                                        <p className="text-[#5B5B5B] text-base text-center">Thank You !!</p>
                                    </Modal>
                                    {pbsData.length > 0 ? (pbsData.map((row, index) => (
                                        <div key={index} className="lg:mx-5 mx-5 my-3 bg-[#FBFBFB] pbs-schedule-block">
                                            <Row gutter={30}>
                                                <Col xl={5} lg={8} md={8} xs={24}>
                                                    <div className="flex">
                                                        <div className="bg-white icon-bg" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                            <img src="/images/calendar.png" className="icon" alt='calendar-img' />
                                                        </div>
                                                        <span className="md:text-xl lg:text-lg text-lg text-[#0C3762] flex justify-center items-center pbs-heading">{row.date}</span>
                                                    </div>
                                                </Col>
                                                <Col xl={5} lg={8} md={8} xs={24}>
                                                    <div className="flex md:ml-16 lg:ml-0 xl:ml-0 ml-0">
                                                        <div className="bg-white icon-bg" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                            <img src="/images/clock.png" className="icon" alt='clock-img' />
                                                        </div>
                                                        <span className="md:text-xl lg:text-lg text-lg text-[#0C3762] flex justify-center items-center pbs-heading">{row.time}</span>
                                                    </div>
                                                </Col>
                                                <Col xl={5} lg={8} md={8} xs={24}>
                                                    <div className="flex">
                                                        <div className="bg-white icon-bg" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                            <img src="/images/presentation.png" className="icon" alt='presentation-img' />
                                                        </div>
                                                        <span className="md:text-xl lg:text-lg text-lg text-[#0C3762] flex justify-center items-center pbs-heading">
                                                            {row.counsellor != null ? (decrypt(row.counsellor, encriptionKey)) : ('Mentor Not Assign')

                                                            }
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xl={5} lg={12} md={12} xs={24} className="status-outer">
                                                    <div className="flex">
                                                        <span style={{ lineHeight: "2.15rem" }} className="text-xl text-[#0C3762] flex justify-center items-center pbs-heading status">
                                                            {row.status}
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col xl={4} lg={12} md={12} xs={24}>
                                                    <div className="flex justify-center items-center md:ml-0 ml-10 view-btn">
                                                        {(row.status == "Approved" || row.status == "Pending") && row.meeting_id === null ? (
                                                            <Link
                                                                className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center items-center"
                                                                style={{ height: 'auto', borderRadius: 10 }}
                                                            >
                                                                Pending
                                                            </Link>
                                                        ) : (row.status == "Complete" ? (

                                                            <Link to={'/student-dashboard/pbs-timeline/' + encrypt(row.id.toString(), encriptionKey)}
                                                                className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center items-center"
                                                                style={{ height: 'auto', borderRadius: 10 }}
                                                            >
                                                                View
                                                            </Link>
                                                        ) : (<Button className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }} onClick={() => joinZoomMeeting(row)}>
                                                                Join Session
                                                            </Button>
                                                            
                                                            ))

                                                        }
                                                        

                               
                                                       

                                                        

                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))) : (<div className="my-24">
                                        <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                    </div>)}
                                </div>
                            </Col>
                        </Row>

                    </Content>
                )
                }

            </DashboardLayout>
        </>
    )
}

export default PbsSession