import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import { Col, Row, Layout, Button, Tabs } from 'antd';
import DashboardLayout from '../components/dashboard-layout';
import axios from 'axios';
import { api_url, studentPath } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from "react-router-dom";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { gapi } from 'gapi-script';
const { Content } = Layout;
const { TabPane } = Tabs;


const StudentApplication = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [myCountry, setMyCountry] = useState(null);
    const [showComponent, setShowComponent] = useState(false);
    const [showLOR, setShowLOR] = useState(false);
    const [showSOP, setShowSOP] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [countryMapping, setCountryMapping] = useState(null);
    const [subjectData, setSubjectData] = useState(null);
    const [englishData, setEnglishData] = useState(null);
    const [principleData, setPrincipleData] = useState(null);
    const [sopData, setSOPData] = useState(null);
    const [commonData, setCommonData] = useState(null);
    const [universityData, setUniversity] = useState(null);
    const [showAllEssay, setShowAllEssay] = useState(false);
    const [allData, setAllData] = useState(null);
    
   const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const getCountryList = () => {

        axios.post(api_url + 'ajax/get_user_application_country', {
            user_id: user_data.id,

        }).then((res) => {
            
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.country_list;
            
            setMyCountry(st_list);
            setCommonData(res.data.common_essay)
            setSubjectData(res.data.subject)
            setEnglishData(res.data.english)
            setPrincipleData(res.data.principle)
            setSOPData(res.data.sop)
            setAllData(res.data.document_editor_data[st_list[0].country_id]);
            setShowAllEssay(true);
            // if(res.data.university_data){
            //     setShowComponent(true);
            // }
           //setUniversity(res.data.university_data)
            setLoading(false)

        })
    }
    useEffect(() => {
        getCountryList()
        
    }, [])

    const handleChange = (result) => {
        
        axios.post(api_url + 'ajax/get_user_application_country', {
            user_id: user_data.id,
            country_id : result.country_id,

        }).then((res) => {
            
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.country_list;
            
            setCommonData(res.data.common_essay)
            setSubjectData(res.data.subject)
            setEnglishData(res.data.english)
            setPrincipleData(res.data.principle)
            setSOPData(res.data.sop)
            setUniversity(res.data.university_data)
            setAllData(res.data.document_editor_data[st_list[0].country_id]);
            setLoading(false)

            setCountryMapping(result);
            if(res.data.university_data){
                setShowComponent(true);
            }
           

        })
        
       
    };

   
    const handleButton = () => {
        setIsActive(true);
    };

    return (
        <>
            <DashboardLayout>
                <Content
                    className="site-layout-background lg:p-7 md:p-5"
                    style={{
                        margin: '20px 16px 24px',
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mb-16 mx-10">
                        <Row>
                            <Col md={24}>
                                <div className="form-layout form-space bg-[#F6F6F6]">
                                    <div className=" bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-base">Application</h1>
                                    </div>
                                    <div className="bg-[#F5F5F5] p-2.5">
                                        <div className="mx-2.5 bg-white" style={{ border: '1px solid #FFEFCC' }}>
                                            <div className='bg-[#FFEFCC]'>
                                                <h2 className='text-base text-[#0C3762] p-2.5'>Application Process</h2>
                                            </div>
                                            <Row gutter={30} style={{ marginTop: 20, marginBottom: 50, marginLeft: 10, marginRight: 3 }}>
                                            {loading ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (

                                                myCountry.map((row, index) => ( 
                                                    <Col key={index} md={8}>
                                                        <div className=' flex justify-center items-center'>
                                                            <Button className='custom-button bg-[#FFEFCC] text-[#0C3762] text-base rounded-lg flex justify-center items-center py-5 px-32'
                                                                style={{ border: '1px solid #F2B11F' }}
                                                                    onClick={(e) => handleChange(row)}
                                                                >
                                                                {row.country}
                                                            </Button>

                                                        </div>
                                                    </Col>
                                                ))
                                               
                                            )}
                                                

                                                 

                                                <div class="watch-video mb-7">
                                                    <a class="bg-[#0C3762] text-white text-sm font-light px-3.5 py-1.5" style={{ float: 'right' }}>
                                                        <img src='/images/video-camera.png' alt='video-camera' /><span>watching the tutorial for guides</span> </a>
                                                </div>

                                                <Col md={24}>
                                                    {showComponent && <Essay mapping = {countryMapping} user_id={id} userdata ={user_data} university ={universityData} common_essay = {commonData}/>}
                                                </Col>
                                                 {/*<Col md={24} style={{ marginTop: 20 }}>
                                                    {showLOR && <Lor mapping = {countryMapping} user_id={id} userdata ={user_data} subject = {subjectData} english = {englishData} principle = {principleData} />}
                                                </Col>
                                                <Col md={24} style={{ marginTop: 20 }}>
                                                    {showSOP && <Sop mapping = {countryMapping} user_id={id} userdata ={user_data}  sop = {sopData}/>}
                                                </Col> */}
                                                <Col md={24} style={{ marginTop: 20 }}>
                                                    {showAllEssay && <ALLEssay mapping = {countryMapping} user_id={id} userdata ={user_data}  data = {allData}/>}
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Content>
            </DashboardLayout>
        </>
    )
}

const Essay = (props) => {
    const data = props.mapping;
    const docs = props.common_essay;
    const uni = props.university;
    const navigate = useNavigate();
   
  

    const editFile = (id) => {
        navigate("/student-dashboard/documents/" + id)
    }
    
    return (
        <div className="mx-2.5 bg-white" style={{ border: ' 1px solid #FFEFCC' }}>
            <Row>
                <Col md={24}>
                    <div className='bg-[#FFEFCC]'>
                        <h2 className='text-base text-[#0C3762] p-2.5'>University Specific Essay</h2>
                    </div>
                </Col>
                <Col md={24} style={{ margin: 20 }}>
                    
                    <div className='mx-7 my-5 bg-[#F6F6F6] px-5 py-10'>
                            
                            <div className=' my-2.5 mr-5 bg-white' style={{ border: '2px dashed #FFEFCC' }}>
                                <div className='px-3.5 py-5'>
                                    <div className="bg-[#fff]" style={{ borderRadius: 15 }}>
                                        <table className="edit-essay">
                                            <thead>
                                                <tr>
                                                    <th>University Name</th>
                                                    {/* <th>University Assigned Essay</th> */}
                                                    <th>Write Essay</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {uni.map((row,index) => (
                                                    <tr key={index}>
                                                        <td><span>{row.university_name}</span></td>
                                                        {/* <td><span>University Assigned Essay 1</span></td> */}
                                                        <td>
                                                            
                                                            <p>
                                                                <Button onClick={() => editFile(row.id)}  className="bg-[#0C3762] text-white" style={{ borderRadius: 7 }}>Open Editor</Button>
                                                                 
                                                            </p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            {/* <div className='flex justify-center items-center mt-5'>
                                <Button className='bg-[#0C3762] text-white text-sm px-7' style={{ border: '1px solid #EEE6E6', borderRadius: 5 }} type='submit'>Submit</Button>
                            </div> */}
                    </div>
                            
                   
                </Col>
            </Row>
        </div>
    )
}


const ALLEssay = (props) => {
    const data = props.mapping;
    const navigate = useNavigate();
    const alldata = props.data;
   
    const editFile = (row_data) => {
        navigate("/student-dashboard/documents/" + row_data.document_id)
    }

    

    return (
        <>
            <div className="mx-2.5 bg-white" style={{ border: ' 1px solid #FFEFCC' }}>
                <Row>
                    <Col md={24}>
                        <div className='bg-[#FFEFCC]'>
                            <h2 className='text-base text-[#0C3762] p-2.5'>Letter & Recommendation (LOR)</h2>
                        </div>
                    </Col>
                    <Col md={24} style={{ margin: 20 }}>
                        <Row gutter={10}>
                            {alldata.map((row, index) => (
                                <Col md={6}>
                                    <div className=' flex'>
                                      <Button onClick={() => editFile(row)} className=' bg-[#DBE7F3] text-[#0C3762] text-base rounded-lg flex justify-center items-center py-5 px-16'
                                            style={{ border: '1px solid #A6CAEE' }}
                                        ><img src='/images/edit.png' alt='edit-icon' style={{ height: 25, paddingRight: 5 }} /> {row.document_type}
                                        </Button>
                                    </div>
                                </Col> ))}
                                
                            

                           
                        </Row>
                     
                    </Col>
                </Row>
            </div>
        </>
    )
}


export default StudentApplication

