import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Dropdown, Button, Space, Layout, Menu } from "antd";
import TeacherLayout from "../components/teacher-layout";
import { useNavigate, Link } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { encrypt, decrypt, encriptionKey } from '../components/utils';

const { Search } = Input;
const { Content } = Layout;

const MyStudents = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [studentList, setStudentList] = useState([]);

    const onMenuClick = (e) => {
        console.log('click', e);
    };
    const items = [
        {
            key: '1',
            label: 'Assessment Report',
        },
        {
            key: '2',
            label: 'Performance Report',
        },
        {
            key: '3',
            label: 'Diagnostic Result',
        },
    ];


    const fetchStudentList = async () => {

        await axios.post(api_url + 'faculty/get-class-student-list', {
            faculty_id: user_data.id

        }).then((res) => {
            //console.log(res.data.student_list);
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.student_list;

            const k = st_list.map((item, index) =>
            ({
                'sno': index + 1,
                'user_id': item.id,
                'name': decrypt(item.name, encriptionKey),
                //'center': item.center,
                'email': decrypt(item.email, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'class': decrypt(item.st_class, encriptionKey),
                'course': decrypt(item.st_course, encriptionKey),
                'test_result': item.test_result,
                'batch': item.batch,
            })
            );

            setStudentList(k);
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchStudentList()
    }, [])

    const onEditRequest = (record) => {
        navigate('/teacher-dashboard/assessment-list/' + record.user_id);

    };

    const onViewResult = (record) => {
        navigate('/teacher-dashboard/diagnostic-result/' + record.user_id);

    };

    const OnEditPerformance = (record) => {
        navigate('/teacher-dashboard/performance-list/' + record.user_id);
    };

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
        },
        {
            key: "2",
            title: "Name",
            dataIndex: "name",
            align: 'center'
        },
        {
            key: "3",
            title: "Email",
            dataIndex: "email",
            align: 'center'
        },
        {
            key: "4",
            title: "Mobile No.",
            dataIndex: "mobile",
            align: 'center'
        },
        {
            key: "5",
            title: "Class",
            dataIndex: "class",
            align: 'center'
        },
        {
            key: "6",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "6",
            title: "Batch",
            dataIndex: "batch",
            align: 'center'
        },
        {
            key: "7",
            title: "Action",
            align: 'center',
            render: (record) => {
                return (
                    // <Space>
                    //     <Button onClick={() => { onEditRequest(record); }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Assessment Report</Button>
                    //     <Button onClick={() => { OnEditPerformance(record); }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Performance Report</Button>
                    //     {record.test_result > 0 ? (<Button onClick={() => { onViewResult(record); }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Dignostic Result</Button>) : ('')}

                    // </Space>
                    <Space>
                        <Dropdown
                            overlay={
                                <Menu onClick={onMenuClick}>
                                    {items.map(item => (
                                        <Menu.Item key={item.key}>
                                            <Link
                                                className="text-[#205584]"
                                                // style={{ borderRadius: 11 }}
                                                onClick={() => {

                                                    switch (item.key) {
                                                        case '1':
                                                            onEditRequest(record);
                                                            break;
                                                        case '2':
                                                            OnEditPerformance(record);
                                                            break;
                                                        case '3':
                                                            if (record.test_result > 0) {
                                                                onViewResult(record);
                                                            }
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            >
                                                {item.label}
                                            </Link>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }
                        >
                            <Button className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>
                                Actions
                            </Button>
                        </Dropdown>
                    </Space>
                );
            },
        },
    ];

    return (
        <TeacherLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                <div className="mx-0 mt-0">
                    <Row>
                        <Col md={24} xs={24}>
                            <div className="bg-[#114D89] px-5 py-2.5">
                                <h1 className="text-white text-2xl ">Student Records</h1>
                            </div>
                        </Col>
                    </Row>
                    <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                        <Row>

                            <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                <Search placeholder="Search...." />
                            </Col>
                            <Col md={24} className="responsive-table">
                                <Table
                                    className="record"
                                    bordered
                                    columns={columns}
                                    pagination={tableParams.pagination}
                                    dataSource={studentList}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </TeacherLayout>
    )
}

export default MyStudents