import React, { useState, useEffect } from "react";
import { Row, Col, Layout, } from "antd";
import DashboardLayout from '../components/dashboard-layout';

import { ClockCircleOutlined } from '@ant-design/icons'
const { Content } = Layout;

const CalendarSchedule = () => {
    return (
        <DashboardLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                <Row>
                    <Col md={5}>
                        <div className="flex py-2.5 px-5" style={{ boxShadow: '0px 1px 8px #00000029', borderRadius: 10 }}>
                            <img src='/images/calendar-icon.png' alt='calendar-icon' />
                            <h1 className="text-[#8A8A8A] text-base pl-5">Today : 01-March-2023</h1>
                        </div>
                    </Col>
                </Row>
                <div className="bg-[#F6F6F6] mt-7 px-7 pt-7 pb-32">
                    <div className="flex justify-between p-5 my-2.5 bg-white" style={{ borderRadius: 25 }}>
                        <div className="flex">
                            <div className="bg-[#FFEFCC] p-2.5" style={{ borderRadius: 2 }}>
                                <img src='/images/blackboard.png' />
                            </div>
                            <div className="pl-2.5">
                                <h3 className="text-[#8A8A8A] text-base">Live Classes</h3>
                                <span className="text-[#F8A30C] text-sm">Mathematical Reasoning Classes</span>
                            </div>
                        </div>
                        <h4 className="text-[#0F4275] text-sm flex items-center"><ClockCircleOutlined className="pr-2.5" />Time : 01 : 00 PM</h4>
                    </div>
                    <div className="flex justify-between p-5 my-2.5 bg-white" style={{ borderRadius: 25 }}>
                        <div className="flex">
                            <div className="bg-[#C5E5FF] p-2.5" style={{ borderRadius: 2 }}>
                                <img src='/images/session.png' />
                            </div>
                            <div className="pl-2.5">
                                <h3 className="text-[#8A8A8A] text-base">Doubt Classes</h3>
                                <span className="text-[#F8A30C] text-sm">Chemistry in everyday life Ncert</span>
                            </div>
                        </div>
                        <h4 className="text-[#0F4275] text-sm flex items-center"><ClockCircleOutlined className="pr-2.5" />Time : 10 : 00 AM</h4>
                    </div>
                    <div className="flex justify-between p-5 my-2.5 bg-white" style={{ borderRadius: 25 }}>
                        <div className="flex">
                            <div className="bg-[#FFEFCC] p-2.5" style={{ borderRadius: 2 }}>
                                <img src='/images/counselling.png' />
                            </div>
                            <div className="pl-2.5">
                                <h3 className="text-[#8A8A8A] text-base">PBS Session</h3>
                                <span className="text-[#F8A30C] text-sm">Your PBS Session Schedule Today</span>
                            </div>
                        </div>
                        <h4 className="text-[#0F4275] text-sm flex items-center"><ClockCircleOutlined className="pr-2.5" />Time : 02 : 30 PM</h4>
                    </div>
                </div>
            </Content>

        </DashboardLayout>

    )
}

export default CalendarSchedule