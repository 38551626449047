import React, { useState, useEffect } from "react";
import axios from 'axios';
// import '@zoomus/websdk/dist/css/bootstrap.css'; // Import Zoom SDK CSS
// import '@zoomus/websdk/dist/css/react-select.css'; // Import Zoom SDK CSS
import '../styles/counsellor.css';
import {Input, Layout } from "antd";
import TeacherLayout from "../components/teacher-layout";
import { useNavigate,useParams } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
const { Search } = Input;
const { Content } = Layout;

const JoinClass = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    const { meetingid } = useParams();
    
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [studentList, setStudentList] = useState([]);

    
    useEffect(() => {
         
        axios.post(api_url + 'faculty/join_class', {
            meeting_id: meetingid

            }).then((res) => {
                loadZoomSDK(res);
            })
          
    }, [])

    const loadZoomSDK = async (response) => {
        let class_data = response.data.data;
        
        const { ZoomMtg } = await import('@zoomus/websdk');
        ZoomMtg.setZoomJSLib('https://source.zoom.us/2.15.2/lib', '/av');
  
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();
  
        ZoomMtg.i18n.load('en-US');
        ZoomMtg.i18n.reload('en-US');

       
  
        if(class_data){
            ZoomMtg.generateSDKSignature({
                meetingNumber : response.data.data.meeting_id,
                role : 1,
                sdkKey :  class_data.sdk_key,
                sdkSecret : class_data.sdk_secret,
                success:function(signature){
                   console.log(signature);
                    ZoomMtg.init({
                        leaveUrl: '/teacher-dashboard/my_classes',
                        success: (success) => {
                            ZoomMtg.join({
                                meetingNumber:  class_data.meeting_id,
                                sdkKey: class_data.sdk_key,
                                signature: signature.result,
                                userName: decrypt(user_data.name,encriptionKey) + '-'+ user_data.id,
                                userEmail: decrypt(user_data.email,encriptionKey),
                                // registrant_id : user_data.id,
                                passWord: class_data.password,
                                
                                success: (joinSuccess) => {
                                    console.log(joinSuccess);
                                    
                                },
                                error: (joinError) => {
                                    console.error('Error joining the meeting:', joinError);
                                },
                            });
                        },
                        error: (error) => {
                          console.log(error)
                        }
                      })
                }
            })
        }else{
            window.location.href = '/teacher-dashboard/my_classes';
        }
        
    };

   
   

    return (
       ''
    )
}

export default JoinClass