import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Dropdown, Button, Space, Layout, Progress } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
const { Search } = Input;
const { Content } = Layout;

const ApplicationStudentList = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [studentList, setStudentList] = useState([]);

    const onDeleteStudent = (record) => {
        //console.log(record);
    };


    const fetchApplicationStudentList = async () => {

        await axios.post(api_url + 'counsellor/application-student-list', {
            counsellor_id: user_data.id,
        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.users;

            const k = st_list.map((item, index) =>
            ({


                'sno': index + 1,
                'name': decrypt(item.name, encriptionKey),
                'center': item.center,
                'email': decrypt(item.email, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'number': decrypt(item.form_number, encriptionKey),
                'user_id': item.user_id,
                'app_country': item.app_country,
                'app_university': item.app_university,
                'user_id': item.user_id,
                'progress': <Progress percent={item.progress} status="active" strokeColor={{ from: '#108ee9', to: '#87d068', }} />,
            })
            );

            setStudentList(k);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchApplicationStudentList()
    }, [])



    const studentInfo = (record) => {
        navigate("/counsellor-dashboard/student-info/" + record.user_id)
    }

    const studentApplication = (record) => {
        navigate("/counsellor-dashboard/application-process/" + record.user_id)
    }




    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const searchItems = (searchValue) => {

        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = studentList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(studentList)
        }
    }

    const studentStatus = (record) => {
        navigate("/counsellor-dashboard/application-status/" + record.user_id)
    }

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",

        },
        {
            key: "3",
            title: "Form Number",
            dataIndex: "number",
            align: 'center',
        },
        {
            key: "3",
            title: "Name",
            dataIndex: "name",
            align: 'center',
        },
        {
            key: "4",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "5",
            title: "Email",
            dataIndex: "email",
            align: 'center'
        },
        {
            key: "6",
            title: "Mobile",
            dataIndex: "mobile",
            align: 'center'
        },

        {
            key: "7",
            title: "Progress",
            dataIndex: "progress",
            align: 'center'
        },


        {
            key: "7",
            title: "Actions",
            render: (record) => {
                return (
                    <>
                        <Space>

                            <Button onClick={() => {
                                studentInfo(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>View Record</Button>
                            {record.app_country === "1" && record.app_university === "1" ? (<Button onClick={() => {
                                studentApplication(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Application Record</Button>) : ('')}
                            {record.app_country === "1" && record.app_university === "1" ? (<Button onClick={() => {
                                studentStatus(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Status</Button>) : ('')}
                        </Space>

                    </>
                );
            },
        },
    ];

    const columnsCountry = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",

        },
        {
            key: "3",
            title: "Form Number",
            dataIndex: "number",
            align: 'center',
        },
        {
            key: "3",
            title: "Name",
            dataIndex: "name",
            align: 'center',
        },
        {
            key: "4",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "5",
            title: "Email",
            dataIndex: "email",
            align: 'center'
        },
        {
            key: "6",
            title: "Mobile",
            dataIndex: "mobile",
            align: 'center'
        },



        {
            key: "7",
            title: "Actions",
            render: (record) => {
                return (
                    <>
                        <Space>

                            <Button onClick={() => {
                                studentInfo(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>View Record</Button>
                            {user_data.counsellor_type === "Editor Counsellor" || user_data.counsellor_type === "Editor Counsellor Head" ? (<Button onClick={() => {
                                studentApplication(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Application Record</Button>) : ('')}
                            <Button onClick={() => {
                                studentStatus(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Status</Button>
                        </Space>

                    </>
                );
            },
        },
    ];
    return (
        <>
            <CounsellorLayout>

                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mt-10">
                        <Row>
                            <Col md={24} xs={24}>
                                <div className="bg-[#114D89] px-5 py-2.5">
                                    <h1 className="text-white text-2xl ">Student List</h1>
                                </div>
                            </Col>
                        </Row>

                        <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                            {/* <Row>
                            <Col>
                                <h2 className="text-[#061522] text-2xl mb-2.5">PBS Student Session List</h2>
                                <span className="text-[#9B9B9B] text-lg">This is your most recent records.</span>
                            </Col>
                        </Row> */}


                            <Row>

                                <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                    <Search onChange={(e) => searchItems(e.target.value)} placeholder="Search...." />
                                </Col>
                                {user_data.counsellor_type === "Country Counsellor" || user_data.counsellor_type === "Editor Counsellor" || user_data.counsellor_type === "Editor Counsellor Head" ? (
                                    <>
                                        <Col md={24} className="responsive-table">
                                            {searchInput.length > 1 ? (
                                                <Table className="record" bordered columns={columnsCountry} dataSource={filteredResults} />
                                            ) : (
                                                <Table className="record" bordered columns={columnsCountry} dataSource={studentList} />
                                            )}
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col md={24} className="responsive-table">
                                            {searchInput.length > 1 ? (
                                                <Table className="record" bordered columns={columns} dataSource={filteredResults} />
                                            ) : (
                                                <Table className="record" bordered columns={columns} dataSource={studentList} />
                                            )}
                                        </Col>
                                    </>

                                )}



                            </Row>
                        </div>
                    </div>
                </Content>
            </CounsellorLayout>
        </>
    )
}

export default ApplicationStudentList