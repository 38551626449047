import React, { useEffect, useState } from 'react';
import '../styles/faculty.css';
import axios from 'axios';
import { Layout, Button, Form, Col, Row, Input, Radio } from 'antd';
import TeacherLayout from "../components/teacher-layout";
import { useParams, useNavigate } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { BackwardOutlined } from '@ant-design/icons';
const { Content } = Layout;
const { TextArea } = Input;

const AssessmentReport = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({ username: "", centername: "", classname: "", coursename: "" });

    const fetchUserDetails = async () => {

        await axios.post(api_url + 'get-user-details', {
            user_id: id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.userdata;
            setUserData(st_list);
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchUserDetails()
    }, [])

    const onFinish = (values) => {
        axios.post(api_url + 'faculty/submit_assessment', {
            faculty_id: user_data.id,
            user_id: id,
            records: values,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    // const timer = setTimeout(() => {
                    //     navigate("/counsellor-dashboard/targets/" + id)
                    // }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })
        //console.log('Success:', values);
    };

    const onBackRequest = (record) => {
        navigate('/teacher-dashboard/assessment-list/' + id)
    }

    return (
        <TeacherLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                <div className="mx-16 mt-7 mb-10" style={{ border: '1px solid #E5E5E5' }}>
                    <Row>
                        <Col md={24}>
                            <div className='flex bg-[#114D89] px-5 py-2.5 justify-between '>
                                <div>
                                    <h1 className="text-white text-xl text-center">ASSESSMENT REPORT</h1>
                                    <span className='text-sm text-white text-center'>(2022-2023)</span>
                                </div>
                                <div>

                                </div>
                                <BackwardOutlined onClick={onBackRequest} className='text-white text-3xl flex items-center justify-center' />
                            </div>
                        </Col>
                    </Row>

                    <div className='bg-[#F1F3F6]' style={{ padding: '20px 30px' }}>
                        <Row gutter={30} >
                            <Col md={12}>
                                <div className='flex py-1.5'>
                                    <h3 className='text-base text-[#0C3762] font-medium'>Name</h3>
                                    <span className='text-base text-[#787878] font-medium pl-16'>{decrypt(userData.username, encriptionKey)}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className='flex py-1.5'>
                                    <h3 className='text-base text-[#0C3762] font-medium'>Class</h3>
                                    <span className='text-base text-[#787878] font-medium pl-20'>{decrypt(userData.classname, encriptionKey)}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className='flex py-1.5'>
                                    <h3 className='text-base text-[#0C3762] font-medium'>Centre</h3>
                                    <span className='text-base text-[#787878] font-medium pl-16'>{userData.centername}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row style={{ marginTop: 30 }}>
                        <Col md={24}>
                            <div className='bg-[#F1F3F6] px-7'>
                                <Form className='evaluation py-5' onFinish={onFinish} >
                                    <div>
                                        <Row>
                                            <Col md={24}>
                                                <h2 className='text-[#0C3762] text-lg p-2.5 text-center font-bold'>REPORT OF MONTH</h2>
                                            </Col>
                                        </Row>
                                        <div className='bg-[#F1F3F6] py-5 px-7 mb-5'>
                                            <div className='attendance py-5'>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Item
                                                            name="month"
                                                            label="Month"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                        >
                                                            <Input
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col md={24}>

                                            <table className='test-result'>
                                                <thead>
                                                    <tr>
                                                        <th>MODULE</th>
                                                        <th>SCORE</th>
                                                        <th>FEEDBACK</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='topic'>Grammar</td>
                                                        <td>
                                                            <Form.Item name='grammar_score'>
                                                                <Input type='number' />
                                                            </Form.Item>
                                                        </td>
                                                        <td>
                                                            <Form.Item name='grammar_feedback'>
                                                                <TextArea rows={1} />
                                                            </Form.Item>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className='topic'>Vocabulary</td>
                                                        <td>
                                                            <Form.Item name='vocabulary_score' >
                                                                <Input type='number' />
                                                            </Form.Item>

                                                        </td>
                                                        <td>
                                                            <Form.Item name='vocabulary_feedback'>
                                                                <TextArea rows={1} />
                                                            </Form.Item>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className='topic'>Listening</td>
                                                        <td>
                                                            <Form.Item name='listening_score'>
                                                                <Input type='number' />
                                                            </Form.Item>
                                                        </td>
                                                        <td>
                                                            <Form.Item name='listening_feedback'>
                                                                <TextArea rows={1} />
                                                            </Form.Item>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className='topic'>Speaking</td>
                                                        <td>
                                                            <Form.Item name='speaking_score'>
                                                                <Input type='number' />
                                                            </Form.Item>
                                                        </td>
                                                        <td>
                                                            <Form.Item name='speaking_speaking'>
                                                                <TextArea rows={1} />
                                                            </Form.Item>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className='topic'>Reading</td>
                                                        <td>
                                                            <Form.Item name='reading_score'>
                                                                <Input type='number' />
                                                            </Form.Item>
                                                        </td>
                                                        <td>
                                                            <Form.Item name='reading_feedback'>
                                                                <TextArea rows={1} />
                                                            </Form.Item>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='topic'>Writing</td>
                                                        <td>
                                                            <Form.Item name='writing_score'>
                                                                <Input type='number' />
                                                            </Form.Item>
                                                        </td>
                                                        <td>
                                                            <Form.Item name='writing_feedback'>
                                                                <TextArea rows={1} />
                                                            </Form.Item>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>

                                    <div className='mt-7 bg-[#F1F3F6] p-5'>
                                        <Row>
                                            <Col md={24}>
                                                <h2 className='text-[#0C3762] text-lg font-bold mb-5'>Overall Feedback</h2>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={24}>
                                                <Form.Item name='overall_feedback'>
                                                    <TextArea rows={4} className='remark' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='mt-7 bg-[#F1F3F6] p-5'>
                                        <Row>
                                            <Col md={24}>
                                                <h2 className='text-[#0C3762] text-lg font-bold mb-5'>Overall Performence</h2>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={24}>
                                                <Form.Item name='performance'>
                                                    <Radio.Group className='overall-performance'  >
                                                        <Radio value={1}>Excellent</Radio>
                                                        <Radio value={2}>Good</Radio>
                                                        <Radio value={3}>Can Do Better</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col md={24} className='flex justify-center my-7'>
                                            <Button className="bg-[#205584] text-white" htmlType="submit" >Submit</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>
        </TeacherLayout>
    )
}

export default AssessmentReport