import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Select, Button, Space, Layout, Modal, Form, Upload, Menu, Dropdown } from "antd";
import TeacherLayout from "../components/teacher-layout";
import { useNavigate } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DownOutlined } from '@ant-design/icons';
const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

const Class = () => {
    const [loading, setLoading] = useState(true);
    const [classList, setClassList] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [file, setFile] = useState(null);
    const [status, setStatus] = useState(null);
    const [classLink, setClassLink] = useState(null);
    const [remarks, setClassRemarks] = useState('');
    const [classId, setClassId] = useState(null);
    const [form] = Form.useForm();
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const dateAfterAddition = new Date(currentDateTime);
    console.log(dateAfterAddition);
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const onFinish = () => {

        const formData = new FormData();

        if (status === "Complete" || status === "Cancelled") {

            if (file) {
                for (let i = 0; i < file.length; i++) {

                    formData.append('files_' + i, file[i]);
                }
            }

            formData.append('status', status)
            // formData.append('link', classLink)
            formData.append('remarks', remarks)
            formData.append('classId', classId);
            formData.append('user_id', user_data.id);

            axios.post(api_url + 'faculty/update-class', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        setEditModal(false);
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }


            }).catch(error => {
                console.error(error);
            });


        }

    };

    const joinZoomMeeting = (record) => {
        navigate("/teacher-dashboard/join-class/" + record.meeting_id);
    };

    const viewZoomMeeting = (record) => {
        navigate("/teacher-dashboard/view-class/" + record.meeting_id);
    };


    const getAttendance = (record) => {

        axios.post(api_url + 'application/getAttendance', {
            meeting_id: record.meeting_id,

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }

            if (res.data.status) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                const timer = setTimeout(() => {
                    setEditModal(false);
                    window.location.reload();
                }, 3000);

            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_CENTER
                });
            }



        })
    }


    const setEditHandle = (record) => {

        axios.post(api_url + 'faculty/get-class-details', {
            class_id: record.class_id

        }).then((res) => {

            if (res.data.status) {
                setClassLink(res.data.data.class_link)
                setStatus(res.data.data.status)
                if (res.data.data.status === "Approved") {
                    setStatus('Pending')
                }

                setClassId(record.class_id)
                setClassRemarks(res.data.data.reject_remarks)
                setTimeout(() => {
                    setEditModal(true);
                }, 500);

            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        })

    }

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
            align: 'center',
        },
        {
            key: "8",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "2",
            title: "Topic",
            dataIndex: "topic",
            align: 'center'
        },
        {
            key: "3",
            title: "Date",
            dataIndex: "date",
            align: 'center'
        },
        {
            key: "4",
            title: "Duration (Min)",
            dataIndex: "duration",
            align: 'center'
        },
        {
            key: "5",
            title: "Status",
            dataIndex: "status",
            align: 'center'
        },
        {
            key: "7",
            title: "Action",
            align: 'center',
            render: (record) => {
                return (
                    <>
                        {/* {record.status === 'Cancelled' || record.reject_status === '1' ? <>
                        </>
                            :
                            <>

                                <Button className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }} onClick={() => viewZoomMeeting(record)}>View Class</Button>
                                <Button className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }} onClick={() => setEditHandle(record)}>Edit</Button></>}

                        {record.status === "Pending" ? (<Button className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }} onClick={() => joinZoomMeeting(record)}>Join Class</Button>) : (
                            ''
                        )}
                        {record.status === "Complete" && record.attendance === 0 ? (
                            <Button
                                className="bg-[#F0AA0C] text-white"
                                style={{ borderRadius: 11 }}
                                onClick={() => getAttendance(record)}
                            >
                                Get Attendance
                            </Button>
                        ) : (
                            ''
                        )} */}

                        <Dropdown overlay={
                            <Menu>
                                 {record.status === "Complete" && record.attendance === 1 ? (
                                    <Menu.Item key="viewClass">
                                     <a href="#" onClick={() => viewZoomMeeting(record)}>
                                     View Recording
                                    </a>
                                    </Menu.Item>
                                ) : (
                                    ''
                                )}
                                
                                   
                               
                                <Menu.Item key="edit">
                                    <a href="#" onClick={() => setEditHandle(record)}>
                                        Edit
                                    </a>
                                </Menu.Item>
                                {record.status === "Pending" && ((new Date(record.date).setMinutes(new Date(record.date).getMinutes() + record.duration)) > dateAfterAddition ? (<>
                                    <Menu.Item key="joinClass">
                                        <a href="#" onClick={() => joinZoomMeeting(record)}>
                                            Join Class
                                        </a>
                                    </Menu.Item></>
                                ):('')
                                    
                                )}
                                {record.status === "Complete" && record.attendance === 0 && (
                                    <Menu.Item key="getAttendance">
                                        <a href="#" onClick={() => getAttendance(record)}>
                                            Get Attendance
                                        </a>
                                    </Menu.Item>
                                )}
                            </Menu>
                        } placement="bottomLeft">
                            <Button className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }}>
                                Actions
                            </Button>
                        </Dropdown>

                        <Modal title="Update Class Details" open={editModal} onCancel={() => setEditModal()} footer={null}>
                            <Form form={form} onFinish={onFinish}>

                                <Form.Item label="Class Status" name="class_status" >
                                    {remarks ? <><Select value={status} onChange={(value) => setStatus(value)}>
                                        <Option value="Cancelled">Cancelled</Option>
                                    </Select></> : <>

                                        <Select value={status} onChange={(value) => setStatus(value)}>
                                            <Option value="Pending" >Pending</Option>
                                            <Option value="Complete" >Complete</Option>
                                            <Option value="Cancelled">Cancelled</Option>
                                        </Select>

                                    </>}

                                </Form.Item>
                                {/* <Form.Item label="Class Link" initialValue={classLink} name="link" style={{ display: status === 'Complete' || status === 'Cancelled' ? 'none' : 'block' }}>
                                    <Input placeholder="Enter the link" onChange={(e) => setClassLink(e.target.value)} disabled={status === 'Complete' || status === 'Cancelled' ? true : false} />
                                </Form.Item> */}
                                {status === "Cancelled" ? <><Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter remarks',
                                        },
                                    ]}
                                    label="Remarks"
                                    initialValue={remarks} // Ensure initialValue is set to remarks
                                    name="remarks"
                                    style={{ display: status === 'Cancelled' ? 'block' : 'none' }}
                                >
                                    <Input
                                        placeholder="Enter the Remarks"
                                        onChange={(e) => setClassRemarks(e.target.value)} // Update remarks when input changes
                                        disabled={status === 'Complete' ? true : false}
                                    />
                                </Form.Item></> : <></>}

                                {remarks ? <></> : <> <Form.Item label="Study Material" name="file" style={{ display: status === 'Pending' || status === 'Cancelled' ? 'none' : 'block' }}>
                                    <input type="file" onChange={(e) => setFile(e.target.files)} multiple disabled={status === 'Pending' || status === 'Cancelled' ? true : false} />
                                    <p className="text-red-600 text-sm">Note: Can Upload multiple files</p>
                                </Form.Item></>}

                                <Form.Item wrapperCol={{ offset: 6 }}>
                                    <Button type="primary" htmlType="submit" className="bg-[#F0AA0C]">
                                        Submit
                                    </Button>
                                </Form.Item>

                            </Form>
                        </Modal>

                    </>
                );
            },
        },
    ];

    const fetchClassesList = async () => {

        await axios.post(api_url + 'faculty/get-my-classess', {
            faculty_id: user_data.id

        }).then((res) => {
            //console.log(res.data.live_class);
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const class_list = res.data.live_class;
            //console.log(class_list);
            const k = class_list.map((item, index) =>
            ({
                'sno': index + 1,
                'course': item.course_name,
                'topic': item.class_name,
                'date': item.date + ' ' + item.start_time,
                'duration': item.duration,
                'time': item.time_slot,
                'status': item.status === 'Approved' ? 'Pending' : (item.status === "Cancelled" && item.reject_status === "1") ? 'Cancelled - ' + item.reject_remarks : item.status,
                'class_id': item.id,
                'meeting_id': item.meeting_id,
                'attendance': item.attendance,
                'link': item.link,
                'reject_status': item.reject_status
            })
            );

            // console.log(k);

            setClassList(k);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchClassesList()
    }, [])


    return (
        <TeacherLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                <div className="mx-0 mt-0">
                    <Row>
                        <Col md={24} xs={24}>
                            <div className="bg-[#114D89] px-5 py-2.5">
                                <h1 className="text-white text-2xl ">Classes</h1>
                            </div>
                        </Col>
                    </Row>
                    <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                        <Row>

                            <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                <Search placeholder="Search...." />
                            </Col>
                            <Col md={24} className="responsive-table">
                                <Table
                                    className="record"
                                    bordered
                                    columns={columns}
                                    dataSource={classList}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </TeacherLayout>
    )
}

export default Class