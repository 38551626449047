import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form, Input, Skeleton } from 'antd';
import Header from '.././components/header';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import '../styles/form.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url, base_url } from '../components/constants';
import { decrypt, encriptionKey } from "../components/utils";


const DiagnosticGuideline = () => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    if(!login_user){
        window.location.href="/";
    }
    const user_data = login_user.user;
    console.log(decrypt(user_data.class,encriptionKey));
    return (
        <>
            <Header />
            <div className="page-wrapper">
                <div className="page-corners">
                    <TopBanner />
                    <div className="container flex justify-center items-center">
                        <div className="form-layout mt-10 mb-48">
                            <div className="p-5">
                                <h1 className="text-[#1E1D1D] text-lg italic">Diagnostic Test Guidelines:</h1>
                                
                                    
                                

                                    <ol className="guideline">
                                    <li><strong>Timing:</strong> The online diagnostic test will be one hour long.</li>
                                    <li><strong>Test Format: </strong>The test comprises of 42 questions. The last two questions in the writing part
                                        are compulsory. All multiple choice questions (MCQs) will be of 1 mark and the two writing
                                        questions will be of 5 marks each.</li>
                                    <li><strong>Test Submission:</strong> Once you start the test, you will not be able to stop or pause it. The test
                                        will be automatically submitted after one hour. Therefore, it is important to ensure that you
                                        have a reliable internet connection and a working computer.</li>
                                  
                                    <li><strong>Preparation:</strong> Attempt the test only when you can spare an hour dedicatedly to it. Ensure
                                        that you have a comfortable and quiet environment to avoid any distractions.</li>
                                    <li><strong>Retake Policy:</strong> Once started, the test cannot be taken again. Therefore, it is important to
                                        take the test seriously and attempt it to the best of your ability.</li>
                                        {decrypt(user_data.class,encriptionKey) === "9" ? (
                                    <li><strong>Teacher's discretion:</strong> The teacher holds the final say on which batch the student will be
                                        placed in, and they reserve the right to make changes to the allotted batch based on the
                                        results of the diagnostic test or the class performance of the student.</li>
                                        ) : ('')}
                                    <li><strong>Test Completion:</strong> If you complete the test before an hour, you can submit it. It is
                                        recommended that you use the remaining time to review your answers and ensure that you
                                        have answered all questions to the best of your ability.</li>
                                </ol>
                                
                                
                                



                                <div className="flex justify-center items-center">
                                    <Button href={'/student-portal/diagnostic'} className="text-lg text-[#fff] bg-[#0D3761] rounded-lg py-1.5 px-5 flex justify-center mr-2.5"
                                        style={{ height: 'auto' }} type="primary">Start Test</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default DiagnosticGuideline