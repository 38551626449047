import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/dashboard.css';
import { Button, Modal, Form, Col, Row, Layout, Space, Select, Spin, Input } from 'antd';

import { Link } from 'react-router-dom';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { useNavigate } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import TeacherLayout from "../components/teacher-layout";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Content } = Layout;
const { Option } = Select;

const PtmSession = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true)
    const [editModal, setEditModal] = useState(false);
    const [ptmData, setptmData] = useState([{ id: "", date: "", time_slot: "", status: "", faculty: "", link: "" }]);

    const [status, setStatus] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const [sessionLink, setSessionLink] = useState(null);
    const [ptmId, setPtmId] = useState(null);



    const fetchPTMData = async () => {
        await axios.post(api_url + 'faculty/get_ptm_session', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const ptm_data = res.data.data;
            setptmData(ptm_data);
            setLoading(false)

        })
    }




    useEffect(() => {
        fetchPTMData()
    }, [])



    const onFinish = (values) => {

        axios.post(api_url + 'faculty/update_ptm_request', {
            ptm_id: ptmId,
            user_id: user_data.id,
            ptm_data: values,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            });
    };

    const setEditHandle = (id) => {
        axios.post(api_url + 'faculty/get-ptm-session-details', {
            ptm_id: id

        }).then((res) => {
            //console.log(res.data.data);
            if (res.data.status) {
                setSessionLink(res.data.data.link);
                setPtmId(id)
                setEditModal(true);
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        })

    }

    return (
        <>
            <TeacherLayout>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >

                    <Modal title="Update PTM Session Details" open={editModal} onOk={() => setEditModal(false)} onCancel={() => setEditModal(false)} footer={null}>
                        <Form onFinish={onFinish}>
                            <Form.Item label="Session Status" name="status" >
                                <Select defaultValue="Select" onChange={(value) => setStatus(value)}>
                                    <Option value="Pending" >Pending</Option>
                                    <Option value="Complete" >Complete</Option>
                                    <Option value="Cancelled">Cancelled</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Session Link" initialValue={sessionLink} name="link" style={{ display: status === 'Complete' || status === 'Cancelled' ? 'none' : 'block' }}>
                                <Input placeholder="Enter the Session link" onChange={(e) => setSessionLink(e.target.value)} disabled={status === 'Complete' || status === 'Cancelled' ? true : false} />
                            </Form.Item>
                            <Form.Item label="Remarks" initialValue={remarks} name="remarks" style={{ display: status === 'Cancelled' ? 'block' : 'none' }}>
                                <Input placeholder="Enter the Remarks" onChange={(e) => setRemarks(e.target.value)} disabled={status === 'Cancelled' ? false : true} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 6 }}>
                                <Button type="primary" htmlType="submit" className="bg-[#F0AA0C]">
                                    Submit
                                </Button>
                            </Form.Item>

                        </Form>
                    </Modal>

                    <Row gutter={30}>

                        <Col md={24} xs={24}>
                            <div className="form-layout form-space" style={{ background: '#F5F5F5' }}>

                                <div className='flex bg-[#114D89] px-5 py-2.5 justify-between '>
                                    <div className="">
                                        <h1 className="text-white text-xl text-center">PTM Session</h1>
                                    </div>
                                </div>


                                {ptmData.length > 0 ? (ptmData.map((row, index) => (
                                    <div key={index} className="lg:mx-10 mx-5 my-7 p-5 bg-[#FBFBFB]" style={{ borderRadius: 29 }}>
                                        <Row gutter={30}>
                                            <Col lg={4} xs={24} md={24}>
                                                <div className="flex pb-2.5">
                                                    <div className="bg-white p-3.5 rounded-full" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                        <img src="/images/calendar.png" alt='calendar-img' />
                                                    </div>
                                                    <span className="text-xl text-[#0C3762] flex justify-center items-center pl-7 capitalize">{row.date}</span>
                                                </div>
                                            </Col>
                                            <Col lg={6} xs={24} md={24}>
                                                <div className="flex pb-2.5">
                                                    <div className="bg-white p-3.5 rounded-full" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                        <img src="/images/clock.png" alt='clock-img' />
                                                    </div>
                                                    <span className="text-xl text-[#0C3762] flex justify-center items-center pl-7 capitalize">{row.time_slot}</span>
                                                </div>
                                            </Col>
                                            <Col lg={4} xs={24} md={24}>
                                                <div className="flex pb-2.5">
                                                    <div className="bg-white p-3.5 rounded-full" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                        <img src="/images/presentation.png" alt='presentation-img' />
                                                    </div>
                                                    <span className="text-xl text-[#0C3762] flex justify-center items-center pl-7 capitalize">
                                                        {row.faculty != null ? (decrypt(row.faculty, encriptionKey)) : ('Mentor Not Assign')

                                                        }


                                                    </span>
                                                </div>
                                            </Col>
                                            <Col lg={4} xs={24} md={24}>
                                                <div className="flex pb-2.5">
                                                    <div className=" p-3.5 " >

                                                    </div>
                                                    <span style={{ lineHeight: "3.75rem" }} className="text-xl text-[#0C3762] flex justify-center items-center pl-7 capitalize">
                                                        {row.status}
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col lg={4} xs={24} md={24}>
                                                <div className="flex justify-center items-center mt-2.5 ml-10">
                                                    {/* {(row.status == "Approved" || row.status == "Pending") && row.link == null ? ( */}
                                                    <Link onClick={() => setEditHandle(row.id)}
                                                        className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center items-center mr-2.5"
                                                        style={{ height: 'auto', borderRadius: 20 }}
                                                    >
                                                        Edit
                                                    </Link>
                                                    {/* ) : ('')

                                                    } */}
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                ))) : (<div className="my-24">
                                    <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                </div>)}




                            </div>
                        </Col>
                    </Row>


                </Content>

            </TeacherLayout>
        </>
    )
}

export default PtmSession