import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import axios from 'axios';
import { Button, Col, Row, Layout, Space, Radio, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { api_url, reportPath } from '../components/constants';
import DashboardLayout from '../components/dashboard-layout';
const { Content } = Layout;
const { Option } = Select;



const Level2 = () => {
    const [value, setValue] = useState(1);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(1);
    const [showPassageReading, setShowPassageReading] = useState(false);
    const [showQuiz, setShowQuiz] = useState(false); // Step 1
    const [showBeginOption, setShowBeginOption] = useState(true);



    const onChange = e => {
        setValue(e.target.value);
    };


    // Function to handle subject selection
    const handleSubjectSelection = (value) => {
        setSelectedSubject(value);
    };

    // Function to handle level selection
    const handleLevelSelection = (e) => {
        setSelectedLevel(e.target.value);
    };

    // Function to handle Begin button click
    const handleBeginClick = () => {
        if (selectedSubject && selectedLevel) {
            setShowPassageReading(true);
            setShowBeginOption(false);
        }
    };

    const handleDoneClick = () => {
        setShowPassageReading(false);
        setShowQuiz(true); // Step 3
    };


    return (
        <>
            <DashboardLayout>

                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <Row gutter={30}>

                        <Col md={24} xs={24}>

                            <div className="form-layout form-space" style={{ background: '#F5F5F5' }}>

                                <div className='flex  pbs-session-heading bg-[#114D89] px-5 py-2.5 justify-between '>
                                    <h1 className="text-white text-xl text-center font-light">Speed Reading</h1>
                                    <p className="text-right text-white text-xl font-light">Level-2</p>
                                </div>
                                <div className="bg-[#F5F5F5] p-7">
                                    <Row>
                                        <Col md={24}>
                                            <div className='flex items-center justify-center'>

                                                <div className='p-2.5' style={{ background: '#F5C964', borderRadius: 10 }}>
                                                    <div className='flex items-center'>
                                                        <span className="dot"></span>
                                                        <p className='text-[#fff] pl-2.5 text-sm'>
                                                            Round 1
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr style={{ border: 'none', borderBottom: '1px solid #DBDBDB', width: '20%' }} />

                                                <div className='p-2.5' style={{ background: '#fff', borderRadius: 10 }}>
                                                    <div className='flex items-center'>
                                                        <span className="dot"></span>
                                                        <p className='text-[#F0AA0C] pl-2.5 text-sm'>
                                                            Round 2
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr style={{ border: 'none', borderBottom: '1px solid #DBDBDB', width: '20%' }} />

                                                <div className='p-2.5' style={{ background: '#fff', borderRadius: 10 }}>
                                                    <div className='flex items-center'>
                                                        <span className="dot"></span>
                                                        <p className='text-[#F0AA0C] pl-2.5 text-sm'>
                                                            Round 3
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>



                                    <Row gutter={20} style={{ paddingTop: 40 }}>
                                        <Col md={4}>
                                            <p className='text-[#114D89] text-lg font-light pl-2.5 pb-2.5'>Choose Subject</p>
                                            <Select
                                                placeholder="Select Subject"
                                                onChange={handleSubjectSelection}
                                                style={{ width: '100%', borderRadius: 8, boxShadow: '0px 3px 6px #00000029' }}
                                            >
                                                <Option value="subject1">Subject 1</Option>
                                                <Option value="subject2">Subject 2</Option>
                                                {/* Add more options as needed */}
                                            </Select>
                                        </Col>
                                        <Col md={15}>
                                            {showBeginOption ? (
                                                <>
                                                    <div className='reading-space pt-5'>
                                                        <p className='text-[#60666B] text-sm pb-20'>Please Select The Level and Subject, then click on the Begin button.</p>

                                                    </div>
                                                    <div className='reading flex justify-end'>
                                                        <Button
                                                            className='text-[#114D89] bg-[#F5C964]'
                                                            style={{ borderRadius: 10, border: '1px solid #F5C964' }}
                                                            onClick={handleBeginClick}
                                                        >
                                                            Begin
                                                        </Button>
                                                    </div>
                                                </>
                                            ) : null}
                                            {showPassageReading ? (
                                                <PassageReading handleDoneClick={handleDoneClick} />
                                            ) : null}
                                            {showQuiz ? (
                                                <QuizComponent />
                                            ) : null}
                                        </Col>

                                        <Col md={5}>
                                            <p className='text-[rgb(17,77,137)] text-lg font-light pl-2.5 pb-2.5'>Choose Level</p>
                                            <div className='level'>
                                                <Radio.Group className='custom-level' onChange={onChange} onClick={handleLevelSelection}>
                                                    <Radio value={1} className='choose-level'>Easy Level</Radio>
                                                    <Radio value={2} className='choose-level'>Medium Level</Radio>
                                                    <Radio value={3} className='choose-level'>Hard Level</Radio>
                                                </Radio.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* {showQuiz && <QuizComponent />}  */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Content>

            </DashboardLayout>

        </>
    )
}



const PassageReading = ({ handleDoneClick }) => {
    return (
        <div>


            <div className='reading-space pt-5'>
                <p className='text-[#60666B] text-sm pb-20'>
                    text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                    It was popularised in the 1960s with the release of Letraset sheets containing
                    Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

            </div>
            <div className='reading flex justify-between'>
                <p className='text-base text-[#fff] font-light'>1280 Words/Minute</p>
                <Space>
                    <Button className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }}>PAUSE</Button>
                    <Button className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }}>PLAY</Button>
                    <Button onClick={handleDoneClick} className='text-[#114D89] bg-[#F5C964]' style={{ borderRadius: 10, border: '1px solid #F5C964' }}>DONE</Button>
                </Space>


            </div>

        </div>
    )
}


const QuizComponent = () => {

    const [value, setValue] = useState(1);
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    // const [form] = Form.useForm();

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };


    const question = [
        {
            id: 1,
            question: 'What is the capital of France?',
            options: ['Paris', 'London', 'Berlin', 'Madrid'],
            answer: 'Paris'
        },
        {
            id: 2,
            question: 'What is the largest ocean in the world?',
            options: ['Atlantic', 'Indian', 'Arctic', 'Pacific'],
            answer: 'Pacific'
        },
    ]



    const handleNextQuestion = () => {
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    };

    return (
        <>

            <div className='reading-space pt-5 '>
                <div className='flex justify-end pb-5'>
                    <img src='/images/clock.png' style={{ width: '20px', height: '20px' }} />
                    <span className='pl-2.5 text-[#114D89]'>10:00Min</span>
                </div>
                <p className='text-[#062646] text-base pb-5'>
                    Q1  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>

                <Radio.Group onChange={onChange} value={value} className='pb-10'>
                    <Space direction="vertical">
                        <Radio value={1} className='options'>Option A</Radio>
                        <Radio value={2} className='options'>Option B</Radio>
                        <Radio value={3} className='options'>Option C</Radio>
                    </Space>
                </Radio.Group>

            </div>
            <div className='reading flex justify-center'>

                <Button className='text-white font-light bg-[#F8B133]' style={{ borderRadius: 10, border: '1px solid #F5C964' }} onClick={handleNextQuestion}>
                    NEXT
                </Button>

            </div>

        </>
    )
}

export default Level2