import React, { useState } from "react";
import { Button, Col, Row, Steps } from 'antd';
import { Link } from 'react-router-dom';
import { encriptionKey, encrypt, decrypt } from "./utils";
import '../styles/responsive.css';

const TopBanner = (props) => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const className = decrypt(user_data.class, encriptionKey);

    //console.log(window.location.pathname);

    let visitedPages = JSON.parse(sessionStorage.getItem('visitedPages'));

    return (
        <>

            <Col md={24} xs={24} className="top-step" style={{ display: 'flex', justifyContent: 'center', marginBottom: 40, marginLeft: 30 }}>

                {/* {className == "9" || className == "10" ? (
                    window.location.pathname == '/student-portal/diagnostic-guideline' || window.location.pathname == '/student-portal/diagnostic' ?
                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5 visited"
                        >Diagnostic Test</Link> :
                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5"
                        >Diagnostic Test</Link>
                ) : (
                    ''
                )} */}
                {
                    window.location.pathname == '/student-portal/general-enquiry' ?
                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5 visited"
                        >GEF Form </Link> :
                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5"
                        >GEF Form </Link>
                }

                {
                    window.location.pathname == '/student-portal/general-intelligence-quotient' ?

                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5 visited"
                        > GIQ Form</Link> :
                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5"
                        > GIQ Form</Link>
                }

                {
                    window.location.pathname == '/student-portal/student-profile-report' ?

                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5 visited"
                        > Profile Format</Link> :
                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5"
                        > Profile Format</Link>
                }


                {className == "9" ? (

                    window.location.pathname == '/student-portal/psychometric-report' ?
                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5"
                        >Psychometric Report</Link> :
                        <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5"
                        >Psychometric Report</Link>


                ) : (

                    className == "11" || className == "12" ? (
                        window.location.pathname == '/student-portal/diagnostic-report' ?
                            <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5">
                                Diagnostic Report</Link> :
                            <Link type="primary" htmlType="submit" className="top-banner-wrapper lg:mx-16 mx-5">
                                Diagnostic Report</Link>

                    ) : ('')

                )}










            </Col>

        </>
    )
}

export default TopBanner