import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Form, Input, Select, Table, Popconfirm, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import { api_url, base_url } from '../components/constants';
const { Option } = Select;


const Internships = ({ show, hide }) => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    const user_data = login_user.user;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [internData, setinternData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);

    const handleAddRow = () => {
        setinternData([...internData, { year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);
    };


    const handleChange = (e, index) => {
        const newData = [...internData];
        newData[index][e.target.name] = e.target.value;
        setinternData(newData);

    };

    const handleSubmit = () => {

        axios.post(api_url + 'user/update_internships_records', {
            user_id: user_data.id,
            data: internData,

        })
            .then(result => {
                if (result.data.status) {

                    Swal.fire({
                        icon: 'success',
                        title: result.data.message
                    });
                    window.location.reload();
                    //navigate("/student-portal/student-profile-report")
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something went wrong'
                    });
                }
            })
    };

    const fetchUserData = async () => {

        //  pushPage('student-profile-report');

        await axios.post(api_url + 'user/get_internships_data', {
            user_id: user_data.id,

        }).then((res) => {
            let data = res.data.internships_data;


            if (data) {
                setinternData(JSON.parse(data));
            }

            setLoading(false)

        })
    }

    useEffect(() => {
        fetchUserData()

    }, [])

    const handleRemove = (index) => {
        const newTable = [...internData]
        newTable.splice(index, 1)
        setinternData(newTable)
    }

    return (
        <>
            {loading ? (
                <div className="loaders"><img src='/images/loader.gif' /></div>
            ) : (
                <Modal
                    title="Internships Records"
                    open={show}
                    onCancel={hide}
                    onOk={() => {
                        handleSubmit();
                        hide();
                    }
                    }
                    okText='Save'
                    width={1200}
                    cancelText='Cancel'
                    okButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                    cancelButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                >
                    <Form
                        name="normal_login"
                        className="my-7 mx-5"
                    >
                        <table className='curricular-data'>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Class</th>
                                    <th>Activity</th>
                                    <th>Type</th>
                                    <th>Your Role</th>
                                    <th>Level</th>
                                    <th>Rank</th>
                                    <th>Remarks</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {internData.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <select value={row.year}
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                name="year"
                                                onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="2015">2015</option>
                                                <option value="2016">2016</option>
                                                <option value="2017">2017</option>
                                                <option value="2018">2018</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.class}
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                name="class"
                                                onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="activity"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.activity}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <select value={row.type} name="type" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="Achievement">Achievement</option>
                                                <option value="Participant">Participant</option>
                                                <option value="Honour">Honour</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.role} name="role" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleChange(e, index)} >
                                                <option value="">Select</option>
                                                <option value="Participant">Participant</option>
                                                <option value="Leader">Leader</option>
                                                <option value="Not Applicable">Not Applicable</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.level} name="level" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleChange(e, index)} >
                                                <option value="">Select</option>
                                                <option value="School">School</option>
                                                <option value="Interschool">Interschool</option>
                                                <option value="District">District</option>
                                                <option value="State">State</option>
                                                <option value="National">National</option>
                                                <option value="International">International</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="rank"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.rank}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="remarks"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.remarks}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <Popconfirm title="Sure to delete?" onConfirm={() => handleRemove(index)} okButtonProps={{ style: { background: '#2b6494' } }}>
                                                <a><DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' /></a>
                                            </Popconfirm>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" className="ant-btn css-dev-only-do-not-override-k83k30 ant-btn-primary text-base text-white bg-[#FCC222] rounded-lg pb-1.5 px-5" onClick={handleAddRow} style={{ float: 'right' }}>
                            Add Row
                        </button>



                    </Form>


                </Modal>
            )}
        </>

    )

}

export default Internships
