import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import axios from 'axios';
import { Button, Col, Row, Layout, Collapse, Radio, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { api_url, reportPath } from '../components/constants';
import DashboardLayout from '../components/dashboard-layout';
const { Content } = Layout;
const { Panel } = Collapse

const SpeedAnalysis = () => {


    const panelStyle = {
        marginBottom: 24,
        border: '1px solid #F5C964',
        background: '#fff',
        borderRadius: 5,
        color: '#fff'
    };

    const panel2Style = {
        //marginBottom: 24,
        border: '1px solid #D9CFCF',
        background: '#F5F5F5',
        borderRadius: 5,
        color: '#fff'
    };

    return (
        <>
            <DashboardLayout>

                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >

                    <Row gutter={30}>

                        <Col md={24} xs={24}>
                            <div className="form-layout form-space" style={{ background: '#F5F5F5' }}>

                                <div className='flex  pbs-session-heading bg-[#114D89] px-5 py-2.5 justify-between '>
                                    <h1 className="text-white text-xl text-center font-light">Speed Reading</h1>
                                    <p className="text-right text-white text-xl font-light">Level-1</p>
                                </div>

                                <div className="bg-[#F5F5F5] p-7">
                                    <Row>
                                        <Col md={24}>
                                            <div className='flex items-center justify-center'>

                                                <div className='p-2.5' style={{ background: '#F0AA0C', borderRadius: 10 }}>
                                                    <div className='flex items-center'>
                                                        <span className="dot"></span>
                                                        <p className='text-[#fff] pl-2.5 text-sm'>
                                                            Round 1
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr style={{ border: 'none', borderBottom: '1px solid #DBDBDB', width: '20%' }} />

                                                <div className='p-2.5' style={{ background: '#fff', borderRadius: 10 }}>
                                                    <div className='flex items-center'>
                                                        <span className="dot"></span>
                                                        <p className='text-[#F0AA0C] pl-2.5 text-sm'>
                                                            Round 2
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr style={{ border: 'none', borderBottom: '1px solid #DBDBDB', width: '20%' }} />

                                                <div className='p-2.5' style={{ background: '#fff', borderRadius: 10 }}>
                                                    <div className='flex items-center'>
                                                        <span className="dot"></span>
                                                        <p className='text-[#F0AA0C] pl-2.5 text-sm'>
                                                            Round 3
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 40 }}>
                                        <Col md={24} className='lg:px-5 px-5 profile'>
                                            <Collapse defaultActiveKey={['1']}
                                                bordered={false}
                                                expandIconPosition='end'
                                                style={{ marginTop: "-1vh", }}
                                                expandIcon={({ isActive }) => (
                                                    <DownOutlined
                                                        style={{ color: '#A2A1A1', paddingTop: 10, }}
                                                        rotate={isActive ? 180 : 0}
                                                    />
                                                )}
                                            >

                                                <Panel header="Round 1 Analysis Report" key="1" style={panelStyle}>

                                                </Panel>

                                                <Panel header="Your Quiz Analysis Report" key="2" style={panelStyle}>
                                                    <div className='px-2.5 py-5' style={{ border: '1px solid #707070', borderRadius: 23 }}>
                                                        <Row>
                                                            <Col md={24} className='lg:px-5 px-5 analysis-que'>
                                                                <Collapse defaultActiveKey={['1']}
                                                                    bordered={false}
                                                                    expandIconPosition='end'
                                                                    style={{ marginTop: "-1vh", }}
                                                                    expandIcon={({ isActive }) => (
                                                                        <DownOutlined
                                                                            style={{ color: '#A2A1A1', paddingTop: 10, }}
                                                                            rotate={isActive ? 180 : 0}
                                                                        />
                                                                    )}
                                                                >

                                                                    <Panel header="Q1 Lorem Ipsum is simply dummy text of the printing and typesetting industry." key="1" style={panel2Style}>
                                                                        <div>
                                                                            <p className='text-[#062646] text-sm py-2.5'>Solutions :-</p>
                                                                            <Radio.Group className='pb-5'>
                                                                                <Space direction="vertical">
                                                                                    <Radio value={1} className='options' style={{ border: 'none' }}>Option A</Radio>
                                                                                    <Radio value={2} className='options' style={{ border: 'none' }}>Option B</Radio>
                                                                                    <Radio value={3} className='options' style={{ border: 'none' }}>Option C</Radio>
                                                                                </Space>
                                                                            </Radio.Group>
                                                                        </div>
                                                                        <div className='flex justify-end'>
                                                                            <div className='ans-box mr-2.5'>
                                                                                <p className='text-white text-sm'>Correct Ans: <span className='text-[#FFB917]'>A</span></p>
                                                                            </div>
                                                                            <div className='ans-box'>
                                                                                <p className='text-white text-sm'>Time period: <span className='text-[#FFB917]'>: 20 Sec</span></p>
                                                                            </div>
                                                                        </div>
                                                                    </Panel>

                                                                    {/* <Panel header="Q2 Lorem Ipsum is simply dummy text of the printing and typesetting industry." key="2" style={panel2Style}>
                                                                        <div>
                                                                            <p className='text-[#062646] text-sm py-2.5'>Solutions :-</p>
                                                                            <Radio.Group className='pb-5'>
                                                                                <Space direction="vertical">
                                                                                    <Radio value={1} className='options' style={{ border: 'none' }}>Option A</Radio>
                                                                                    <Radio value={2} className='options' style={{ border: 'none' }}>Option B</Radio>
                                                                                    <Radio value={3} className='options' style={{ border: 'none' }}>Option C</Radio>
                                                                                </Space>
                                                                            </Radio.Group>
                                                                        </div>
                                                                        <div className='flex justify-end'>
                                                                            <div className='ans-box mr-2.5'>
                                                                                <p className='text-white text-sm'>Correct Ans: <span className='text-[#FFB917]'>A</span></p>
                                                                            </div>
                                                                            <div className='ans-box'>
                                                                                <p className='text-white text-sm'>Time period: <span className='text-[#FFB917]'>: 20 Sec</span></p>
                                                                            </div>
                                                                        </div>
                                                                    </Panel> */}
                                                                </Collapse>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </Panel>

                                            </Collapse>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>


                </Content>


            </DashboardLayout>
        </>
    )
}

export default SpeedAnalysis