import React from "react";
// import { Button, Col, Row, } from 'antd';

const Footer = () => {
    return (
        <div className="footer flex justify-center text-center bg-[#2A6496] p-2.5" style={{ border: '1px solid #707070' }}>
            <h1 className="text-white text-sm font-light">ALLEN Career Institute Pvt. Ltd. © All Rights Reserved.</h1>
        </div>
    )

}

export default Footer