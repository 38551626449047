import CryptoJS from 'crypto-js';


export function decrypt(string, key) {
    let ckey = CryptoJS.enc.Utf8.parse(key);
    let ciphertext = CryptoJS.enc.Hex.parse(string);
    let srcs = CryptoJS.enc.Base64.stringify(ciphertext);
    let decrypt = CryptoJS.AES.decrypt(srcs, ckey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    let decrypted = decrypt.toString(CryptoJS.enc.Utf8);
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function encrypt(string, key) {
    let ckey = CryptoJS.enc.Utf8.parse(key);
    let encrypted = CryptoJS.AES.encrypt(string, ckey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    //return encrypted.toString(); //This method returns the base64 format ciphertext
    return encrypted.ciphertext.toString(); // return ciphertext in hex format
}


export const encriptionKey = '1234567890123456';