import React, { useEffect, useState,useRef } from 'react';
import '../styles/dashboard.css';
import { Col, Row, Layout, Button, Modal,Input,Form,Popover } from 'antd';
import DashboardLayout from '../components/dashboard-layout';
import axios from 'axios';
import { api_url, studentPath,counsellorPath } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from "react-router-dom";
import { encrypt, decrypt, encriptionKey } from '../components/utils';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Content } = Layout;



const Documents = () => {
    const { docid } = useParams();
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState('');
    const [contentData, setContentData] = useState('');
    const [historyContent, setContentHistory] = useState('');
    const [commentContent, setCommentContent] = useState('');
    const [worldLimit, setWordLength] = useState(0);
    
   const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    
    const fetchDocumentData = () => {

        axios.post(api_url + 'application/get_document_content', {
            document_id: docid,
        }).then((res) => {
            
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const content_data = res.data.data;
            
            if(content_data){
                setContentData(content_data);
                setContent(content_data.content);
                const words = content_data.content.trim().split(/\s+/);
                setWordLength(words.length);
                console.log(words.length);
            }

           
            const history = res.data.history;
           
            if(history){
                setContentHistory(history);
            }
           
            
            setLoading(false)

        })
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
       
        fetchDocumentData()
    }, [])

    const onFinish = (values) => {
      
        axios.post(api_url + 'application/update_document_content', {
            document_id: docid,
            created_by: user_data.id,
            usertype : 'Student',
            content: content,
            comments : commentContent,
        })

            .then((response) => {
                
                if (response.data.status) {

                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }
  

    // Handle changes in the editor content
    const handleEditorChange = (value) => {
        setContent(value);
    };

    const handleCommentChange = (e) => {
        setCommentContent(e.target.value);
     };
    
     const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ size: [] }], 
        [{ color: [] }, { background: [] }], 
        ['link', 'image'],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }], 
        
    ];

      const handleSubmit = (event) => {
        console.log(content);
        event.preventDefault();
      };

      const quillRef = useRef(null); // Create a ref for ReactQuill

      const [selectedSentence, setSelectedSentence] = useState('');
      const [selectedStartIndex, setSelectedStartIndex] = useState(null);
      const [gefModalVisible, setGefModalVisible] = useState(false);
      const [commentList, setDocumentCommentData] = useState("");
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [contentId, setContentId] = useState("");

      const handleGetSelectedSentence = () => {
        if (quillRef.current) {
          const quillEditor = quillRef.current.getEditor();
          const selection = quillEditor.getSelection(); // Get the selected range
          
          if (selection) {
            const [startIndex, endIndex] = [selection.index, selection.index + selection.length];
            setSelectedStartIndex(startIndex); // Set the selected start index
            const editorContent = quillEditor.getText();
            const fullText = editorContent.slice(0, endIndex);
      
            // Find the start of the sentence by searching backward for the first period or the beginning of the editor content.
            let sentenceStart = startIndex;
            sentenceStart = sentenceStart !== -1 ? sentenceStart + 1 : 0;
      
            // Find the end of the sentence by searching forward for the next period or the end of the editor content.
            const sentenceEnd = fullText.indexOf('.', endIndex);
            const selectedSentence = fullText.slice(sentenceStart, sentenceEnd !== -1 ? sentenceEnd + 1 : undefined);
      
            setSelectedSentence(selectedSentence.trim());
            
          }
        }
        setGefModalVisible(true)
    };


    const handleChange = (name, value) => {
        setSelectedSentence(value);
    };
    const handleCommentSubmit = (e) => {
        e.preventDefault();
        if (commentContent.trim() !== '') {
            axios.post(api_url + 'application/update_document_comment', {
                content_id : contentId,
                usertype: 'Student',
                created_by : user_data.id,
                comment: commentContent,
            })
                .then(response => {
                    if (response.data.status) {
    
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        const timer = setTimeout(() => {
                            window.location.reload();
                        }, 3000);
    
                    } else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
    
                    }
                })
          } else {
            console.log('Comment is empty');
          }
       
    }

    const handleCommentHistory = (id) => {
        
        axios.post(api_url + 'application/get_document_history', {
            content_id : id,
        })
        .then(response => {
            if(response.data.data){
                setDocumentCommentData(response.data.data);
                setContentId(id);
                setIsModalOpen(true);
            }
            
        })
    }

    return (
        <>
            <DashboardLayout>
                <Content
                    className="site-layout-background lg:p-7 md:p-5"
                    style={{
                        margin: '20px 16px 24px',
                        background: '#fff',
                        width: '100%'
                    }}
                >

                <div className="mb-16 mx-10" >
                        <Row style={{ height: '100%' }}>
                            <Col md={20} style={{ height: '100%' }}>
                                <div className="form-layout bg-[#F6F6F6]" style={{ height: '100%', marginBottom: '30px' }}>
                                    <div className=" bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-base">Document Editor</h1>
                                        <p style={{ float: 'right',marginTop : '-25px' }} className="text-[#fff] ">Word Count : {worldLimit}</p>
                                    </div>
                                    <div className="bg-[#FCFCFC] p-2.5" style={{ height: '100%' }}>
                                        <Form onFinish={onFinish}>
                                            <div>
                                                
                                                <ReactQuill spellCheck={true} ref={quillRef} id="getText" modules={{ toolbar: toolbarOptions }} value={content} onChange={handleEditorChange} style={{ height: '100%' }} />
                                            </div>

                                            <Button style={{ marginTop: '20px' }} htmlType='submit' className='bg-[#0C3762] text-sm text-white rounded-full  flex justify-center items-center px-7 border'>
                                                Save
                                            </Button>
                                        </Form>


                                        <Popover trigger="click" content={<div><h3 className='h3'>Comment Box  </h3>

                                        <Input  style={{ width: '100%', }} name="comment" onChange={(e) => handleCommentChange(e)} />

                                        </div>} placement="right">
                                            <Button className='bg-[#0C3762] text-sm text-white rounded-full flex justify-right items-center px-7 margin-left border'>Comment
                                            </Button>


                                        </Popover>

                                    



                                    </div>
                                </div>

                            
                                {loading ? (
                            <div className="loaders"><img src='/images/loader.gif' /></div>
                        ) : (

                            historyContent.length > 0 ? (historyContent.map((row, index) => (
                                <>
                                <div className="msger-chat" key={index}>
                                    {row.userType !== "Student" ? (
                                    <div className="msg left-msg">
                                        <div className="msg-img">
                                             
                                             {row.image == null ? (
                                                <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                                            ) : (
                                                <img src={counsellorPath + row.image} width="100%" alt="user" />
                                            )}
                                            </div>
                                        <div className="msg-bubble">
                                            <div className="msg-info">
                                                <div className="msg-info-name">{row.created_by}</div>
                                                <div className="msg-info-time">{row.created_date}</div>
                                            </div>

                                            <div className="msg-text1" dangerouslySetInnerHTML={{ __html: row.content }}></div>

                                            {row.comment > 0 ? (<div className='comment__footer '>
                                                    <div className='comment-footer__menu'>
                                                       
                                                        <li className='bullet'>•</li><li  onClick={() => handleCommentHistory(row.id)}  >Comment <span>{row.comment}</span></li>
                                                    </div>
                                                </div>) : (<div className='comment__footer '>
                                                    <div className='comment-footer__menu'>
                                                       <li className='bullet'>•</li><li  onClick={() => handleCommentHistory(row.id)}  >Click Here for Comment <span></span></li>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>
                                    ) : (
                                        <div className="msg right-msg">
                                            <div className="msg-img">
                                            {row.image == null ? (
                                                <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                                            ) : (
                                                <img src={studentPath + row.image} width="100%" alt="user" />
                                            )}
                                                
                                                </div>
                                            <div className="msg-bubble">
                                                <div className="msg-info">
                                                    <div className="msg-info-time">{row.created_date}</div>
                                                    <div className="msg-info-name">{row.created_by}</div>
                                                </div>
                                                <div className="msg-text1" dangerouslySetInnerHTML={{ __html: row.content }}></div>
                                                {row.comment > 0 ? (<div className='comment__footer '>
                                                    <div className='comment-footer__menu'>
                                                       
                                                        <li className='bullet'>•</li><li  onClick={() => handleCommentHistory(row.id)}  >Comment <span>{row.comment}</span></li>
                                                    </div>
                                                </div>) : (<div className='comment__footer '>
                                                    <div className='comment-footer__menu'>
                                                       <li className='bullet'>•</li><li  onClick={() => handleCommentHistory(row.id)}  >Click Here for Comment <span></span></li>
                                                    </div>
                                                </div>)}
                                                

                                            </div>
                                        </div>

                                    )}

                                    

                                </div></>
                                ))) : ('')
                            )}

                                <Modal  className="modalStyle" title="Comment" open={isModalOpen}  onCancel={handleCancel} footer={null}>
                                    <div className="actionBox">
                                        <ul className="commentList">
                                        {commentList.length > 0  && commentList.map((row, index) => (
                                             <li>
                                                <div className="commenterImage">
                                                {row.userType === "Student" ? (
                                                    row.image == null ? (
                                                        <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                                                    ) : (
                                                        <img src={studentPath + row.image} width="100%" alt="user" />
                                                    )) : (row.image == null ? (
                                                        <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                                                    ) : (
                                                        <img src={counsellorPath + row.image} width="100%" alt="user" />
                                                    ))}
                                                    
                                                </div><h3>{row.created_by}</h3>
                                                <div className="commentText">
                                                    <p className="">{row.comment}</p> <span className="date sub-text">{row.created_date}</span>

                                                </div>
                                            </li>
                                        ))}
                                    </ul>

                                    </div>
                                    
                                    <form className="msger-inputarea">
                                        <img className='userimg' src="/images/user.png" alt="user" />
                                        <input onChange={(e) => handleCommentChange(e)} type="text" className="msger-input" placeholder="Enter your message..." required/>
                                        <button  onClick={(e) => handleCommentSubmit(e)}  type="button" className="msger-send-btn"> <img
                                            src="/images/send.png"
                                            width="24px"
                                            alt="button"  />
                                        </button>
                                    </form>
                                </Modal>
                            </Col>
                        </Row>

                      

                    </div>
                    {/* <div className="mb-16 mx-10" style={{ height: '60vh' }}>
                        <Row style={{ height: '100%' }}>
                            <Col md={24} style={{ height: '100%' }}>
                            <div className="form-layout form-space bg-[#F6F6F6]" style={{ height: '100%' }}>
                                <div className=" bg-[#0F4275] py-2.5 px-7">
                                <h1 className="text-[#fff] text-base">Document Editor</h1>
                                </div>
                                <div className="bg-[#F5F5F5] p-2.5" style={{ height: '100%' }}>
                                    
                                <Form onFinish={onFinish}>
                                       
                                        <div>
                                            <label>Content</label>
                                            <ReactQuill modules={{ toolbar: toolbarOptions }} value={content} onChange={handleEditorChange} style={{ height: '100%' }} />
                                        </div>
                                        <Button htmlType='submit' className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>
                                            Save
                                        </Button>
                                </Form>

                                <Button onClick={handleGetSelectedSentence} 
                                        className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>Chat
                                </Button>

                                <Button onClick={handleGetHistory} 
                                        className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>History
                                </Button>
                                
                                
                                </div>
                            </div>
                            </Col>
                        </Row>

                        {loading ? (
                            <div className="loaders"><img src='/images/loader.gif' /></div>
                        ) : (

                            documentData.length > 0 ? (documentData.map((row, index) => (
                                <>
                                    {row.userType === "Student" ? (
                                        <Row className='my-5 mx-7'>
                                            <Col md={8}>
                                                <h3 className='text-[#0C3762] text-base'>{decrypt(row.created_by, encriptionKey)}</h3>
                                                <div className='p-2.5 rounded mb-2.5' style={{ border: '1px solid #C5BEC6' }}>
                                                    <p className='text-base'>{row.message}</p>
                                                    <span className='text-base flex justify-end'>{row.created_at}</span>
                                                </div>
                                               
                                            </Col>
                                        </Row>

                                    ) : (
                                        <Row className='my-5 mx-7' style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Col md={8}>
                                                <h3 className='text-[#0C3762] text-base'>{decrypt(row.created_by, encriptionKey)}</h3>
                                                <div className='p-2.5 rounded bg-[#FDF9EF] mb-2.5' style={{ border: '1px solid #EEE1C0' }}>
                                                    <p className='text-base'>{row.message}</p>
                                                    <span className='text-base flex justify-end'>{row.created_at}</span>
                                                </div>
                                               
                                            </Col>
                                        </Row>

                                    )}

                                </>
                            ))) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)
                        )}

                        <Modal title="Document Chat Box"
                            width={1000}
                            open={gefModalVisible}
                            onOk={() => {
                                handleChatSubmit();
                            }
                            }
                            onCancel={() => setGefModalVisible(false)}
                            okText='Update'
                            okButtonProps={{ style: { background: '#F0AA0C', borderRadius: 20, padding: '5px 40px 35px', fontSize: 18 } }}
                            cancelButtonProps={{ style: { background: '#717070', borderRadius: 20, padding: '5px 40px 35px', fontSize: 18, color: '#fff' } }}
                        >
                            <div className="form-layout">
                                <div className="flex justify-center items-center lg:mx-10 mx-2.5">
                                    <Form
                                        name="normal_login"
                                        className="my-7"

                                    >
                                        <Row>
                                            <Col md={36}>
                                                <div>
                                                    <h2 className="text-lg text-[#0D0C0C] mb-2.5">Query</h2>
                                                </div>
                                            </Col>
                                            <Col md={24} xs={24}>
                                                <Form.Item
                                                    name="suggestion"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={selectedSentence}
                                                >
                                                    <Input.TextArea
                                                        onChange={(e) => handleChange('suggestion', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Modal>
                    </div> */}
                </Content>
            </DashboardLayout>
        </>
    )
}

export default Documents








