import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Dropdown, Button, Space, Layout, Select, Form, Modal, Skeleton } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Search } = Input;
const { Content } = Layout;

const StudentList = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [studentList, setStudentList] = useState([]);


    //console.log(user_data);


    const fetchStudentList = async () => {

        await axios.post(api_url + 'ajax/get_all_student_list', {
            user_id: user_data.id,
        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.users;

            const k = st_list.map((item, index) =>
            ({

                'sno': index + 1,
                'center': item.center,
                'form_number': (item.form_number) ? decrypt(item.form_number, encriptionKey) : '',
                'name': (item.name) ? decrypt(item.name, encriptionKey) : '',
                'email': (item.email) ? decrypt(item.email, encriptionKey) : '',
                'mobile': (item.mobile) ? decrypt(item.mobile, encriptionKey) : '',
                'course': (item.course) ? decrypt(item.course, encriptionKey) : "",
                'class': (item.class) ? decrypt(item.class, encriptionKey) : "",
                'user_id': item.id,
                'pbs_status': item.pbs_status,
            })
            );



            setStudentList(k);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchStudentList()
    }, [])

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const searchItems = (searchValue) => {

        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = studentList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(studentList)
        }
    }


    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
            align: 'center'

        },
        {
            key: "3",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "3",
            title: "Form Number",
            dataIndex: "form_number",
            align: 'center'
        },
        {
            key: "3",
            title: "Name",
            dataIndex: "name",
            align: 'center'
        },


        {
            key: "6",
            title: "Mobile",
            dataIndex: "mobile",
            align: 'center'
        },
        {
            key: "5",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        // {
        //     key: "5",
        //     title: "Class",
        //     dataIndex: "class",
        //     align: 'center'
        // },
        {
            key: "7",
            title: "Actions",
            render: (record) => {
                return (
                    <>
                        <Space>
                            {record.pbs_status & user_data.counsellor_type === 'Mentor Head' ? (<Button onClick={() => { pbsTimeline(record); }} className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }}>PBS View</Button>) : ('')}
                            <Button onClick={() => { studentInfo(record); }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>View Record</Button>
                        </Space>

                    </>
                );
            },
        },
    ];

    const pbsTimeline = (record) => {

        const user_id = encrypt(record.user_id.toString(), encriptionKey);
        navigate("/counsellor-dashboard/pbs-timeline/" + user_id)
    }

    const studentInfo = (record) => {

        navigate("/counsellor-dashboard/student-info/" + record.user_id)
    }




    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mx-0 mt-0">
                            <Row>
                                <Col md={24} xs={24}>
                                    <div className="bg-[#114D89] px-5 py-2.5">
                                        <h1 className="text-white text-2xl ">Student List</h1>
                                    </div>
                                </Col>
                            </Row>

                            <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>

                                <Row>

                                    <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                        <Search onChange={(e) => searchItems(e.target.value)} placeholder="Search...." />
                                    </Col>
                                    <Col md={24} className="responsive-table">
                                        {searchInput.length > 1 ? (
                                            <Table className="record" bordered columns={columns} dataSource={filteredResults} />
                                        ) : (
                                            <Table className="record" bordered columns={columns} dataSource={studentList} />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>


                    </Content>
                )}
            </CounsellorLayout>
        </>
    )
}

export default StudentList