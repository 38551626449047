import React, { useState, useEffect } from "react";
import { Col, Row, Form, Input, Modal, Layout, Progress, Upload, message, } from 'antd';
import '../styles/dashboard.css';
import '../styles/responsive.css';
import axios from 'axios';
import { decrypt, encriptionKey } from '../components/utils';
import Swal from 'sweetalert2';
import DashboardLayout from '../components/dashboard-layout';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url, studentPath } from '../components/constants';
const { Content } = Layout;

const GefSummary = () => {
    const [gefModalVisible, setGefModalVisible] = useState(false);
    const login_user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [studentData, setStudentData] = useState(
        {
            name: '', email: '', form_number: '', city: '', school: '', father_name: '', mother_name: '', parent_mobile: '', mother_occupation: '', father_occupation: '', grade: '',
            grade_8: '', grade_9: '', grade_10: '', grade_11: '', grade_12: '', sat: '', act: '', toefl: '', ielts: '', sat_ap: '', scholarships: '', hearAbout: '', allen_number: '', mobile: '', address: '',
            university: '', intended: '',
        }
    )
    const [form] = Form.useForm();
    const [imageLoading, setImageLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState("");


    const fetchGEFData = async () => {

        await axios.post(api_url + 'get_gef_form_data', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }

            const user_info = [res.data.data];

            const stArray = user_info.map(item => ({
                'user_id': item.user_id,
                'name': decrypt(item.name, encriptionKey),
                'email': decrypt(item.email, encriptionKey),
                'form_number': decrypt(item.form_number, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'mother_name': decrypt(item.mother_name, encriptionKey),
                'father_name': decrypt(item.father_name, encriptionKey),
                'dob': decrypt(item.dob, encriptionKey),
                'city': decrypt(item.city, encriptionKey),
                'psychrometric_report': item.psychrometric_report,
                'batch': item.batch,
                'father_occupation': item.father_occupation,
                'mother_occupation': item.mother_occupation,
                'diagnostic_report': item.diagnostic_report,
                'admission_date': item.admission_date,
                'address': item.address,
                'class': decrypt(item.class, encriptionKey),
                'course_name': decrypt(item.course, encriptionKey),
                'school': item.school,
                'photo': item.photo,
                'allen_number': item.allen_number,
                'grade_8': item.grade_8,
                'grade_9': item.grade_9,
                'grade_10': item.grade_10,
                'grade_11': item.grade_11,
                'grade_12': item.grade_12,
                'sat': item.sat,
                'act': item.act,
                'ielts': item.ielts,
                'toefl': item.toefl,
                'sat_ap': item.sat_ap,
                'scholarships': item.scholarships,
                'hearAbout': item.hearAbout,
                'university': item.university,
                'intended': item.intended,
                'parent_mobile': item.parent_mobile,
            }));
            if(stArray[0].photo != null){
                setImageUrl(studentPath + stArray[0].photo)
            }
            
            setStudentData(stArray[0]);
            setLoading(false)

        })
    }


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };
    const handleChangeImage = (e) => {
        //console.log(e.target.files[0]);return false;
        // if (info.file.status === 'uploading') {
        //     setImageLoading(true);
        //     return;
        // }
        const formData = new FormData();
        formData.append('image', e.target.files[0]);
        formData.append('user_id', user_data.id);

        axios.post(api_url + 'user/upload_image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {

            const url = studentPath + response.data.image;
            setImageUrl(url);

            form.resetFields();
        }).catch(error => {
            console.error(error);
        });


    };



    const handleChange = (name, value) => {
        setStudentData({ ...studentData, [name]: value });
    };

    const handleSubmit = () => {
        //console.log(studentData);
        axios.post(api_url + 'user/update_gef_records', {
            user_id: user_data.id,
            type: 'GEF',
            records: studentData,
        })
            .then(result => {
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => {
                        setGefModalVisible(false)
                        navigate("/student-dashboard/gef")

                    }, 3000);

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something went wrong'
                    });
                }
            })
    }

    useEffect(() => {
        fetchGEFData()

    }, [])

    return (
        <>
            <ToastContainer />
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background lg:p-5 md:p-5"
                        style={{
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <Row gutter={30}>

                            <Col md={24} className="profile-page">
                                {loading ? (
                                    <div className="loaders"><img src='/images/loader.gif' /></div>
                                ) : (
                                    <div className="form-layout form-space">
                                        <div className="flex justify-between bg-[#0F4275] py-2.5 px-7">
                                            <h1 className="text-[#fff] text-2xl uppercase text-center">GENERAL ENQUIRY FORM (GEF)</h1>
                                            <a className="text-right text-white text-xl" onClick={() => setGefModalVisible(true)}>Edit</a>
                                            <Modal title="GENERAL ENQUIRY FORM (GEF)"
                                                width={1000}
                                                open={gefModalVisible}
                                                onOk={() => {
                                                    handleSubmit();
                                                }
                                                }
                                                onCancel={() => setGefModalVisible(false)}
                                                okText='Update'
                                                okButtonProps={{ style: { background: '#F0AA0C', borderRadius: 20, padding: '5px 40px 35px', fontSize: 18 } }}
                                                cancelButtonProps={{ style: { background: '#717070', borderRadius: 20, padding: '5px 40px 35px', fontSize: 18, color: '#fff' } }}
                                            >
                                                <div className="form-layout">
                                                    <div className="flex justify-center items-center lg:mx-10 mx-2.5">
                                                        <Form
                                                            name="normal_login"
                                                            className="my-7"
                                                        //form={form}
                                                        //onSubmit={handleValue}

                                                        >
                                                            <Row gutter={30}>
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="form_number"
                                                                        label="Enquiry Form Number"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.form_number}
                                                                    >
                                                                        <Input disabled={true}
                                                                            onChange={(e) => handleChange('form_number', e.target.value)}

                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="name"
                                                                        label="Name of Student"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.name}
                                                                    >
                                                                        <Input
                                                                            type="name"
                                                                            onChange={(e) => handleChange('name', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                {/* </Row>
                                                    <Row> */}
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="allen_number"
                                                                        label="Allen Form Number"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.allen_number}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('allen_number', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={30}>
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="grade"
                                                                        label="Current Grade"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.class}
                                                                    >
                                                                        <Input
                                                                            disabled={true}
                                                                            onChange={(e) => handleChange('grade', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="school"
                                                                        label="Current School"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.school}
                                                                    >
                                                                        <Input
                                                                            type="name"
                                                                            onChange={(e) => handleChange('school', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                {/* </Row>
                                                    <Row gutter={30}> */}
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="city"
                                                                        label="City of Residence"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.city}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('city', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="mobile"
                                                                        label="Student's Mobile Number"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.mobile}
                                                                    >
                                                                        <Input
                                                                            type="name"
                                                                            onChange={(e) => handleChange('mobile', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                {/* </Row>
                                                    <Row gutter={30}> */}
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="father_name"
                                                                        label="Father's Name"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.father_name}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('father_name', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="mother-name"
                                                                        label="Mother's Name"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.mother_name}
                                                                    >
                                                                        <Input type="name"
                                                                            onChange={(e) => handleChange('father_name', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                {/* </Row>
                                                    <Row gutter={30}> */}
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="email"
                                                                        label="Student's Email Address"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.email}
                                                                    >
                                                                        <Input type='email' disabled={true}
                                                                            onChange={(e) => handleChange('email', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="parent_mobile"
                                                                        label="Parent's Mobile Number"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.parent_mobile}
                                                                    >
                                                                        <Input
                                                                            type="name"
                                                                            onChange={(e) => handleChange('parent_mobile', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                {/* </Row>
                                                    <Row gutter={30}> */}
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="father_occupation"
                                                                        label="Father's Occupation"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.father_occupation}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('father_occupation', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="mother_occupation"
                                                                        label="Mother's Occupation"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.mother_occupation}
                                                                    >
                                                                        <Input
                                                                            type="name"
                                                                            onChange={(e) => handleChange('mother_occupation', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                {/* </Row>
                                                    <Row gutter={30}> */}
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="address"
                                                                        label="Complete Address"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.address}
                                                                    >
                                                                        <Input.TextArea type='address'
                                                                            onChange={(e) => handleChange('address', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={8} xs={24}>
                                                                    <Form.Item
                                                                        name="hearAbout"
                                                                        label="Where/How/When did you hear about AGSD ?"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.hearAbout}
                                                                    >
                                                                        <Input.TextArea
                                                                            //value={hear}
                                                                            type="name"
                                                                            onChange={(e) => handleChange('hearAbout', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={30}>
                                                                <Col md={12} xs={24}>
                                                                    <Form.Item
                                                                        name="university"
                                                                        label="Target Universities"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.university}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('university', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col md={12} xs={24}>
                                                                    <Form.Item
                                                                        name="intended"
                                                                        label="Intended Major (s)"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.intended}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('intended', e.target.value)}
                                                                        />
                                                                    </Form.Item>

                                                                </Col>
                                                            </Row>
                                                            <Row gutter={30}>
                                                                <Col md={24}>
                                                                    <div>
                                                                        <h2 className="text-lg text-[#0D0C0C]">Marks Secured (% / GPA)</h2>
                                                                    </div>
                                                                </Col>

                                                                <Col md={4} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_9"
                                                                        label="Grade 9"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_9}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_9', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={4} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_10"
                                                                        label="Grade 10"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_10}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_10', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={4} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_11"
                                                                        label="Grade 11"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_11}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_11', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={4} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_-12"
                                                                        label="Grade 12"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_12}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_12', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={30}>
                                                                <Col md={24}>
                                                                    <div>
                                                                        <h2 className="text-lg text-[#0D0C0C]">Have you taken any of these tests, if yes, mention your scores below :</h2>
                                                                    </div>
                                                                </Col>

                                                                <Col md={4} xs={12}>

                                                                    <Form.Item
                                                                        name="sat"
                                                                        label="SAT"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.sat}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('sat', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={4} xs={12}>
                                                                    <Form.Item
                                                                        name="act"
                                                                        label="ACT"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.act}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('act', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={4} xs={12}>
                                                                    <Form.Item
                                                                        name="toefl"
                                                                        label="TOEFL"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.toefl}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('toefl', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={4} xs={12}>
                                                                    <Form.Item
                                                                        name="ielts"
                                                                        label="IELTS"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.ielts}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('ielts', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={4} xs={12}>
                                                                    <Form.Item
                                                                        name="sat_ap"
                                                                        label="Subject SAT/AP"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.sat_ap}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('sat_ap', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={24}>
                                                                    <div>
                                                                        <h2 className="text-lg text-[#0D0C0C] mb-2.5">Will you be able to afford your studies abroad if scholarships are not provided ?</h2>
                                                                    </div>
                                                                </Col>
                                                                <Col md={24} xs={24}>
                                                                    <Form.Item
                                                                        name="scholarships"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.scholarships}
                                                                    >
                                                                        <Input.TextArea
                                                                            onChange={(e) => handleChange('scholarships', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </div>
                                        <div className="mx-2.5 my-3">
                                            {/* <Row gutter={30}>
                                                <Col md={6}>
                                                    <div className="student-img">
                                                        {studentData.photo == null ? (
                                                            <img  src='/images/avatar.png' className="profile-pic flex justify-start items-center" alt="user avatar" />
                                                        ) : (
                                                            <img src={studentPath + studentData.photo} className="profile-pic flex justify-start items-center" alt="user avatar" />
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col md={24} lg={24} xl={18}>
                                                    <Row gutter={30}>
                                                        {studentData.form_number && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Form Number:</strong> {studentData.form_number}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.admission_date && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Admission Date :</strong> {studentData.admission_date}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.city && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Center Name:</strong> {studentData.city}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.name && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Name:</strong> {studentData.name}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.mobile && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Mobile :</strong> {studentData.mobile}
                                                                </p>
                                                            </Col>
                                                        )}


                                                        {studentData.email && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Email :</strong> {studentData.email}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.dob && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>DOB :</strong> {studentData.dob}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.course && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Course :</strong> {studentData.course}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.class && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Class :</strong> {studentData.class}
                                                                </p>
                                                            </Col>
                                                        )}


                                                        {studentData.father_name && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Father's Name :</strong> {studentData.father_name}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.mother_name && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Mother's Name :</strong> {studentData.mother_name}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.father_occupation && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Father's Occupation :</strong> {studentData.father_occupation}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.mother_occupation && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Mother's Occupation :</strong> {studentData.mother_occupation}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.address && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong> Address :</strong> {studentData.address}
                                                                </p>
                                                            </Col>
                                                        )}


                                                        {studentData.grade_9 && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Grade 9 :</strong> {studentData.grade_9}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.grade_10 && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Grade 10 :</strong> {studentData.grade_10}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.grade_11 && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Grade 11 :</strong> {studentData.grade_11}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.grade_12 && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Grade 12 :</strong> {studentData.grade_12}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.university && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Target Universities :</strong> {studentData.university}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.intended && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Intended Major (s) :</strong> {studentData.intended}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.sat && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>SAT Score :</strong> {studentData.sat}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.act && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>ACT Score :</strong> {studentData.act}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.toefl && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>TOEFL Score :</strong> {studentData.toefl}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.ielts && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>IELTS Score :</strong> {studentData.ielts}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.sat_ap && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>SAT/AP Score :</strong> {studentData.sat_ap}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.hearAbout && (
                                                            <Col md={8}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Where/How/When did you hear about AGSD ? :</strong> {studentData.hearAbout}
                                                                </p>
                                                            </Col>
                                                        )}

                                                        {studentData.scholarships && (
                                                            <Col md={24}>
                                                                <p className="mb-5 text-base text-[#6c757d]">
                                                                    <strong>Will you be able to afford your studies abroad if scholarships are not provided ? </strong> 
                                                                </p>
                                                                <p>{studentData.scholarships}</p>
                                                            </Col>
                                                        )}

                                                    </Row>
                                                </Col>
                                            </Row> */}

                                            <div className="rounded-lg bg-white gef-profile-block" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                <Row>
                                                    <Col md={6} style={{ borderRight: '1px solid #e7e7e7', display: 'flex', alignItems: 'center' }}>
                                                        <div className="profile-pic p-2.5" >
                                                            {imageUrl ? (<img src={imageUrl} alt='profile-img' />) : (<img src="/images/avatar.png" alt='profile-img' />)}
                                                            
                                                            <h2 className="text-[#114D89] capitalize text-base">{studentData.name}</h2>
                                                            {/* <span className="text-[#114D89] text-base">Your Profile</span>
                                                            <Progress percent={50} className="gef" /> */}
                                                            {/* <div className="upload-icon">
                                                                <img src="/images/upload-icon.png" />
                                                            </div> */}
                                                            <div className="upload-icon">
                                                                <label htmlFor="file-upload" className="upload-label">
                                                                    <img src="/images/upload-icon.png" />
                                                                </label>
                                                                <input id="file-upload" name="file" type="file" accept="image/*" onChange={handleChangeImage}  style={{ display: 'none' }} />
                                                            </div>
                                                        </div>

                                                    </Col>
                                                    <Col md={18}>
                                                        <Row>
                                                            {studentData.form_number && (
                                                                <Col xl={10} lg={12} md={12} >
                                                                    <div className="gef-data">
                                                                        <p>Form Number</p>
                                                                        <span>{studentData.form_number}</span>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            {studentData.class && (
                                                                <Col xl={7} lg={12} md={12}>
                                                                    <div className="gef-data">
                                                                        <p>Class</p>
                                                                        <span>{studentData.class}</span>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            {studentData.dob && (
                                                                <Col xl={7} lg={12} md={12}>
                                                                    <div className="gef-data">
                                                                        <p>DOB</p>
                                                                        <span>{studentData.dob}</span>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            {studentData.mobile && (
                                                                <Col xl={12} lg={12} md={12}>
                                                                    <div className="gef-data">
                                                                        <p>Mobile</p>
                                                                        <span>{studentData.mobile}</span>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            {studentData.course && (
                                                                <Col xl={12} lg={12} md={12}>
                                                                    <div className="gef-data">
                                                                        <p>Course Name</p>
                                                                        <span>{studentData.course}</span>
                                                                    </div>
                                                                </Col>
                                                            )}


                                                            <Col xl={12} lg={12} md={12}>
                                                                <div className="gef-data">
                                                                    <p>Grade 9</p>
                                                                    <span>88%</span>
                                                                </div>
                                                            </Col>


                                                            {studentData.father_name && (
                                                                <Col xl={12} lg={12} md={12}>
                                                                    <div className="gef-data">
                                                                        <p>Father's Name</p>
                                                                        <span>{studentData.father_name}</span>
                                                                    </div>
                                                                </Col>
                                                            )}


                                                            {studentData.mother_name && (
                                                                <Col xl={12} lg={12} md={12}>
                                                                    <div className="gef-data">
                                                                        <p>Mother's Name</p>
                                                                        <span>{studentData.mother_name}</span>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            {studentData.email && (
                                                                <Col xl={24} md={12}>
                                                                    <div className="gef-data">
                                                                        <p>Email</p>
                                                                        <span>{studentData.email}</span>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            {studentData.father_occupation && (
                                                                <Col xl={12} lg={12} md={12}>
                                                                    <div className="gef-data">
                                                                        <p>Father's Occupation</p>
                                                                        <span>{studentData.father_occupation}</span>
                                                                    </div>
                                                                </Col>

                                                            )}

                                                            {studentData.mother_occupation && (
                                                                <Col xl={12} lg={12} md={12}>
                                                                    <div className="gef-data">
                                                                        <p>Mother's Occupation</p>
                                                                        <span>{studentData.mother_occupation}</span>
                                                                    </div>
                                                                </Col>
                                                            )}
                                                            {studentData.intended && (
                                                                <Col xl={24} md={24}>
                                                                    <div className="gef-data">
                                                                        <p>Intended Major (s)</p>
                                                                        <span>{studentData.intended}</span>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            {studentData.address && (
                                                                <Col xl={24} md={24}>
                                                                    <div className="gef-data">
                                                                        <p>Address</p>
                                                                        <span>{studentData.address}</span>
                                                                    </div>
                                                                </Col>
                                                            )}

                                                            {studentData.university && (
                                                                <Col xl={24} md={24}>
                                                                    <div className="gef-data">
                                                                        <p>Target Universities</p>
                                                                        <span>{studentData.university}</span>
                                                                    </div>
                                                                </Col>
                                                            )}



                                                            {studentData.scholarships && (
                                                                <Col xl={24} md={24}>
                                                                    <div className="gef-data">
                                                                        <p>Will you be able to afford your studies abroad if scholarships are not provided ?</p>
                                                                        <span>{studentData.scholarships}</span>
                                                                    </div>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </Col>
                        </Row>
                    </Content>
                )
                }

            </DashboardLayout>
        </>
    )
}

export default GefSummary