import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Layout, Modal, Select, Radio,Space } from 'antd';
import '../styles/dashboard.css';
import '../styles/responsive.css';
import DashboardLayout from '../components/dashboard-layout';
import { ClockCircleOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { api_url } from '../components/constants';
import { useNavigate } from "react-router-dom";
const { Content } = Layout;


const Dashboard = () => {
    let now = new Date().toLocaleDateString('en-us', { weekday: "long", month: "long", day: "numeric" });
    let date = new Date().toLocaleDateString('en-us', { day: "numeric" });
    let day = new Date().toLocaleDateString('en-us', { weekday: "long" });
    let month = new Date().toLocaleDateString('en-us', { month: "long" });
    let year = new Date().getFullYear();

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [userSlotloading, setUserSlotLoading] = useState(true);
    const navigate = useNavigate();
    const [pbsEvent, setPBSEvent] = useState({ date: "", time: "", counsellor: "", status: "", id: "", link: "" });
    const [calandermodalVisible, setCalanderMoadalVisible] = useState(false);
    const [classModalVisible, setClassModalVisible] = useState(false);
    //const [timeSlot, settimeSlot] = useState({ label: '', value: '' });
    const [timeSlot, settimeSlot] = useState({});
    const [slotSet, setSlot] = useState(true);
    const [events, setEvents] = useState([
        { id: "", date: "", time: "", status: "", event: "", user: "", user_id: "" },
    ]);
    const [formData, setFormData] = useState({});

    const [timeSlots, setTimeSlots] = useState([]);

    const [form] = Form.useForm();

    const [selectedBatchIds, setSelectedBatchIds] = useState({});

    // const dateCellRender = (date) => {
    //     const formattedDate = date.format('YYYY-MM-DD');
    //     const event = events.find((e) => e.date === formattedDate);

    //     if (event) {
    //         let options = [];
    //         events.map((element, index) => {

    //             if (formattedDate === element.date) {
    //                 options.push({
    //                     id: element.id,
    //                     date: element.date,
    //                     event: "PBS Session - " + element.status,
    //                 });
    //             }
    //         })
    //         return (
    //             <ul className="events">
    //                 {options.map((item) => (
    //                     <li key={item.id}>
    //                         {item.event}
    //                     </li>
    //                 ))}
    //             </ul>
    //         );
    //     }
    //     return null;
    // };
    // const onSelect = (date) => {
    //     const formattedDate = date.format('YYYY-MM-DD');

    //     axios.post(api_url + 'get_student_pbs_request', {
    //         user_id: user_data.id,
    //         event_date: formattedDate,

    //     }).then((res) => {

    //         if (res.data.status) {
    //             let pbs_cal_req = JSON.parse(res.data.data);
    //             setPBSEvent(pbs_cal_req);
    //             setLoading(false)
    //             setCalanderMoadalVisible(true);
    //         }
    //         // else{
    //         //     toast.error("Event not found !", {
    //         //         position: toast.POSITION.TOP_CENTER
    //         //     });
    //         // }



    //     })
    // };

    const fetchPBSData = async () => {
        await axios.post(api_url + 'user/get_event_data', {
            user_id: user_data.id,
            type: 'Student'

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const pbs_data = res.data.data;

            setEvents(pbs_data);
            setLoading(false)

        })
    }
    const fetchUserSlot = async () => {
        await axios.post(api_url + 'user/get_my_service', {
            user_id: user_data.id,

        }).then((res) => {
            // console.log(res);return false;
            // if (!res.data.status) {

            //     if (res.data.access_token == null) {
            //         localStorage.removeItem('user')
            //         window.location.href = '/';
            //     }
            // }
            if (res.data.time_slot) {


                const time_slot = res.data.time_slot;
                //console.log(res.data.time_slot)
                // setTimeSlots(res.data.time_slot[''] || {});
                // let options = [];
                // time_slot.map((element, index) => {
                //     options.push({
                //         label: element.time_slot,
                //         value: element.id
                //     });
                // })

                if (res.data.count > 0) {
                    if (Array.isArray(res.data.batch_count) && res.data.batch_count.length > 0) {
                        setClassModalVisible(false);
                    } else {
                        setClassModalVisible(true);
                    }
                    
                    settimeSlot(time_slot);
                    setUserSlotLoading(true);
                } else {
                    
                    setUserSlotLoading(false)
                }
            } else {
                setUserSlotLoading(false)
                setSlot(false);
            }

        })
    }
    useEffect(() => {
        
        fetchUserSlot()
        fetchPBSData()

    }, [])


    const onFinish = (values) => {
        //console.log('Form values:', formData);return false;
        axios.post(api_url + 'submit_user_slot', {
            batch_id: Object.values(selectedBatchIds),
            user_id: user_data.id,
        })
            .then(result => {
                
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        setClassModalVisible(false);
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })

    };

    const setEditHandle = (value) => {

        if (value === "Live Class") {
            navigate("/student-dashboard/my-classes-new");
        } else {
            navigate("/student-dashboard/pbs-session");
        }

    };

    // Utility function to format date (e.g., "2022-06-30" to "June 30, 2022")
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    };

    // Utility function to format time (e.g., "09:00:00" to "9:00 AM")
    const formatTime = (timeString) => {
        const time = new Date('1970-01-01T' + timeString);
        return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };


    // const handleSelectChange = (e, key) => {
    //     const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);

    //     setFormData(prevState => ({
    //         ...prevState,
    //         [key]: selectedOptions
    //     }));
    // };


    const handleBatchChange = (service, phase, batchId) => {
        setSelectedBatchIds(prevState => ({
            ...prevState,
            [service]: batchId
        }));
    };

    const handleReset = () => {
        setSelectedBatchIds({});
    };

    // const onFinish = () => {
    //     console.log('Selected batch IDs:', Object.values(selectedBatchIds));
    // };



    return (
        <>

            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        {/* {loading ? (
                            <div className="loaders"><div class="hourglass"></div></div>
                        ) : ( */}
                        <Row gutter={30}>

                            <Col md={24} xs={24}>
                                <Row>
                                    <Col md={24} xs={24}>
                                        <div className="flex justify-between deshboard-top">
                                            <div className="flex py-2.5 px-5" style={{ boxShadow: '0px 1px 8px #00000029', borderRadius: 10 }}>
                                                <img src='/images/calendar-icon.png' alt='calendar-icon' />
                                                {/* <h1 className="text-[#8A8A8A] text-base pl-5">{ date +'-'+month+'-'+year }</h1> */}
                                                <h1 className="text-[#8A8A8A] text-base pl-5">All Notifications</h1>
                                            </div>
                                            {userSlotloading && (
                                                <div className="btn-outer">
                                                    <Button type="primary"
                                                        className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                        style={{ height: 'auto', borderRadius: 20, float: 'right' }}
                                                        onClick={() => setClassModalVisible(true)}  > Book Class Slot</Button>
                                                </div>
                                            )}
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        {classModalVisible && (
                                            <Modal title="Select your Class Slot"
                                                open={classModalVisible}
                                                onOk={() => setClassModalVisible(false)}
                                                onCancel={() => setClassModalVisible(false)}
                                                footer={null}
                                                maskClosable={false}
                                                width={900}
                                            >


                                                <div>
                                                    {loading ? (
                                                        <p>Loading...</p>
                                                    ) : (
                                                        <Form form={form} onFinish={onFinish}>
                                                            <table className="class-slot responsive-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Service</th>
                                                                        <th>Phase</th>
                                                                        <th>Batch</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(timeSlot).map(service => (
                                                                        Object.keys(timeSlot[service]).map((phase, phaseIndex) => (
                                                                            timeSlot[service][phase].map((batch, batchIndex) => (
                                                                                <tr key={`${service}_${phase}_${batch.batch_id}`}>
                                                                                    {phaseIndex === 0 && batchIndex === 0 && (
                                                                                        <td align='center' rowSpan={Object.values(timeSlot[service]).flat().length}>{service}</td>
                                                                                    )}
                                                                                    {batchIndex === 0 && (
                                                                                        <td align='center' rowSpan={timeSlot[service][phase].length}>
                                                                                            {phase} <br/>
                                                                                            {/* {formatDate(batch.start_date) + ' - ' + formatDate(batch.end_date)} */}
                                                                                        </td>
                                                                                    )}
                                                                                    <td>
                                                                                        <Form.Item
                                                                                            key={batch.batch_id}
                                                                                            name={service}
                                                                                        >
                                                                                            <input
                                                                                                type="radio"
                                                                                                name={service}
                                                                                                id={`${service}_${phase}_${batch.batch_id}`}
                                                                                                value={batch.batch_id}
                                                                                                className="checkbox-radio"
                                                                                                onChange={() => handleBatchChange(service, phase, batch.batch_id)}
                                                                                                checked={selectedBatchIds[service] === batch.batch_id}
                                                                                            />
                                                                                            <label htmlFor={`${service}_${phase}_${batch.batch_id}`}></label>
                                                                                            {formatTime(batch.start_time) + ' - ' + formatTime(batch.end_time)} {batch.days}
                                                                                        </Form.Item>
                                                                                    </td>
                                                                                </tr>

                                                                            ))
                                                                        ))
                                                                    ))}
                                                                </tbody>
                                                            </table>

                                                            <Space className="flex justify-center items-center">
                                                                <Form.Item >
                                                                    <Button type="primary" htmlType="submit" className="bg-[#F8B133] ">
                                                                        Submit
                                                                    </Button>
                                                                </Form.Item>
                                                                <Form.Item >
                                                                    <Button type="primary" onClick={handleReset} className="bg-[#F8B133]">
                                                                        Reset
                                                                    </Button>
                                                                </Form.Item>
                                                            </Space>
                                                        </Form>
                                                    )}
                                                </div>

                                                {/* <Form>

                                                    <table className="class-slot responsive-table">
                                                        <tr>
                                                            <th></th>
                                                            <th>April</th>
                                                            <th>May</th>
                                                            <th>June</th>
                                                        </tr>
                                                        <tr>
                                                            <td rowspan="3">SAT</td>
                                                            <td><input type="radio" name="sat_april" id="sat_april_morning" value="morning" class="checkbox-radio" /><label for="sat_april_morning" ></label>SLOW BATCH: 08:30 PM - 10:00 PM (FRI,SAT)</td>
                                                            <td><input type="radio" name="sat_may" id="sat_may_morning" value="morning" class="checkbox-radio" /><label for="sat_may_morning" ></label> SLOW BATCH: 08:30 PM - 10:00 PM (FRI,SAT)</td>
                                                            <td><input type="radio" name="sat_june" id="sat_june_morning" value="morning" class="checkbox-radio" /><label for="sat_june_morning" ></label> SLOW BATCH: 08:30 PM - 10:00 PM (FRI,SAT)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="radio" name="sat_april" id="sat_april_afternoon" value="afternoon" class="checkbox-radio" /><label for="sat_april_afternoon"></label> Afternoon</td>
                                                            <td><input type="radio" name="sat_may" id="sat_may_afternoon" value="afternoon" class="checkbox-radio" /><label for="sat_may_afternoon"></label> Afternoon</td>
                                                            <td><input type="radio" name="sat_june" id="sat_june_afternoon" value="afternoon" class="checkbox-radio" /><label for="sat_june_afternoon"></label> Afternoon</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="radio" name="sat_april" id="sat_april_evening" value="evening" class="checkbox-radio" /><label for="sat_april_evening"></label> Evening</td>
                                                            <td><input type="radio" name="sat_may" id="sat_may_evening" value="evening" class="checkbox-radio" /><label for="sat_may_evening"></label> Evening</td>
                                                            <td><input type="radio" name="sat_june" id="sat_june_evening" value="evening" class="checkbox-radio" /><label for="sat_june_evening"></label> Evening</td>
                                                        </tr>
                                                        <tr>
                                                            <td rowspan="3">TOEFL</td>
                                                            <td><input type="radio" name="toefl_april" id="toefl_april_morning" value="morning" class="checkbox-radio" /><label for="toefl_april_morning"></label> Morning</td>
                                                            <td><input type="radio" name="toefl_may" id="toefl_may_morning" value="morning" class="checkbox-radio" /><label for="toefl_may_morning"></label> Morning</td>
                                                            <td><input type="radio" name="toefl_june" id="toefl_june_morning" value="morning" class="checkbox-radio" /><label for="toefl_june_morning"></label> Morning</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="radio" name="toefl_april" id="toefl_april_afternoon" value="afternoon" class="checkbox-radio" /><label for="toefl_april_afternoon"></label> Afternoon</td>
                                                            <td><input type="radio" name="toefl_may" id="toefl_may_afternoon" value="afternoon" class="checkbox-radio" /><label for="toefl_may_afternoon"></label> Afternoon</td>
                                                            <td><input type="radio" name="toefl_june" id="toefl_june_afternoon" value="afternoon" class="checkbox-radio" /><label for="toefl_june_afternoon"></label> Afternoon</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="radio" name="toefl_april" id="toefl_april_evening" value="evening" class="checkbox-radio" /><label for="toefl_april_evening"></label> Evening</td>
                                                            <td><input type="radio" name="toefl_may" id="toefl_may_evening" value="evening" class="checkbox-radio" /><label for="toefl_may_evening"></label> Evening</td>
                                                            <td><input type="radio" name="toefl_june" id="toefl_june_evening" value="evening" class="checkbox-radio" /><label for="toefl_june_evening"></label> Evening</td>
                                                        </tr>
                                                    </table>

                                                </Form> */}

                                            </Modal>
                                        )}
                                    </Col>
                                </Row>

                                <div className="bg-[#F6F6F6] mt-7 px-7 py-7">
                                    {events.length === 0 ? (
                                        <div className="flex justify-center items-center">
                                            <div className="flex py-5 px-32 my-2.5 bg-white" style={{ borderRadius: 25 }}>
                                                <span className="text-[#F8A30C] text-xl text-center">No notification found.</span>
                                            </div>
                                        </div>
                                    ) : (
                                        events.map((value, k) => (
                                            <a onClick={() => setEditHandle(value.event)} className="xl:flex justify-between p-5 my-2.5 sm:pb-7 bg-white" style={{ borderRadius: 25 }}>
                                                <div className="md:flex">
                                                    <div className="bg-[#FFEFCC] p-2.5" style={{ borderRadius: 2, display: 'flex', flexShrink: 0 }}>
                                                        <img src='/images/counselling.png' />
                                                    </div>
                                                    <div className="pl-2.5">
                                                        {value.event === "Live Class" ? (
                                                            <h3 className="text-[#8A8A8A] text-base">Live Classes</h3>
                                                        ) : (
                                                            <h3 className="text-[#8A8A8A] text-base">PBS Session</h3>
                                                        )}

                                                        {value.event === "Live Class" ? (
                                                            <span className="text-[#F8A30C] text-sm">Your Class Schedule on {value.date}</span>
                                                        ) : (
                                                            <span className="text-[#F8A30C] text-sm">Your PBS Session Schedule on {value.date}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                {value.event != "Live Class" ? (
                                                    <h4 className="text-[#0F4275] text-sm flex items-center ml-0 lg:ml-14 xl:ml-0 sm:float-right"><ClockCircleOutlined className="pr-2.5" />Time : {value.time}</h4>
                                                ) : ('')}
                                            </a>
                                        ))
                                    )}
                                </div>
                            </Col>
                        </Row>
                        {/* )} */}
                    </Content>
                )}
            </DashboardLayout>

        </>
    )
}

export default Dashboard