import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Dropdown, Calendar, Modal, Form, Col, Row, Select, Space, DatePicker, Input, Drawer, Avatar, Badge, Affix } from 'antd';
import {
    DownOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ExclamationCircleOutlined,
    BellOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import Footer from '../components/footer';
import { Link, useNavigate } from 'react-router-dom';
import { setAuthToken } from '../helpers/setAuthToken';
import { encriptionKey, encrypt, decrypt } from "../components/utils";
import { api_url, base_url, counsellorPath } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/responsive.css';
const { Header, Sider, Content } = Layout;

const CounsellorLayout = ({ children }) => {
    let now = new Date().toLocaleDateString('en-us', { weekday: "long", month: "long", day: "numeric" });
    let date = new Date().toLocaleDateString('en-us', { day: "numeric" });
    let day = new Date().toLocaleDateString('en-us', { weekday: "long" });
    let month = new Date().toLocaleDateString('en-us', { month: "long" });
    let year = new Date().getFullYear();
    const path = window.location.pathname;
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [selectedMenuTitle, setSelectedMenuTitle] = useState(null);
    const [pbsMoadalVisible, setPbsMoadalVisible] = useState(false);
    const [tymodalVisible, setTyModalVisible] = useState(false);
    const [PassModalvisible, setPassModalvisible] = useState(false);
    const [pbsDate, setPbsDate] = useState('');
    const [pbsTime, setPbsTime] = useState('');
    const [timeSlot, settimeSlot] = useState({ label: '', value: '' });
    const [events, setEvents] = useState([{ id: "", date: "", time: "", status: "" },]);
    const [pbsEvent, setPBSEvent] = useState({ date: "", time: "", counsellor: "", status: "", id: "", link: "" });
    const [calandermodalVisible, setCalanderMoadalVisible] = useState(false);
    const [notifications, setNotificationEvents] = useState([{ id: "", title: "", type: "", created_at: "" },]);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const handleDateChange = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        setPbsDate(formattedDate);
    };
    const handleTimeChange = (value) => {
        setPbsTime(value);
    };
    const fetchUserSteps = async () => {

        await axios.post(api_url + 'get_student_step_flag', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                } else {
                    window.location.href = '/student-portal/general-enquiry';
                }
            } else {

                if (res.data.gef_status == "0" || res.data.giq_status == "0" || res.data.academic_status == "0" || res.data.curricular_status == "0") {
                    navigate("/student-portal/general-enquiry");
                }
            }
            setLoading(false)

        })
    }
    const logout = () => {
        localStorage.removeItem('user')
        setIsLoggedIn(false);
        navigate('/');
    }

    const handleIsView = (id) => {

        axios.post(api_url + 'update_notification_view', {
            user_id: user_data.id,
            nid: id,

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const notify = res.data.data;
            if (notify.type === "PBS") {
                navigate('/counsellor-dashboard/pending-session');
            } else {
                //navigate('/student-dashboard/my-classes');
            }

        })
    }

    const fetchPBSData = async () => {
        await axios.post(api_url + 'get_pbs_session', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const pbs_data = res.data.data;

            setEvents(pbs_data);
            setLoading(false)

        })
    }
    const getTimeSlot = async () => {

        await axios.post(api_url + 'pbs-time-slot', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const time_slot = res.data.data;

            let options = [];
            time_slot.map((element, index) => {
                options.push({
                    label: element.slot,
                    value: element.slot
                });
            })

            settimeSlot(options);
            setLoading(false);
        })


    }

    const getUserNotification = async () => {

        await axios.post(api_url + 'get_users_notification', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const notify = res.data.data;
            setNotificationEvents(notify);

            setLoading(false);
        })


    }
    const fetchEventData = async () => {
        await axios.post(api_url + 'user/get_event_data', {
            user_id: user_data.id,
            type: 'Mentor'

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const pbs_data = res.data.data;

            setEvents(pbs_data);
            setLoading(false)

        })
    }
    useEffect(() => {
        // fetchUserSteps()
        fetchEventData()
        // getTimeSlot()
        getUserNotification()
    }, [])




    let isProfileVisible = "";
    if (path.includes('student-dashboard')) {
        isProfileVisible = 'student-dashboard';
    }

    let isCounsellorVisible = "";
    if (path.includes('counsellor-dashboard')) {
        isCounsellorVisible = 'counsellor-dashboard';
    }

    const access_token = JSON.parse(localStorage.getItem('user'));
    if (access_token) {
        setAuthToken(access_token.token);
    }

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const TyModalVisibleNew = () => {
        setTyModalVisible(false);
        const timer = setTimeout(() => {
            navigate(window.location.pathname);
        }, 1000);



    }

    const handleApi = () => {
        axios.post(api_url + 'pbs-request', {
            date: pbsDate,
            time: pbsTime,
            user_id: user_data.id,
        })
            .then(result => {
                if (result.data.status) {
                    setPbsMoadalVisible(false);
                    setTyModalVisible(true);
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }
    const onSelect = (date) => {
        const formattedDate = date.format('YYYY-MM-DD');

        axios.post(api_url + 'get_student_pbs_request', {
            user_id: user_data.id,
            event_date: formattedDate,
            type: "Mentor"

        }).then((res) => {
            console.log(res);
            if (res.data.status) {
                let pbs_cal_req = JSON.parse(res.data.data);
                setPBSEvent(pbs_cal_req);
                setLoading(false)
                setCalanderMoadalVisible(true);
            }
        })
    };
    const allMenuItems = [

    ]

    const countryHead = [
        {
            key: '1',
            title: 'Dashboard',
            path: '/counsellor-dashboard/dashboard-home',
            icon: '/images/calendar-img.png'
        },
        // {
        //     key: '5',
        //     title: 'Manage Counseller',
        //     path: '/counsellor-dashboard/counsellor-list',
        //     icon: '/images/profile.png'
        // },
        {
            key: '6',
            title: 'Manage Application Student',
            path: '/counsellor-dashboard/application-student-list',
            icon: '/images/profile.png'
        },
    ];

    const counsellor = [

        {
            key: '6',
            title: 'Manage Application Student',
            path: '/counsellor-dashboard/application-student-list',
            icon: '/images/profile.png'
        },
    ];

    const mentorHead = [
        {
            key: '1',
            title: 'Dashboard',
            path: '/counsellor-dashboard/dashboard-home',
            icon: '/images/calendar-img.png'
        },
        {
            title: 'All Mentor List',
            path: '/counsellor-dashboard/counsellor-list',
            icon: '/images/profile.png'
        },
        {
            title: 'All Student List',
            path: '/counsellor-dashboard/student-list',
            icon: '/images/profile.png'
        },
        {
            title: 'All PBS Session',
            path: '/counsellor-dashboard/pbs-list',
            icon: '/images/profile.png'
        },
        {
            title: 'All Pending PBS',
            path: '/counsellor-dashboard/pending-pbs-list',
            icon: '/images/profile.png'
        },
        {
            title: 'My Student Record',
            path: '/counsellor-dashboard/student-record',
            icon: '/images/application.png',
        },
        {
            title: 'Pending Session',
            path: '/counsellor-dashboard/pending-session',
            icon: '/images/online-survey.png'
        },

        {
            title: 'Completed Session',
            path: '/counsellor-dashboard/completed-session',
            icon: '/images/profile.png'
        },

    ];

    const mentorAdmin = [
        {
            key: '1',
            title: 'Dashboard',
            path: '/counsellor-dashboard/dashboard-home',
            icon: '/images/calendar-img.png'
        },
        {
            title: 'All Mentor List',
            path: '/counsellor-dashboard/counsellor-list',
            icon: '/images/profile.png'
        },
        {
            title: 'All Student List',
            path: '/counsellor-dashboard/student-list',
            icon: '/images/profile.png'
        },
        {
            title: 'All PBS Session',
            path: '/counsellor-dashboard/pbs-list',
            icon: '/images/profile.png'
        },
        {
            title: 'All Pending PBS',
            path: '/counsellor-dashboard/pending-pbs-list',
            icon: '/images/profile.png'
        }
    ];

    const mentorCoordinator = [
        {
            key: '1',
            title: 'Dashboard',
            path: '/counsellor-dashboard/dashboard-home',
            icon: '/images/calendar-img.png'
        },

        {
            title: 'All Student List',
            path: '/counsellor-dashboard/student-list',
            icon: '/images/profile.png'
        },
        {
            title: 'All Pending PBS',
            path: '/counsellor-dashboard/pending-pbs-list',
            icon: '/images/profile.png'
        },

        {
            title: 'My Student Record',
            path: '/counsellor-dashboard/student-record',
            icon: '/images/application.png',
        },
        {
            title: 'Pending Session',
            path: '/counsellor-dashboard/pending-session',
            icon: '/images/online-survey.png'
        },

        {
            title: 'Completed Session',
            path: '/counsellor-dashboard/completed-session',
            icon: '/images/profile.png'
        },
    ];

    const mentor = [
        {
            key: '1',
            title: 'Dashboard',
            path: '/counsellor-dashboard/dashboard-home',
            icon: '/images/calendar-img.png'
        },
        {
            key: '2',
            title: 'My Student Record',
            path: '/counsellor-dashboard/student-record',
            icon: '/images/application.png',
        },
        {
            key: '3',
            title: 'Pending Session',
            path: '/counsellor-dashboard/pending-session',
            icon: '/images/online-survey.png'
        },

        {
            key: '4',
            title: 'Completed Session',
            path: '/counsellor-dashboard/completed-session',
            icon: '/images/profile.png'
        },


    ];

    let menuItems = [];

    if (user_data.counsellor_type === 'Country Counsellor Head' || user_data.counsellor_type === 'Editor Counsellor Head') {
        menuItems = allMenuItems.concat(countryHead);
    }

    else if (user_data.counsellor_type === 'Country Counsellor' || user_data.counsellor_type === 'Editor Counsellor') {
        menuItems = allMenuItems.concat(counsellor);
    }
    else if (user_data.counsellor_type === 'Mentor Head') {
        menuItems = allMenuItems.concat(mentorHead);
    }
    else if (user_data.counsellor_type === 'Mentor Coordinator') {
        menuItems = allMenuItems.concat(mentorCoordinator);
    }
    else if (user_data.counsellor_type === 'Admin') {
        menuItems = allMenuItems.concat(mentorAdmin);
    }
    else {
        menuItems = allMenuItems.concat(mentor);
    }

    const items = [
        {
            key: '1',
            label: (
                <a onClick={logout}>Logout</a>
            ),
        },
        {
            key: '2',
            label: (
                <a onClick={() => setPassModalvisible(true)}>Change Password</a>
            ),
        },
    ]

    const handleCancel = () => {
        form.resetFields();
        setPassModalvisible(false)
    };

    const handlePassword = async (values) => {
        try {
            const res = await axios.post(api_url + 'change-password', {
                user_id: user_data.id,
                password: values.currentPassword,
                new_password: values.newPassword,
                confirm_password: values.confirmPassword
            });

            // console.log(res); // log the response data to the console
            if (res.data.status) {

                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                const timer = setTimeout(() => {
                    setPassModalvisible(false)
                    window.location.reload();
                }, 3000);

            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

            }

        } catch (error) {
            //console.log(error);
        }
    };

    const dateCellRender = (date) => {
        const formattedDate = date.format('YYYY-MM-DD');

        const event = events.find((e) => e.date === formattedDate);

        if (event) {

            let options = [];
            events.map((element, index) => {

                if (formattedDate === element.date) {
                    options.push({
                        id: element.id,
                        date: element.date,
                        event: "Event - " + element.status,
                    });
                }
            })

            return (
                <ul className="events">
                    {options.map((item) => (

                        (item.id === '1' ? <li key={item.id}></li> : <li key={item.id}></li>)
                    ))}
                </ul>
            );
        }
        return null;
    };

    useEffect(() => {
        const handleResize = () => {
            setCollapsed(window.innerWidth <= 1024);
        };

        handleResize(); // Call the function once to set the initial state

        window.addEventListener('resize', handleResize); // Listen for window resize events

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up the event listener
        };
    }, []);


    return (
        <div className="dashboardSection">
            <ToastContainer />
            <Layout>

                <Header className='sticky top-0 z-10'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #F1F1F1',
                        padding: '0 24px',
                        width: '100%',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            className="trigger"
                            onClick={toggleCollapse}
                            style={{ marginRight: '16px' }}
                        >
                            {collapsed ? (
                                <MenuUnfoldOutlined className='text-2xl' />
                            ) : (
                                <MenuFoldOutlined className='text-2xl' />
                            )}
                        </div>
                        <img
                            src="/images/allen-global-logo.png"
                            width="150px"
                            alt="company logo"
                        // style={{ marginRight: '10px', paddingBottom: 10 }}
                        />
                        <ExclamationCircleOutlined className='flex justify-center items-center text-[#0F4275] text-xl pl-24 dashboard-menu-title' />
                        <h1 className="text-[#F0AA0C] text-lg pl-2.5 dashboard-menu-title">
                            {selectedMenuTitle || user_data.counsellor_type + ' Dashboard'}
                        </h1>
                    </div>
                    <div className="flex items-center">
                        <div className='mr-5'>
                            <Badge count={notifications.length}>
                                <Avatar shape="circle" size="large" icon={<BellOutlined className="text-[#F0AA0C]" />} onClick={() => setIsDrawerVisible(true)} />
                                <Drawer
                                    title="Notifications"
                                    placement="right"
                                    onClose={() => setIsDrawerVisible(false)}
                                    open={isDrawerVisible}
                                    width={400}
                                >
                                    {notifications.length > 0 ? (
                                        <div className="notification-wrape">
                                            {notifications.map((item, index) => (


                                                <div className="live-session" key={index}>
                                                    <div className="live-notification-icon">

                                                    </div>
                                                    <div className="live-notification-title">
                                                        <Link onClick={() => handleIsView(item.id)} ><p className="notification-msg">{item.title}</p></Link>
                                                    </div>

                                                    <div className="notification-time">
                                                        <p>Time : <span>{item.created_at}</span></p>
                                                    </div>

                                                    <div className="show-live-session-text">
                                                        <p>{item.type}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="notification-wrape"><div className="live-notification-title">No record found !!</div></div>

                                    )}

                                </Drawer>
                            </Badge>
                        </div>
                        <div className="flex justify-center items-center">
                            {user_data.image == null ? (
                                <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                            ) : (
                                <img src={counsellorPath + user_data.image} className="user-img flex justify-start items-center" alt="user avatar" />
                            )}

                        </div>

                        <Dropdown menu={{ items }}>
                            <DownOutlined className='flex justify-center items-center pl-2.5' />
                        </Dropdown>
                        <Modal
                            open={PassModalvisible}
                            title="Change Password"
                            onCancel={handleCancel}
                            footer={null}
                        >
                            <Form form={form} onFinish={handlePassword}>
                                <Form.Item
                                    name="currentPassword"
                                    label="Current Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your current password!',
                                        },
                                    ]}
                                >
                                    <Input.Password name="password" />
                                </Form.Item>
                                <Form.Item
                                    name="newPassword"
                                    label="New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your new password!',
                                        },
                                    ]}
                                >
                                    <Input.Password name="new_password" />
                                </Form.Item>
                                <Form.Item
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    dependencies={['newPassword']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your new password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password name="confirm_password" />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 6 }}>
                                    <Button type="primary" htmlType="submit" className='bg-[#F0AA0C]'>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                </Header>

                <Layout>

                    
                        <Sider
                        width={300}
                        trigger={null}
                        collapsible
                        collapsed={collapsed}
                        //onCollapse={toggleCollapse}
                        className={collapsed ? 'collapsed-sider' : ''}
                        onCollapse={setCollapsed}
                        id="main-sidebar"
                    >


                        <div className={`flex my-5 ${collapsed ? 'collapsed-sider-content' : ''}`}>
                            {user_data.image == null ? (
                                collapsed ? null : <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                            ) : (
                                <img src={counsellorPath + user_data.image} className="user-img flex justify-start items-center" alt="user avatar" />
                            )}

                            {!collapsed && (
                                <div className='pl-2.5 pb-5'>
                                    <h3 className='text-[#707070] text-base'>{decrypt(user_data.name, encriptionKey)}</h3>
                                </div>
                            )}
                        </div>

                        {pbsMoadalVisible && (
                            <Modal title="Schedule PBS Session"

                                open={pbsMoadalVisible}
                                okText='Schedule PBS'
                                onOk={() => {
                                    handleApi()
                                }}
                                onCancel={() => setPbsMoadalVisible(false)}
                                okButtonProps={{ style: { background: '#F0AA0C', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16 } }}
                                cancelButtonProps={{ style: { background: '#717070', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16, color: '#fff' } }}
                            >
                                <Form>
                                    <Row gutter={30} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 20 }}>
                                        <Col md={12}>
                                            <Form.Item
                                                name="pbs_date"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                label="Select Date"
                                                initialValue={pbsDate.pbs_date}
                                            >
                                                <Space direction="vertical" style={{ width: '100%' }}>
                                                    <DatePicker onChange={handleDateChange} style={{ width: '100%' }} />
                                                </Space>
                                            </Form.Item>
                                        </Col>

                                        <Col md={12}>
                                            <Form.Item
                                                name="pbs_time"
                                                label="Select Time"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={pbsTime.pbs_time}
                                            >

                                                <Select
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    placeholder="Select Slot"
                                                    onChange={handleTimeChange}
                                                    options={timeSlot}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                            </Modal>
                        )}
                        <Modal title={<img src='/images/send-mail.png' />} open={tymodalVisible}
                            onOk={TyModalVisibleNew}
                            onCancel={() => setTyModalVisible(false)}
                            okButtonProps={{ style: { background: '#0F4275', color: '#fff', borderRadius: 7 } }}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >
                            <h4 className="text-[#2A6496] text-xl text-center">PBS Request</h4>
                            <h3 className="text-[#FFC040] text-2xl text-center">Successfully Send</h3>
                            <p className="text-[#5B5B5B] text-base text-center">Your counsellor contact to you very soon</p>
                            <p className="text-[#5B5B5B] text-base text-center">Thank You !!</p>
                        </Modal>
                        <Menu
                            theme='light'
                            mode="inline"
                            defaultSelectedKeys={[window.location.pathname]}
                            defaultOpenKeys={['sub1']}

                        >
                            {menuItems.map((item) => (
                                <Menu.Item key={item.path} title={item.title} style={{ paddingLeft: collapsed ? 10 : '15px' }} onClick={() => setSelectedMenuTitle(item.title)}>
                                    <div className='flex items-center'>
                                        <Link to={item.path} ><img src={item.icon} alt={item.title} className='flex items-center justify-center' style={{ width: collapsed ? '35px' : '24px', height: collapsed ? '35px' : '24px', }} /></Link>

                                        {!collapsed && (
                                            <Link to={item.path} className='pl-2.5'>{item.title}</Link>
                                        )}
                                    </div>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Sider>

                    {calandermodalVisible && (
                        <Modal title="PBS Schedule Details" width={800} open={calandermodalVisible}
                            onCancel={() => setCalanderMoadalVisible(false)}
                            okButtonProps={{ style: { display: 'none' } }}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >

                            <table className='curricular'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>User</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{pbsEvent.date}</td>
                                        <td>{pbsEvent.time}</td>
                                        <td>{pbsEvent.counsellor ? (decrypt(pbsEvent.counsellor, encriptionKey)) : ('Mentor Not Assign')}</td>
                                        <td>{pbsEvent.status}</td>
                                        <td>
                                            {pbsEvent.status == "Pending" ? (
                                                <>
                                                    <Button className="text-md text-white bg-[#F0AA0C]  mx-1.5"
                                                        style={{ height: 'auto', borderRadius: 20, display: 'flex', justifyContent: 'center', color: '#fff' }}>Pending</Button>

                                                </>
                                            ) : ('')

                                            }
                                            {pbsEvent.status == "Complete" ? (

                                                <Link
                                                    className="text-md text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center items-center mr-2.5"
                                                    style={{ height: 'auto', borderRadius: 20 }}
                                                >
                                                    View
                                                </Link>
                                            ) : ('')

                                            }

                                            {pbsEvent.status == "Approved" && pbsEvent.link != null && pbsEvent.counsellor != null ? (
                                                <a style={{ height: 'auto', borderRadius: 20 }} className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 justify-center items-center mr-2.5" target="_blank" href={'/counsellor-dashboard/targets/' + encrypt(pbsEvent.id.toString(), encriptionKey)}> Start Session</a>
                                            ) : ('')

                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal>
                    )}

                    <div className='dash-cal-sesseion'>
                        {children}
                        {user_data.counsellor_type === 'Country Counsellor Head' ? ('') : (path == '/counsellor-dashboard/dashboard-home' ? (
                            <Sider width={300} style={{ background: '#fff', }}>
                                <div className='pt-5 cal-top'>
                                    <span className='text-[#30203A] text-base pl-2.5'>Hi,</span>
                                    <h4 className='text-[#30203A] text-base mb-5 pl-2.5'>{decrypt(user_data.name, encriptionKey)}</h4>
                                    <img src='/images/right-banner.png' alt='right-banner' />

                                    <div className='current-date'>
                                        <div className='date-info'>
                                            <h2>{date} {day}</h2>
                                            <span>{month} {year}</span>
                                        </div>
                                    </div>
                                </div>

                                <Calendar className='cal' dateCellRender={dateCellRender} onSelect={onSelect} fullscreen={false} />
                            </Sider>
                        ) : (''))}


                        <Footer />
                    </div>
                </Layout>

            </Layout>

        </div>
    );
}

export default CounsellorLayout