import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Dropdown, Button, Space, Modal, Form, Layout } from "antd";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import CounsellorLayout from "./counsellor-layout";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url, base_url } from '../components/constants';
const { Search } = Input;
const { Content } = Layout;

const PendingSession = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const dateAfterAddition = new Date(currentDateTime);
    const [loading, setLoading] = useState(true);
    const [sessionList, setSessionList] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [pbsLink, setLinkData] = useState({ title: "",duration:"" });
    const [pbsId, setPBSId] = useState({ pbs_id: "" });
    const [form] = Form.useForm();
    const fetchPendingSession = async () => {

        await axios.post(api_url + 'counsellor-pending-session', {
            counsellor_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.users;
            console.log(st_list);
            const k = st_list.map((item, index) =>
            ({
                'sno': index + 1,
                'name': decrypt(item.name, encriptionKey),
                'class': decrypt(item.class, encriptionKey),
                'course': decrypt(item.course, encriptionKey),
                'center': item.center,
                'date': item.date + ' ' + item.time,
                'status': item.status,
                'pbs_id': item.id,
                'link': item.link,
                'meeting_id': item.meeting_id,
                'duration': item.duration,
            })
            );

            setSessionList(k);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchPendingSession()
    }, [])

    const onApproveRequest = (record) => {
        //console.log(record);
    };
    const onRejectRequest = (record) => {
        //console.log(record);
    };
    const onEditRequest = (record) => {
        setPBSId(record.pbs_id);
        setLinkData(record.title);

        setEditModalVisible(true);
    };
    const onStartSession = (record) => {
        const pbs_id = encrypt(record.pbs_id.toString(), encriptionKey);
        const url = "/counsellor-dashboard/targets/" + pbs_id;
        window.open(url, "_blank");
        // If you also want to navigate in the current tab, use the following line
        // window.location.href = url;
    };
    

    const handleApi = () => {
        
        
        axios.post(api_url + 'update-pbs-link', {
            title: pbsLink.title,
            duration: pbsLink.duration,
            pbs_id: pbsId,
        })
            .then(result => {
                
                if (result.data.status) {

                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        setEditModalVisible(false);
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }

    const handleChange = (name, value) => {
        setLinkData({ ...pbsLink, [name]: value });

    };

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",

        },

        {
            key: "2",
            title: "Name",
            dataIndex: "name",
            align: 'center'
        },
        {
            key: "3",
            title: "Class",
            dataIndex: "class",
            align: 'center'
        },
        {
            key: "4",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "5",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "6",
            title: "Date & Time",
            dataIndex: "date",
            align: 'center'
        },
        {
            key: "7",
            title: "Status",
            dataIndex: "status",
            align: 'center'
        },
        {
            key: "8",
            title: "Actions",
            render: (record) => {
                return (
                    <>
                        <Space>
                        {record.status == 'Complete' ? (<Button onClick={() => { onStartSession(record); }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Enter PBS Output</Button>) 
                        
                        : 
                        (record.meeting_id == null ? (
                                <Button onClick={() => { onEditRequest(record); }} className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }}>Edit</Button>
                            ) : (<>

                                {(new Date(record.date).setMinutes(new Date(record.date).getMinutes() + record.duration)) > dateAfterAddition ? (<>
                                <Button className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }} onClick={() => joinZoomMeeting(record)}>
                                    Join Session
                                </Button>
                                <Button onClick={() => onStartSession(record)} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>
                                    Enter PBS Output
                                </Button></>
                                ) : (
                                <Button onClick={() => onStartSession(record)} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>
                                    Enter PBS Output
                                </Button>
                                )}

                                
                                </>
                            ))}
                            {/* <Button  onClick={() => { onRejectRequest(record);}} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Reject</Button> */}
                            


                        </Space>

                    </>
                );
            },
        },
    ];

    const joinZoomMeeting = (record) => {
        navigate("/counsellor-dashboard/join-session/"+record.meeting_id);
    };

    

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const searchItems = (searchValue) => {
        
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = sessionList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults(sessionList)
        }
    }

    return (
        <>
            <ToastContainer />
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mx-0 mt-0">
                        <Row>
                            <Col md={24}>
                                <div className="bg-[#114D89] px-5 py-2.5">
                                    <h1 className="text-white text-2xl ">Pending PBS Session</h1>
                                </div>
                            </Col>
                        </Row>

                        {editModalVisible && (
                            <Modal title="Update PBS Session Zoom Link"

                                open={editModalVisible}
                                okText='Submit'
                                onOk={() => {
                                    handleApi()
                                }}
                                onCancel={() => setEditModalVisible(false)}
                                okButtonProps={{ style: { background: '#F0AA0C', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16 } }}
                                cancelButtonProps={{ style: { background: '#717070', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16, color: '#fff' } }}
                            >
                                <Form >
                                    <Row gutter={30} >
                                        <Col md={24} xs={24}>
                                            <Form.Item
                                                name="title"
                                                label="Title"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter title for pbs session',
                                                    },
                                                ]}
                                            // initialValue={pbsLink.link}
                                            >
                                                <Input onChange={(e) => handleChange('title', e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={24} xs={24}>
                                            <Form.Item
                                                name="duration"
                                                label="Duration"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter duration',
                                                    },
                                                ]}
                                            // initialValue={pbsLink.link}
                                            >
                                                <Input type="number" onChange={(e) => handleChange('duration', e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                            </Modal>
                        )}

                        <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                            {/* <Row>
                            <Col>
                                <h2 className="text-[#061522] text-2xl mb-2.5">Your Pending Session</h2>
                                <span className="text-[#9B9B9B] text-lg">This is your most recent records.</span>
                            </Col>
                        </Row> */}
                            <Row> 
                            <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                <Search onChange={(e) => searchItems(e.target.value)} placeholder="Search...." />
                                </Col>
                                <Col md={24}>
                                {searchInput.length > 1 ? (
                                <Table className="record" bordered columns={columns} dataSource={filteredResults}/>
                                ) : (
                                    <Table className="record" bordered columns={columns} dataSource={sessionList}/>
                                )}

                                </Col>
                                
                            </Row>
                        </div>
                    </div>
                </Content>
                )}
            </CounsellorLayout>
        </>
    )
}

export default PendingSession