import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space, Layout, Modal, Form } from "antd";
import DashboardLayout from '../components/dashboard-layout';
import { useNavigate } from 'react-router-dom'
import { api_url, studentPath, studyMaterial } from '../components/constants';
const { Search } = Input;
const { Content } = Layout;


const ExtraClass = () => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [classList, setClassList] = useState([]);
    const [materialList, setMatrialList] = useState([]);
    const [studyModalVisible, setStudyModalVisible] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    const fetchClassData = async () => {

        await axios.post(api_url + 'user/get_extra_classes', {
            user_id: user_data.id

        }).then((res) => {

            const st_list = res.data.class_list;

            if (st_list) {
                const k = st_list.map((item, index) =>
                ({
                    'sno': index + 1,
                    'topic': item.class_name,
                    //'center': item.center,
                    'date': item.date + ' ' + item.start_time,
                    'duration': item.duration,
                    'time': item.time_slot,
                    'status': item.status,
                    'desc': item.description,
                    'link': item.class_link,
                    'class_id': item.id,
                    'meeting_id': item.meeting_id,
                    'study_material': item.study_material,
                    'class_status': item.class_status,
                })
                );

                setClassList(k);
                setLoading(false)
            }


        })
    }

    const viewStudyMaterial = (record) => {
        console.log(record.study_material)
        const std = record.study_material.split(",");
        const klist = std.map((item, index) =>
        ({
            'sno': index + 1,
            'key': 'Study Material ' + index + 1,
            'value': item,
        })
        );

        setMatrialList(klist)
        setStudyModalVisible(true)
    };


    const joinClass = (record) => {

        window.location.href = record.link;
    };

    useEffect(() => {
        fetchClassData()

    }, [])

    const joinZoomMeeting = (record) => {
        navigate("/student-dashboard/join-class/" + record.meeting_id);
    };


    const columns = [
        {
            key: '1',
            title: 'Sno',
            dataIndex: 'sno',
        },
        {
            key: '2',
            title: 'Topic',
            dataIndex: 'topic',
            align: 'center',
        },
        {
            key: '3',
            title: 'Desc',
            dataIndex: 'desc',
            align: 'center',
            render: (text, record) => (
                <div>
                    {showFullDescriptions[record.key] ? (
                        <div>
                            {text}
                            <br />
                            <Button
                                type="link"
                                onClick={() => toggleDescription(record.key)}
                            >
                                View Less
                            </Button>
                        </div>
                    ) : (
                        <div>
                            {text.slice(0, 50)}{' '}
                            {text.length > 50 && (
                                <Button
                                    type="link"
                                    onClick={() => toggleDescription(record.key)}
                                >
                                    View More
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            key: '4',
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
        },
        {
            key: '5',
            title: 'Duration (Min)',
            dataIndex: 'duration',
            align: 'center',
        },
        {
            key: '6',
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
        },
        {
            key: '7',
            title: 'Action',
            render: (record) => {
                return (
                    <Space>
                        {record.meeting_id != null && record.status !== 'Complete' ? (
                            record.class_status === "1" && record.status === 'Pending' ? (
                                <Button
                                    className="bg-[#F0AA0C] text-white"
                                    style={{ borderRadius: 11 }}
                                    onClick={() => joinZoomMeeting(record)}
                                >
                                    Join Class
                                </Button>
                            ) : (record.status === "Cancelled" ? ('-') : (<Button
                                className="bg-[#F0AA0C] text-white"
                                disabled
                                style={{ borderRadius: 11 }}
                            >
                                Join Class
                            </Button>)

                            )
                        ) : (
                            ''
                        )}
                        {record.status === 'Complete' && record.study_material != '' ? (<>
                            <Button
                                onClick={() => {
                                    viewStudyMaterial(record);
                                }}
                                className="bg-[#205584] text-white"
                                style={{ borderRadius: 11 }}
                            >
                                Study Material
                            </Button>
                            <Button className="bg-[#205584] text-white" style={{ borderRadius: 11 }} onClick={() => viewZoomMeeting(record)}> View Recording</Button></>
                        ) : (
                            record.status === 'Complete' ? (<Button className="bg-[#205584] text-white" style={{ borderRadius: 11 }} onClick={() => viewZoomMeeting(record)}> View Recording</Button>) : ('')

                        )}


                    </Space>
                );
            },
        },
    ];

    const viewZoomMeeting = (record) => {
        navigate("/student-dashboard/view-class/" + record.meeting_id);
    };

    const [showFullDescriptions, setShowFullDescriptions] = useState({});

    const toggleDescription = (key) => {
        setShowFullDescriptions({
            ...showFullDescriptions,
            [key]: !showFullDescriptions[key],
        });
    };

    return (
        <>
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >

                        <div className="mx-0 mt-0">
                            <Row>
                                <Col md={24} xs={24}>
                                    <div className="bg-[#114D89] px-5 py-2.5">
                                        <h1 className="text-white text-2xl ">Extra Classes</h1>
                                    </div>
                                </Col>
                            </Row>
                            <div className="py-5 px-5" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                <Row>

                                    <Col md={6} xs={24} style={{ display: 'flex', justifyContent: 'space-between' }} className='mb-5'>

                                        <Search placeholder="Search...." />
                                    </Col>
                                    <Col md={24} className="responsive-table">
                                        <Table
                                            className="record"
                                            bordered
                                            columns={columns}
                                            dataSource={classList}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Row>
                            <Col>
                                {studyModalVisible && (
                                    <Modal title="View & Download study material"
                                        open={studyModalVisible}
                                        onOk={() => setStudyModalVisible(false)}
                                        onCancel={() => setStudyModalVisible(false)}
                                        footer={null}
                                        maskClosable={false}
                                    >
                                        <Form>
                                            <Row gutter={30}>
                                                {loading ? (
                                                    <div className="loaders"><img src='/images/loader.gif' /></div>
                                                ) : (
                                                    <Col md={24}>
                                                        <div class="ant-modal-body responsive-table">
                                                            <table class="curricular ">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sno</th>
                                                                        <th>Material</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {materialList.map(item =>
                                                                        <tr key={item.sno}>
                                                                            <td>{item.sno}</td>
                                                                            <td>{item.key}</td>
                                                                            <td><a href={studyMaterial + item.value} className="bg-[#205584] text-white"
                                                                                target="_blank" rel="noreferrer" style={{ borderRadius: 11, padding: '4px 10px' }}> View</a></td>

                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>


                                                    </Col>

                                                )}
                                            </Row>
                                        </Form>
                                    </Modal>
                                )}
                            </Col>
                        </Row>

                    </Content>
                )
                }
            </DashboardLayout>
        </>
    )
}

export default ExtraClass