import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Button, Col, Row, Form, Layout, Tabs, Collapse, Timeline, Space, Table, Select, Popconfirm, Radio, Modal } from 'antd';
import CounsellorLayout from "./counsellor-layout";
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { useParams, useNavigate } from 'react-router-dom'
import { api_url, studentPath, reportPath } from '../components/constants';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Panel } = Collapse
const { Content } = Layout;
const { TabPane } = Tabs;
const StudentInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [studentData, setStudentData] = useState({
        name: '', form_number: '', course_name: '', profile_picture: '', batch: '', dob: '', class: '', school: '', address: '', father_name: '', mother_name: '', father_occupation: '', mother_occupation: '',
    })
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const fetchStudentData = async () => {

        await axios.post(api_url + 'user/get_profile_data', {
            user_id: id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const user_info = [res.data.user_info];

            const stArray = user_info.map(item => ({
                'user_id': item.user_id,
                'name': decrypt(item.name, encriptionKey),
                'email': decrypt(item.email, encriptionKey),
                'form_number': decrypt(item.form_number, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'mother_name': decrypt(item.mother_name, encriptionKey),
                'father_name': decrypt(item.father_name, encriptionKey),
                'dob': decrypt(item.dob, encriptionKey),
                'city': decrypt(item.city, encriptionKey),
                'psychrometric_report': item.psychrometric_report,
                'batch': item.batch,
                'father_occupation': item.father_occupation,
                'mother_occupation': item.mother_occupation,
                'diagnostic_report': item.diagnostic_report,
                'admission_date': item.admission_date,
                'address': item.address,
                'class': decrypt(item.class, encriptionKey),
                'course': decrypt(item.course, encriptionKey),
                'school': item.school,
                'photo': item.photo,

            }));
            setStudentData(stArray[0]);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchStudentData()
    }, [])

    const panelStyle = {
        marginBottom: 24,
        border: 'none',
        background: '#E3E3E3',
        boxShadow: '0px 3px 3px #00000029',
        borderRadius: 5,
        color: '#fff'
    };
    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mx-0 my-7 form-layout">
                            <Row>
                                <Col md={24} xs={24}>
                                    <h1 className="bg-[#0f4275] text-white py-2.5 px-5 text-xl font-medium">Student Information</h1>

                                </Col>
                            </Row>
                            <div className="mx-7 my-10">
                                <Row gutter={30}>
                                    <Col md={6}>
                                        <div className="profile-pic">
                                            {studentData.photo === null || studentData.photo === "" ? (
                                                <img src='/images/avatar.png' className="profile-pic flex justify-start items-center" alt="user avatar" />
                                            ) : (
                                                <img src={studentPath + studentData.photo} className="profile-pic flex justify-start items-center" alt="user avatar" />
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={18}>
                                        <Row gutter={30}>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Form Number :</strong> {studentData.form_number}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Admission Date :</strong> {studentData.admission_date}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Center Name :</strong> {studentData.city}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Name :</strong> {studentData.name}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Mobile :</strong> {studentData.mobile}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Email :</strong> {studentData.email}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>DOB :</strong> {studentData.dob}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Course :</strong> {studentData.course}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Class :</strong> {studentData.class}</p>
                                            </Col>

                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Father's Name :</strong> {studentData.father_name}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb=5 text-base text-[#6c757d]"><strong>Mother's Name :</strong> {studentData.mother_name}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Father's Occupation :</strong> {studentData.father_occupation}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Mother's Occupation :</strong> {studentData.mother_occupation}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Address :</strong> {studentData.address}</p>
                                            </Col>
                                        </Row>

                                        <div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <Row>
                                <Col md={24} xs={24} className='md:px-5 px-2.5'>
                                    <Collapse
                                        className="information"
                                        //defaultActiveKey={['1']}
                                        bordered={false}
                                        expandIconPosition='end'
                                        style={{ marginTop: "-1vh", }}
                                        expandIcon={({ isActive }) => (
                                            <DownOutlined
                                                style={{ color: '#A2A1A1', paddingTop: 10, }}
                                                rotate={isActive ? 180 : 0}
                                            />
                                        )}
                                    >
                                        <Panel header="GENERAL ENQUIRY FORM" key="1" style={panelStyle}>
                                            <GefInfo />
                                        </Panel>
                                        <Panel header="GENERAL INTROSPECTION QUESTIONNAIRE" key="2" style={panelStyle}>
                                            <GiqInfo />
                                        </Panel>
                                        <Panel header="PROFILE FORMAT" key="3" style={panelStyle}>
                                            <ProfileInfo />
                                        </Panel>
                                        <Panel header="TEST SCORE RECORDS" key="4" style={panelStyle}>
                                            <TestInfo />
                                        </Panel>
                                        {studentData.class === "9" ? (<Panel header="PSYCHOMETRIC FORMAT" key="5" style={panelStyle}>
                                            <Psychometric />
                                        </Panel>) : ('')}

                                        <Panel header="ALL PBS SESSION" key="9" style={panelStyle}>
                                            <PbsSession user_id={id} />
                                        </Panel>
                                        {user_data.counsellor_type === 'Country Counsellor' ? (<>
                                            <Panel header="APPLICATION CHECKLIST" key="6" style={panelStyle}>
                                                <ApplicationChecklist />
                                            </Panel>
                                            <Panel header="ASSIGN UNIVERSITY" key="8" style={panelStyle}>
                                                <AssignUniversity />
                                            </Panel></>) : ('')}
                                        {user_data.counsellor_type === 'Editor Counsellor' ? (<>
                                            <Panel header="APPLICATION CHECKLIST" key="6" style={panelStyle}>
                                                <ApplicationChecklist />
                                            </Panel>
                                        </>) : ('')}

                                        {user_data.counsellor_type === 'Editor Counsellor Head' ? (<>
                                            <Panel header="APPLICATION CHECKLIST" key="6" style={panelStyle}>
                                                <ApplicationChecklist />
                                            </Panel>
                                            <Panel header="ASSIGN UNIVERSITY" key="8" style={panelStyle}>
                                                <AssignUniversity />
                                            </Panel>
                                            <Panel header="ASSIGN EDITOR" key="10" style={panelStyle}>
                                                <AssignEditor />
                                            </Panel>

                                        </>) : ('')}

                                        {user_data.counsellor_type === 'Country Counsellor Head' ? (
                                            <>
                                                <Panel header="APPLICATION CHECKLIST" key="6" style={panelStyle}>
                                                    <ApplicationChecklist />
                                                </Panel>
                                                <Panel header="ASSIGN COUNTRY & COUNSELLOR" key="7" style={panelStyle}>
                                                    <AssignCountryCouncellor />
                                                </Panel>
                                                <Panel header="ASSIGN UNIVERSITY" key="8" style={panelStyle}>
                                                    <AssignUniversity />
                                                </Panel>

                                                <Panel header="ASSIGN EDITOR" key="10" style={panelStyle}>
                                                    <AssignEditor />
                                                </Panel>
                                                <Panel header="UNIVERSITY FINALIZATION" key="11" style={panelStyle}>
                                                    <UniFinalization />
                                                </Panel>
                                            </>
                                        ) : ('')}

                                    </Collapse>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                )
                }
            </CounsellorLayout>

        </>
    )
}

const GefInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const login_user = JSON.parse(localStorage.getItem('user'));

    const navigate = useNavigate();
    if (!login_user) {
        window.location.href = "/";
    }
    const [studentData, setStudentData] = useState(
        {
            name: '', email: '', form_number: '', city: '', school: '', father_name: '', mother_name: '', parent_mobile: '', mother_occupation: '', father_occupation: '', grade: '',
            grade_8: '', grade_9: '', grade_10: '', grade_11: '', grade_12: '', sat: '', act: '', toefl: '', ielts: '', sat_ap: '', scholarships: '', hearAbout: '', allen_number: '', mobile: '', address: '',
            university: '', intended: '',
        }
    )

    const fetchGEFData = async () => {

        await axios.post(api_url + 'get_gef_form_data', {
            user_id: id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }

            const user_info = [res.data.data];

            const stArray = user_info.map(item => ({
                'user_id': item.user_id,
                'name': decrypt(item.name, encriptionKey),
                'email': decrypt(item.email, encriptionKey),
                'form_number': decrypt(item.form_number, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'mother_name': decrypt(item.mother_name, encriptionKey),
                'father_name': decrypt(item.father_name, encriptionKey),
                'dob': decrypt(item.dob, encriptionKey),
                'city': decrypt(item.city, encriptionKey),
                'psychrometric_report': item.psychrometric_report,
                'batch': item.batch,
                'father_occupation': item.father_occupation,
                'mother_occupation': item.mother_occupation,
                'diagnostic_report': item.diagnostic_report,
                'admission_date': item.admission_date,
                'address': item.address,
                'class': decrypt(item.class, encriptionKey),
                'course_name': decrypt(item.course, encriptionKey),
                'school': item.school,
                'photo': item.photo,
                'allen_number': item.allen_number,
                'grade_8': item.grade_8,
                'grade_9': item.grade_9,
                'grade_10': item.grade_10,
                'grade_11': item.grade_11,
                'grade_12': item.grade_12,
                'sat': item.sat,
                'act': item.act,
                'ielts': item.ielts,
                'toefl': item.toefl,
                'sat_ap': item.sat_ap,
                'scholarships': item.scholarships,
                'hearAbout': item.hearAbout,
                'university': item.university,
                'intended': item.intended,
                'parent_mobile': item.parent_mobile,
            }));

            setStudentData(stArray[0]);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchGEFData()

    }, [])

    return (
        <Form
            name="normal_login"
            className="mx-0 py-5 px-2.5"


        >
            <Row gutter={30} style={{ marginBottom: 20 }}>
                <Col md={24}>
                    <Row gutter={30}>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Form Number :</strong> {studentData.form_number}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Admission Date :</strong> {studentData.admission_date}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Center Name :</strong> {studentData.city}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Name :</strong> {studentData.name}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Mobile :</strong> {studentData.mobile}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Email :</strong> {studentData.email}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>DOB :</strong> {studentData.dob}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Course :</strong> {studentData.course}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Class :</strong> {studentData.class}</p>
                        </Col>
                        {/* <Col md={8}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Batch :</strong> {studentData.batch}</p>
                        </Col> */}
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Father's Name :</strong> {studentData.father_name}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb=5 text-base text-[#6c757d]"><strong>Mother's Name :</strong> {studentData.mother_name}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Father's Occupation :</strong> {studentData.father_occupation}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Mother's Occupation :</strong> {studentData.mother_occupation}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Address :</strong> {studentData.address}</p>
                        </Col>
                        {studentData.grade_9 && (
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Grade 9 :</strong> {studentData.grade_9}</p>
                            </Col>
                        )}
                        {studentData.grade_10 && (
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Grade 10 :</strong> {studentData.grade_10}</p>
                            </Col>
                        )}

                        {studentData.grade_11 && (
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Grade 11 :</strong> {studentData.grade_11}</p>
                            </Col>
                        )}
                        {studentData.grade_12 && (
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Grade 12 :</strong> {studentData.grade_12}</p>
                            </Col>
                        )}
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Target Universities :</strong> {studentData.university}</p>
                        </Col>
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Intended Major (s) :</strong> {studentData.intended}</p>
                        </Col>
                        {studentData.sat && (
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>SAT Score :</strong> {studentData.sat}</p>
                            </Col>
                        )}
                        {studentData.act && (
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>ACT Score  :</strong> {studentData.act}</p>
                            </Col>
                        )}
                        {studentData.toefl && (
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>TOEFL Score  :</strong> {studentData.toefl}</p>
                            </Col>
                        )}
                        {studentData.ielts && (
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>IELTS Score  :</strong> {studentData.ielts}</p>
                            </Col>
                        )}
                        {studentData.sat_ap && (
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>SAT/AP Score  :</strong> {studentData.sat_ap}</p>
                            </Col>
                        )}
                        <Col md={8} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Where/How/When did you hear about AGSD :</strong> {studentData.hearAbout}</p>
                        </Col>
                        <Col md={24} xs={24}>
                            <p className="mb-5 text-base text-[#6c757d]"><strong>Will you be able to afford your studies abroad if scholarships are not provided </strong> </p>
                            <p>{studentData.scholarships}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

const Psychometric = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const login_user = JSON.parse(localStorage.getItem('user'));

    const navigate = useNavigate();
    if (!login_user) {
        window.location.href = "/";
    }
    const [studentData, setStudentData] = useState(
        {
            psychrometric_report: "", name: '', email: '', form_number: '', city: '', school: '', father_name: '', mother_name: '', parent_mobile: '', mother_occupation: '', father_occupation: '', grade: '',
            grade_8: '', grade_9: '', grade_10: '', grade_11: '', grade_12: '', sat: '', act: '', toefl: '', ielts: '', sat_ap: '', scholarships: '', hearAbout: '', allen_number: '', mobile: '', address: '',
            university: '', intended: '',
        }
    )

    const fetchGEFData = async () => {

        await axios.post(api_url + 'get_gef_form_data', {
            user_id: id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }

            const user_info = [res.data.data];

            const stArray = user_info.map(item => ({
                'user_id': item.user_id,
                'name': decrypt(item.name, encriptionKey),
                'email': decrypt(item.email, encriptionKey),
                'form_number': decrypt(item.form_number, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'mother_name': decrypt(item.mother_name, encriptionKey),
                'father_name': decrypt(item.father_name, encriptionKey),
                'dob': decrypt(item.dob, encriptionKey),
                'city': decrypt(item.city, encriptionKey),
                'psychrometric_report': item.psychrometric_report,
                'batch': item.batch,
                'father_occupation': item.father_occupation,
                'mother_occupation': item.mother_occupation,
                'diagnostic_report': item.diagnostic_report,
                'admission_date': item.admission_date,
                'address': item.address,
                'class': decrypt(item.class, encriptionKey),
                'course_name': decrypt(item.course, encriptionKey),
                'school': item.school,
                'photo': item.photo,
                'allen_number': item.allen_number,
                'grade_8': item.grade_8,
                'grade_9': item.grade_9,
                'grade_10': item.grade_10,
                'grade_11': item.grade_11,
                'grade_12': item.grade_12,
                'sat': item.sat,
                'act': item.act,
                'ielts': item.ielts,
                'toefl': item.toefl,
                'sat_ap': item.sat_ap,
                'scholarships': item.scholarships,
                'hearAbout': item.hearAbout,
                'university': item.university,
                'intended': item.intended,
                'parent_mobile': item.parent_mobile,
            }));

            setStudentData(stArray[0]);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchGEFData()

    }, [])

    return (
        <Form
            name="normal_login"
            className="mx-7 p-5"


        >
            <Row gutter={30} style={{ marginBottom: 20 }}>
                <Col md={24}>
                    <Row gutter={30}>
                        <Col md={24}>
                            <div className="bg-[#F5F5F5] p-7">

                                <div className="md:flex justify-center my-10">
                                    <div>
                                        <img src='/images/pdf-icon.png' alt="pdf" className="pl-5" />
                                        <p className="text-center pt-2.5 text-[#114D89] text-lg font-medium">Psychometric Report</p>
                                    </div>
                                    {loading ? (
                                        <div className="loaders"><img src='/images/loader.gif' /></div>
                                    ) : (
                                        <div className="ml-16">
                                            <a href={reportPath + studentData.psychrometric_report} target="_blank"
                                                className="text-lg text-white bg-[#F0AA0C] py-1.5 px-10 flex justify-center mr-2.5"
                                                style={{ height: 'auto', borderRadius: 20 }}
                                            >
                                                View Report
                                            </a>

                                        </div>
                                    )}
                                </div>
                            </div>

                        </Col>

                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

const PbsSession = (props) => {

    const [loading, setLoading] = useState(false);
    const [testData, setTestData] = useState(null);
    const [academicData, setAcademicData] = useState(null);
    const [internData, setInternData] = useState(null);
    const [suggestioData, setSuggestionData] = useState(null);
    const [pbsData, setPBSData] = useState([{ id: "", date: "", time: "", status: "", mentor: "", link: "", test_records: "", academic_records: "", internship_records: "", suggestion_records: "" }]);
    const pbsTimeline = () => {
        axios.post(api_url + 'student-pbs-timeline', {
            user_id: encrypt(props.user_id, encriptionKey)

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            if (res.data.pbs_data.length > 0) {

                const st_list = res.data.pbs_data;
                const test_target = res.data.test_target_array;
                const academic_target = res.data.academic_target_array;
                const st_intern_data = res.data.st_intern_array;
                const st_suggestion_array = res.data.st_suggestion_array;
                if (academic_target.length > 0) {
                    setAcademicData(JSON.parse(academic_target))
                }
                if (test_target.length > 0) {
                    setTestData(JSON.parse(test_target));
                }
                if (st_intern_data.length > 0) {
                    setInternData(JSON.parse(st_intern_data))
                }
                if (st_suggestion_array.length > 0) {
                    setSuggestionData(JSON.parse(st_suggestion_array))
                }



                setPBSData(st_list);


            }

        })
    }

    useEffect(() => {
        pbsTimeline()
    }, [])

    const items = [
        {
            key: '1',
            label: `STUDENT TARGETS`,
            children: <AcademicTarget data={academicData} />,
        },
        {
            key: '2',
            label: `ACADEMICS TARGETS`,
            children: <StudentTarget data={testData} />,
        },
        {
            key: '3',
            label: `INTERNSHIP ASSISTANCE`,
            children: <InternshipAssistance data={internData} />,
        },
        {
            key: '4',
            label: `PROFILE BUILDING SUGGESTIONS`,
            children: <ProfileBuilding data={suggestioData} />,
        },
    ];
    return (
        <>
            {loading ? (
                <div className="loaders"><img src='/images/loader.gif' /></div>
            ) : (
                <div className="mx-0">
                    <Tabs defaultActiveKey="1" items={items} />
                </div>
            )}
        </>


    )
}


const StudentTarget = (props) => {

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (


                                <Timeline.Item key={index} label={row.date}>
                                    <div className="bg-white md:p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Exam</th>
                                                        <th>Your Achivement</th>
                                                        <th>Assign Target</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.test_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.test}</td>
                                                            <td>{value.achive}</td>
                                                            <td>{value.assign}</td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>

                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}

                    </Timeline>

                </Col>
            </Row>
        </>

    )
}

const AcademicTarget = (props) => {

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={row.date}>
                                    <div className="bg-white md:p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Class</th>
                                                        <th>Your Achivement</th>
                                                        <th>Assign Target</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.academic_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.grade}</td>
                                                            <td>{value.achive}</td>
                                                            <td>{value.assign}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}
                    </Timeline>
                </Col>
            </Row>
        </>
    )
}

const InternshipAssistance = (props) => {

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={row.date}>
                                    <div className="bg-white md:p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Suggestion</th>
                                                        <th>Subject</th>
                                                        <th>Deadline</th>
                                                        <th>Remark</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.intern_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.suggestion}</td>
                                                            <td>{value.subject}</td>
                                                            <td>{value.deadline}</td>
                                                            <td>{value.remark}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}

                    </Timeline>
                </Col>
            </Row>
        </>

    )
}

const ProfileBuilding = (props) => {
    return (
        <>
            <Row>
                <Col md={24} className='form-space'>
                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={row.date}>
                                    <div className="bg-white md:p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th width='20%'>Type</th>
                                                        <th width='30%'>Suggestion</th>
                                                        <th width='30%'>Student Output</th>
                                                        <th width='20%'>Follow Up Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {row.suggestion_data.map((value, k) => (


                                                        <tr key={k}>
                                                            <td>{value.type}</td>
                                                            <td>{value.suggestion}</td>
                                                            <td>{value.output}</td>
                                                            <td>{value.deadline}</td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}
                    </Timeline>
                </Col>
            </Row>
        </>

    )
}

const ApplicationChecklist = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [academicList, setAcademicList] = useState([]);
    const [about, setAbout] = useState(null);
    const [selectedCountry, setSelectedCountries] = useState([]);
    const [selectedStream, setSelectedStream] = useState([]);

    const fetchCheckList = async () => {

        await axios.post(api_url + 'counsellor/get_application_checklist', {
            user_id: id,
        }).then((response) => {

            if (response.data.academic_data) {

                const k = response.data.academic_data.map((item, index) =>
                ({
                    'sno': index + 1,
                    'class': item.class,
                    'percentage': item.percentage,
                    'date': item.date,
                    'city': item.city,
                    'user_id': item.user_id,
                    'file': item.file,
                })
                );

                setAcademicList(k);
                setSelectedCountries(response.data.selected_country);
                setSelectedStream(response.data.selected_stream);
                setAbout(response.data.unique_about_you);
            }
            setLoading(false);
        })
    }


    useEffect(() => {
        fetchCheckList()
    }, []);

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
            align: 'center'
        },

        {
            key: "2",
            title: "Class Name",
            dataIndex: "class",
            align: 'center'
        },

        {
            key: "3",
            title: "Percentage",
            dataIndex: "percentage",
            align: 'center'
        },
        {
            key: "4",
            title: "Date of Exam Given",
            dataIndex: "date",
            align: 'center'
        },
        {
            key: "5",
            title: "City",
            dataIndex: "city",
            align: 'center'
        },
        {
            key: "6",
            title: "Board",
            dataIndex: "board",
            align: 'center'
        },
        {
            key: "7",
            title: "School",
            dataIndex: "school",
            align: 'center'
        },

        {
            key: "8",
            title: "Upload File",
            align: 'center',
            render: (record) => {
                return (
                    <>
                        <Space>
                            <Button href={studentPath + record.file} target="_blank" className="bg-[#a01429] text-white rounded-full">Download</Button>
                        </Space>

                    </>
                );
            },
        },
    ];


    return (

        <>
            {loading ? (
                <div className="loaders"><img src='/images/loader.gif' /></div>
            ) : (
                <>
                    <Row>
                        <Col md={24}>
                            <h2 className="bg-[#FFEFCC] text-[#0C3762] text-xl p-2.5 text-center font-medium">Academic Records</h2>
                        </Col>
                        <Col md={24}>
                            <Table
                                className="application-record"
                                bordered
                                columns={columns}
                                dataSource={academicList}
                            />
                        </Col>
                    </Row>

                    <Row style={{ border: '1px solid #707070' }}>
                        <Col md={24}>
                            <h2 className="bg-[#FFEFCC] text-[#0C3762] text-xl p-2.5 font-medium">Indented Major's</h2>
                        </Col>
                        <Col md={24}>
                            <Row gutter={30} style={{ marginLeft: 10, marginRight: 10 }}>
                                {selectedStream.map((row, index) => (
                                    <Col md={6} key={index}>
                                        <p className="text-[#5B5B5B] bg-[#E4E4E4] text-sm p-2.5 my-5" style={{ border: '1px solid #E4E4E4' }}>{row}</p>
                                    </Col>
                                ))}

                            </Row>
                        </Col>
                    </Row>

                    <Row style={{ border: '1px solid #707070', marginTop: 20 }}>
                        <Col md={24}>
                            <h2 className="bg-[#FFEFCC] text-[#0C3762] text-xl p-2.5 font-medium">What is Unique about you</h2>
                        </Col>
                        <Col md={24}>
                            <p className="text-[#5E5E5E] text-sm p-5">{about}</p>
                        </Col>
                    </Row>


                    <Row style={{ border: '1px solid #707070', marginTop: 20 }}>
                        <Col md={24}>
                            <h2 className="bg-[#FFEFCC] text-[#0C3762] text-xl p-2.5 font-medium">Destination Country</h2>
                        </Col>
                        <Col md={24}>
                            <Row gutter={30} style={{ marginLeft: 10, marginRight: 10 }}>
                                {selectedCountry.map((row, index) => (
                                    <Col md={4} key={index}>
                                        <p className="text-[#5B5B5B] bg-[#E4E4E4] text-sm p-2.5 my-5" style={{ border: '1px solid #E4E4E4' }}>{row}</p>
                                    </Col>
                                ))}

                            </Row>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

const AssignEditor = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [docloading, setDocLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [type, setType] = useState([])
    const [countries, setCountries] = useState([]);
    const [loadingCC, setCounsellorLoading] = useState(true);
    const [selectValues, setSelectValues] = useState([{ country: '', editor: '', doc_type: '', permission: '' }]);
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const fetchEditorList = async () => {

        await axios.post(api_url + 'application/get_editor_list', {
            country_id: '9'
        }).then((response) => {

            const editor = response.data.editor_list
            const edArray = editor.map(item => ({
                'id': item.id,
                'name': decrypt(item.name, encriptionKey),
            }))

            setOptions(edArray);
            //setType(response.data.document_type);
            setLoading(false);
        })
    }
    const fetchDocumentList = async () => {

        await axios.get(api_url + 'ajax/get_document', {
        }).then((response) => {

            const doc = response.data.document_type
            const docArray = doc.map(item => ({
                'id': item.id,
                'name': item.name,
            }))

            setDocuments(docArray);
            setDocLoading(false);
        })
    }
    useEffect(() => {
        fetchEditorList()
        fetchDocumentList()
        fetchAssignCounsellorList()
        fetchStudentCountryList()
    }, []);


    const fetchStudentCountryList = async () => {

        await axios.post(api_url + 'application/get_student_country_list', {
            user_id: id,
            counsellor_id: user_data.id,
        }).then((response) => {
            setCountries(response.data.country_list);
            setLoading(false);
        })
    }




    const onFinish = () => {
        //console.log(selectValues);return  false;
        const hasBlankValues = selectValues.some((row) => !row.editor);

        if (hasBlankValues) {
            toast.error('Please fill in all the fields before submitting.', {
                position: toast.POSITION.TOP_CENTER
            });
        } else {
            axios.post(api_url + 'application/update_editor', {
                user_id: id,
                assign_by: user_data.id,
                data: selectValues,
            }).then((response) => {

                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    // const timer = setTimeout(() => {
                    //     window.location.reload();
                    // }, 3000);
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            });
        }
    };

    const fetchAssignCounsellorList = () => {
        axios.post(api_url + 'application/get_assign_editor_list', {
            user_id: id,
            country_id: "9",
        })
            .then((response) => {

                if (response.data.data.length > 0) {
                    setSelectValues(response.data.data);
                    if (response.data.options.length > 0) {
                        const doc = response.data.options
                        const docArray = doc.map(item => ({
                            'id': item.id,
                            'name': item.name,
                        }))

                        setDocuments(docArray);
                        //setType(response.data.options);
                    }
                }
                setCounsellorLoading(false);

            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    };

    const handleChange = (value, index, name) => {
        const newSelectValues = [...selectValues];
        if (name === 'country') {
            axios.post(api_url + 'application/get_country_document_type', {
                country_id: value
            }).then((response) => {
                setType(response.data.document_type);
                setLoading(false);
            })
            newSelectValues[index] = {
                ...newSelectValues[index],
                country: value
            };
        }
        else if (name === 'editor') {
            const isUniversitySelected = selectValues
                .filter((row, i) => i !== index && row.editor === newSelectValues[index].editor)
                .some((row) => row.permission === value);
            console.log(isUniversitySelected);

            if (isUniversitySelected) {
                toast.error('This data is already selected in the same country.', {
                    position: toast.POSITION.TOP_CENTER
                });
                return; // Exit the function without updating the state
            }

            newSelectValues[index] = {
                ...newSelectValues[index],
                editor: value
            };
        } else if (name === 'type') {
            newSelectValues[index] = {
                ...newSelectValues[index],
                doc_type: value
            };
        } else if (name === 'permission') {
            const isUniversitySelected = selectValues
                .filter((row, i) => i !== index && row.editor === newSelectValues[index].editor)
                .some((row) => row.permission === value);
            console.log(isUniversitySelected);
            if (isUniversitySelected) {
                toast.error('This data is already selected in the same country.', {
                    position: toast.POSITION.TOP_CENTER
                });
                return; // Exit the function without updating the state
            }
            newSelectValues[index] = {
                ...newSelectValues[index],
                permission: value
            };
        }

        setSelectValues(newSelectValues);
    };

    const handleRemove = (index) => {
        const newSelectValues = [...selectValues];
        newSelectValues.splice(index, 1);
        setSelectValues(newSelectValues);

        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        setOptions(updatedOptions);
    };

    const handleAddMore = () => {
        const lastRow = selectValues[selectValues.length - 1];
        const isLastRowFilled = lastRow.editor !== '' && lastRow.country !== '' && lastRow.permission !== '';

        if (isLastRowFilled) {
            setSelectValues([...selectValues, { country: '', editor: '', doc_type: '', permission: '' }]);
        } else {
            // Display an error message or perform any other action to indicate that the previous fields need to be filled
            toast.error('Please fill the previous fields before adding a new row.', {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };

    return (
        <>
            <Row style={{ border: '1px solid #707070', marginTop: 20 }}>
                <Col md={24}>
                    {loadingCC ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (
                        <Form onFinish={onFinish} name="normal_login" className="my-7 mx-5">
                            <table className='curricular-data'>
                                <thead>
                                    <tr>
                                        <th>Country</th>
                                        <th>Editor</th>
                                        <th>Document Type</th>
                                        <th>Permission</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectValues.map((row, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Select
                                                    value={row.country} // Set the selected value explicitly
                                                    onChange={(value) => handleChange(value, index, 'country')}
                                                    style={{ width: '80%' }}
                                                    name="country"
                                                // Disable the select box if a country is already selected
                                                >
                                                    {row.country === '' && <Select.Option value="">Select</Select.Option>}
                                                    {countries.map((country) => (
                                                        <Select.Option
                                                            key={country.id}
                                                            value={country.id}
                                                        // Disable the option if the country is already selected in any row
                                                        >
                                                            {country.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </td>
                                            <td>

                                                <Select
                                                    placeholder="Select Editor"
                                                    value={row.editor}
                                                    onChange={(value) => handleChange(value, index, 'editor')}
                                                    style={{ width: '80%', height: '40px' }}
                                                    name="editor"

                                                >

                                                    {options.map((editor) => (
                                                        <Select.Option key={editor.id} value={editor.id}
                                                        >
                                                            {editor.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </td>
                                            <td>
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Select Document Type"
                                                    defaultValue={row.doc_type.length > 0 ? row.doc_type : undefined}
                                                    onChange={(value) => handleChange(value, index, 'type')}
                                                    style={{ width: '80%', }}
                                                    name="type"

                                                >
                                                    {/* <Select.Option value="">Select</Select.Option> */}
                                                    {documents.map((typeName) => (
                                                        <Select.Option key={typeName.id} value={typeName.name}>
                                                            {typeName.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </td>
                                            <td>
                                                <Select
                                                    placeholder="Select"
                                                    defaultValue={row.permission}
                                                    onChange={(value) => handleChange(value, index, 'permission')}
                                                    style={{ width: '80%', height: '40px' }}
                                                    name="permission"

                                                >
                                                    <Select.Option value="Reader">Reader</Select.Option>
                                                    <Select.Option value="Writer">Writer</Select.Option>
                                                </Select>
                                            </td>
                                            <td width={20}>
                                                <Popconfirm title="Sure to delete?" onConfirm={() => handleRemove(index)} okButtonProps={{ style: { background: '#2b6494' } }}>
                                                    <a><DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' /></a>
                                                </Popconfirm>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button type="button" className="ant-btn css-dev-only-do-not-override-k83k30 ant-btn-primary text-base text-white bg-[#FCC222] rounded-lg pb-1.5 px-5"
                                onClick={handleAddMore}
                                style={{ float: 'right' }}>
                                Add Row
                            </button>
                            <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button type="primary"
                                    htmlType="submit"
                                    className="text-sm text-white bg-[#0C3762] py-1.5 px-7 mb-5 flex justify-center"
                                    style={{ height: 'auto', border: '1px solid #EEE6E6', borderRadius: 5 }}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Form>
                    )}
                </Col>
            </Row>
        </>
    )
}

const AssignCountryCouncellor = () => {

    const { id } = useParams();
    const [countries, setCountries] = useState([]);
    //const [streamData, setStreamData] = useState([{ id: null, name: 'Select Stream' }]);
    const [loading, setLoading] = useState(true);
    //const [loadingStream, setStreamLoading] = useState(true);
    const [loadingCC, setCounsellorLoading] = useState(true);
    const [options, setOptions] = useState([[{ id: null, name: 'Select Counsellor' }]]);
    const [selectValues, setSelectValues] = useState([{ country: '', stream: '', counsellor: '' }]);
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const onFinish = (values) => {

        const hasBlankValues = selectValues.some((row) => !row.country || row.counsellor.length === 0);

        if (hasBlankValues) {
            toast.error('Please fill in all the fields before submitting.', {
                position: toast.POSITION.TOP_CENTER
            });
        } else {
            axios.post(api_url + 'application/update_country_counsellor', {
                user_id: id,
                assign_by: user_data.id,
                data: selectValues,
            }).then((response) => {

                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            });
        }
    };

    // const fetchStreamList = async () => {

    //     await axios.get(api_url + 'get_stream_list', {
    //     }).then((response) => {

    //         const streams = response.data.stream_list.map(stream => ({ value: stream.id, label: stream.name }));
    //         setStreamData(response.data.stream_list);
    //         //setStreamLoading(false);
    //     })
    // }

    const fetchAssignCounsellorList = () => {
        axios.post(api_url + 'application/get_assign_counsellor_list', {
            user_id: id,
        })
            .then((response) => {

                if (response.data.data.length > 0) {
                    setSelectValues(response.data.data);
                    if (response.data.options.length > 0) {
                        setOptions(response.data.options);
                    }

                    // if (response.data.stream_options.length > 0) {
                    //     setStreamData(response.data.stream_options);
                    // }
                }
                setCounsellorLoading(false);

            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    };
    useEffect(() => {
        fetchCountryList()
        //fetchStreamList()
        fetchAssignCounsellorList()
    }, []);

    const fetchCountryList = async () => {

        await axios.get(api_url + 'get_country_list', {

        }).then((response) => {
            setCountries(response.data.country_list);
            setLoading(false);
        })
    }

    const getCounsellorList = (value, index) => {
        axios
            .post(api_url + 'application/get_country_counsellor_data', {
                country_ids: value,
            })
            .then((response) => {
                const newOptions = response.data.counsellor_list.length > 0 ? response.data.counsellor_list : [{ id: '', name: '' }];

                const updatedOptions = [...options];
                updatedOptions[index] = newOptions;
                setOptions(updatedOptions);

            })

            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    };



    const handleChange = (value, index, name) => {
        const newSelectValues = [...selectValues];
        //console.log(name)
        if (name === 'country') {
            // Check if the selected country is already selected in other rows
            const isCountrySelected = selectValues.some((row, i) => i !== index && row.country === value);

            if (isCountrySelected) {
                toast.error('This country is already selected in another row.', {
                    position: toast.POSITION.TOP_CENTER
                });
                return;
            }

            newSelectValues[index].country = value;
            newSelectValues[index].counsellor = [];

            // Disable the selected country in the options for other rows
            const updatedOptions = options.map((rowOptions, i) => {
                if (i !== index) {
                    return rowOptions.filter((option) => option.id !== value);
                }
                return rowOptions;
            });
            setOptions(updatedOptions);

            if (index === selectValues.length - 1 && value !== '') {
                getCounsellorList(value, index);
            }
        } else if (name === 'counsellor') {
            newSelectValues[index].counsellor = value;
        }
        // else if (name === 'stream') {
        //     newSelectValues[index].stream = value;
        // }



        setSelectValues(newSelectValues);
    };

    const handleRemove = (index) => {
        const newSelectValues = [...selectValues];
        newSelectValues.splice(index, 1);
        setSelectValues(newSelectValues);

        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        setOptions(updatedOptions);

    };

    const handleAddMore = () => {
        const lastRow = selectValues[selectValues.length - 1];
        const isLastRowFilled = lastRow.country !== '' && lastRow.counsellor !== '';

        if (isLastRowFilled) {
            setSelectValues([...selectValues, { country: '', stream: '', counsellor: '' }]);
            setOptions([...options, [{ id: '', name: 'Select Counsellor' }]]);
        } else {
            // Display an error message or perform any other action to indicate that the previous fields need to be filled
            // For example, you can show a toast notification
            toast.error('Please fill the previous fields before adding a new row.', {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };


    return (
        <>
            <Row style={{ border: '1px solid #707070', marginTop: 20 }}>
                <Col md={24}>
                    <h2 className="bg-[#FFEFCC] text-[#0C3762] text-xl p-2.5 font-medium">Assign Countries & Counselors</h2>
                </Col>
                <Col md={24} style={{ marginLeft: 10, marginRight: 10 }}>
                    {loadingCC ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (
                        <>
                            {loading ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (
                                <Form onFinish={onFinish} name="normal_login" className="my-7 mx-5">
                                    <table className='curricular-data'>
                                        <thead>
                                            <tr>
                                                <th>Country</th>
                                                <th>Counsellor</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {selectValues.map((row, index) => (
                                                <tr key={index}>
                                                    <td width={10}>

                                                        <Select
                                                            value={row.country}
                                                            onChange={(value) => handleChange(value, index, 'country')}
                                                            style={{ width: '80%' }}
                                                            name="country"
                                                            disabled={row.country !== ''}
                                                        >
                                                            {row.country === '' && <Select.Option value="">Select</Select.Option>}
                                                            {countries.map((country) => (
                                                                <Select.Option
                                                                    key={country.id}
                                                                    value={country.id}
                                                                    disabled={selectValues.some((r) => r.country === country.id)}
                                                                >
                                                                    {country.name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </td>
                                                    {/* <td width={30}>
                                                        <Select
                                                            placeholder="Select Stream"
                                                            mode="multiple"
                                                            defaultValue={row.stream.length > 0 ? row.stream : undefined}
                                                            onChange={(value) => handleChange(value, index, 'stream')}
                                                            style={{ width: '80%' }}
                                                            name="stream"
                                                        >
                                                            {row.country && streamData.map((option) => (
                                                                <Select.Option key={option.id} value={option.name}>
                                                                    {option.name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </td> */}
                                                    <td width={30}>

                                                        <Select
                                                            placeholder="Select Counsellor"
                                                            mode="multiple"
                                                            defaultValue={row.counsellor.length > 0 ? row.counsellor : undefined}
                                                            onChange={(value) => handleChange(value, index, 'counsellor')}
                                                            style={{ width: '80%' }}
                                                            name="counsellor"
                                                        >
                                                            {row.country && options[index].map((option) => (
                                                                <Select.Option key={option.id} value={option.name}>
                                                                    {option.name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </td>

                                                    <td width={20}>
                                                        <Popconfirm title="Sure to delete?" onConfirm={() => handleRemove(index)} okButtonProps={{ style: { background: '#2b6494' } }}>
                                                            <a><DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' /></a>
                                                        </Popconfirm>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <button type="button" className="ant-btn css-dev-only-do-not-override-k83k30 ant-btn-primary text-base text-white bg-[#FCC222] rounded-lg pb-1.5 px-5"
                                        onClick={handleAddMore}
                                        style={{ float: 'right' }}>
                                        Add Row
                                    </button>

                                    <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button type="primary"
                                            htmlType="submit"
                                            className="text-sm text-white bg-[#0C3762] py-1.5 px-7 mb-5 flex justify-center"
                                            style={{ height: 'auto', border: '1px solid #EEE6E6', borderRadius: 5 }}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Form>
                            )}

                        </>
                    )}
                </Col>
            </Row>
        </>
    )
}

const AssignUniversity = () => {

    const { id } = useParams();
    const [countries, setCountries] = useState([]);
    const [editors, setEditors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingEE, setDDLoading] = useState(true);
    const [loadingCC, setCCLoading] = useState(true);
    const [streamData, setStreamData] = useState([]);
    const [selectValues, setSelectValues] = useState([{ country: '', university: '', stream: '', essay: false, editor: '' }]);
    const [options, setOptions] = useState([[{ id: null, name: 'Select University' }]]);


    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    //console.log(user_data);
    const onFinish = () => {
        const hasBlankValues = selectValues.some((row) => !row.country || row.university.length === 0);

        if (hasBlankValues) {
            toast.error('Please fill in all the fields before submitting.', {
                position: toast.POSITION.TOP_CENTER
            });
        } else {
            axios.post(api_url + 'application/update_student_university', {
                user_id: id,
                assign_by: user_data.id,
                record: selectValues,
            }).then((response) => {

                if (response.data.status) {

                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
        }
    };

    const fetchStreamList = async () => {

        await axios.get(api_url + 'get_stream_list', {
        }).then((response) => {

            const streams = response.data.stream_list.map(stream => ({ value: stream.id, label: stream.name }));
            setStreamData(response.data.stream_list);
            //setStreamLoading(false);
        })
    }

    const fetchEditorList = async () => {

        await axios.post(api_url + 'application/get_editor_list', {
        }).then((response) => {

            const editor = response.data.editor_list
            const edArray = editor.map(item => ({
                'id': item.id,
                'name': decrypt(item.name, encriptionKey),
            }))

            setEditors(edArray);
            setDDLoading(false);
        })
    }


    useEffect(() => {
        if (user_data.counsellor_type === "Country Counsellor Head") {

            axios.get(api_url + 'get_country_list', {

            }).then((response) => {
                //console.log(response)
                setCountries(response.data.country_list);
                setLoading(false);
            })

        }

        if (user_data.counsellor_type === "Country Counsellor") {
            axios.post(api_url + 'application/get_student_country_list', {
                user_id: id,
                counsellor_id: user_data.id,
            }).then((response) => {
                setCountries(response.data.country_list);
                setLoading(false);
            })
        }
        fetchEditorList()
        fetchStreamList()
        fetchStudentUniversityList()
    }, []);

    const getUniversityList = (value, index) => {
        axios
            .post(api_url + 'get_university_list ', {
                country_id: value,
            })
            .then((response) => {
                //console.log(response.data)

                const newOptions = response.data.university_list.length > 0 ? response.data.university_list : [{ id: '', name: '' }];

                const updatedOptions = [...options];
                updatedOptions[index] = newOptions;
                setOptions(updatedOptions);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    };

    const fetchStudentUniversityList = () => {
        axios
            .post(api_url + 'application/get_country_university_data ', {
                user_id: id,
                counsellor_id: user_data.id,
            })
            .then((response) => {

                if (response.data.record) {
                    setSelectValues(response.data.record);
                    if (response.data.option.length > 0) {
                        setOptions(response.data.option);
                    }
                }
                setCCLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    };

    const [universityInfo, setUniversityInfo] = useState('')
    const [modalVisible, setModalVisible] = useState(false)

    const handleChange = (value, index, name) => {
        const newSelectValues = [...selectValues];

        if (name === 'country') {
            newSelectValues[index].country = value;
            newSelectValues[index].university = []; // Reset the university selection when the country changes
            if (index === selectValues.length - 1 && value !== '') {
                getUniversityList(value, index);
            }
        } else if (name === 'university') {
            // Check if the selected university is already selected in the same country
            const isUniversitySelected = selectValues
                .filter((row, i) => i !== index && row.country === newSelectValues[index].country)
                .some((row) => row.university === value);

            if (isUniversitySelected) {
                toast.error('This university is already selected in the same country.', {
                    position: toast.POSITION.TOP_CENTER
                });
                return; // Exit the function without updating the state
            }

            newSelectValues[index].university = value;


            axios.post(api_url + 'ajax/get_university_info', {
                university_id: value

            }).then((res) => {

                setUniversityInfo(res.data.university_info)
                setModalVisible(true)

            })
        } else if (name === 'essay') {
            newSelectValues[index].essay = value; // Update the checkbox value
        } else if (name === 'editor') {
            newSelectValues[index].editor = value; // Update the checkbox value
        }
        else if (name === 'stream') {
            newSelectValues[index].stream = value;
        }

        setSelectValues(newSelectValues);
    };


    const handleAddMore = () => {
        const lastRow = selectValues[selectValues.length - 1];
        const isLastRowFilled = lastRow.country !== '' && lastRow.counsellor !== '';
        if (isLastRowFilled) {
            setSelectValues([...selectValues, { country: '', university: '', stream: '', essay: '', editor: '' }]);
            setOptions([...options, [{ id: '', name: 'Select University' }]]);
        } else {
            // Display an error message or perform any other action to indicate that the previous fields need to be filled
            toast.error('Please fill the previous fields before adding a new row.', {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };


    const handleRemove = (index) => {
        const newSelectValues = [...selectValues];
        newSelectValues.splice(index, 1);
        setSelectValues(newSelectValues);

        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        setOptions(updatedOptions);
    };

    return (
        <>
            <Row style={{ border: '1px solid #707070', marginTop: 20 }}>
                <Col md={24}>
                    <h2 className="bg-[#FFEFCC] text-[#0C3762] text-xl p-2.5 font-medium">Assign Universities</h2>
                </Col>
                {loadingCC ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (
                    <Col md={24}>
                        <Form onFinish={onFinish} name="normal_login" className="my-7 mx-5">
                            <table className='curricular-data'>
                                <thead>
                                    <tr>
                                        {user_data.counsellor_type === 'Country Counsellor Head' || user_data.counsellor_type === 'Country Counsellor' ? (<th>Country</th>) : ('')}

                                        <th>University</th>
                                        <th>Stream</th>
                                        <th>University Specific Essay</th>
                                        {user_data.counsellor_type === 'Country Counsellor Head' || user_data.counsellor_type === 'Country Counsellor' ? ('') : (<th>Editor</th>)}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {selectValues.map((row, index) => (
                                        <tr key={index}>
                                            {user_data.counsellor_type === 'Country Counsellor Head' || user_data.counsellor_type === 'Country Counsellor' ? (<td>
                                                <Select
                                                    defaultValue={row.country}
                                                    onChange={(value) => handleChange(value, index, 'country')}
                                                    style={{ width: '80%' }}
                                                    name="country"
                                                >
                                                    <Select.Option value="">Select</Select.Option>
                                                    {countries.map((country) => (
                                                        <Select.Option key={country.id} value={country.id}>
                                                            {country.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </td>) : ('')}

                                            <td>

                                                <Select
                                                    placeholder="Select University"
                                                    defaultValue={row.university > 0 ? row.university : undefined}
                                                    onChange={(value) => handleChange(value, index, 'university')}
                                                    style={{ width: '80%', height: '40px' }}
                                                    name="university"
                                                >
                                                    {row.country && options[index].map((option) => ( // Render options only when country is selected
                                                        <Select.Option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </td>
                                            <td width={30}>
                                                <Select
                                                    placeholder="Select Stream"
                                                    defaultValue={row.stream}
                                                    onChange={(value) => handleChange(value, index, 'stream')}
                                                    style={{ width: '80%' }}
                                                    name="stream"
                                                >
                                                    {row.country && streamData.map((option) => (
                                                        <Select.Option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    className="uni"
                                                    name="essay"
                                                    checked={row.essay}
                                                    onChange={(event) => handleChange(event.target.checked, index, 'essay')}
                                                />
                                            </td>
                                            {user_data.counsellor_type === 'Country Counsellor Head' || user_data.counsellor_type === 'Country Counsellor' ? ('') : (<td>

                                                <Select
                                                    placeholder="Select Editor"
                                                    value={row.editor}
                                                    onChange={(value) => handleChange(value, index, 'editor')}
                                                    style={{ width: '80%', height: '40px' }}
                                                    name="editor"
                                                >

                                                    {editors.map((editor) => (
                                                        <Select.Option key={editor.id} value={editor.id}
                                                        >
                                                            {editor.name}
                                                        </Select.Option>
                                                    ))}

                                                </Select>

                                            </td>)}

                                            <td width={20}>
                                                <Popconfirm title="Sure to delete?" onConfirm={() => handleRemove(index)} okButtonProps={{ style: { background: '#2b6494' } }}>
                                                    <a><DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' /></a>
                                                </Popconfirm>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <button type="button" className="ant-btn css-dev-only-do-not-override-k83k30 ant-btn-primary text-base text-white bg-[#FCC222] rounded-lg pb-1.5 px-5"
                                onClick={handleAddMore}
                                style={{ float: 'right' }}>
                                Add Row
                            </button>

                            <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button type="primary"
                                    htmlType="submit"
                                    className="text-sm text-white bg-[#0C3762] py-1.5 px-7 mb-5 flex justify-center"
                                    style={{ height: 'auto', border: '1px solid #EEE6E6', borderRadius: 5 }}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Form>
                        <Modal title="University Information"
                            footer={null}
                            open={modalVisible}
                            onOk={() => setModalVisible(false)}
                            onCancel={() => setModalVisible(false)}>
                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>QS Ranking 2024</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.qs_rank_2024}</p>
                                </Col>
                            </Row>

                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>QS Ranking 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.rank}</p>
                                </Col>
                            </Row>

                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>QS Engg & Technology Ranking 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.qs_engg_tech_rank_2023}</p>
                                </Col>
                            </Row>
                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>QS Physics & Astronomy Ranking 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.qs_phy_asto_rank_2023}</p>
                                </Col>
                            </Row>

                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>QS Mechnical Ranking 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.qs_mech_rank_2023}</p>
                                </Col>
                            </Row>
                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>QS Medicine Ranking 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.qs_medicine_rank_2023}</p>
                                </Col>
                            </Row>

                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>QS Biological Science Ranking 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.qs_bio_science_rank_2023}</p>
                                </Col>
                            </Row>
                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>Early Action Deadline 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.early_deadline}</p>
                                </Col>
                            </Row>

                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>Early Decision Deadline 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.early_decision_deadlin}</p>
                                </Col>
                            </Row>

                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>Regular Action Deadline 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.regular_action_deadline}</p>
                                </Col>
                            </Row>

                            <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                <Col md={12}>
                                    <strong className='text-[#2A6496]'>Regular Decision Deadline 2023</strong>
                                </Col>
                                <Col md={12}>
                                    <p className='text-[#9B9B9B]'>{universityInfo.regular_decision_deadline}</p>
                                </Col>

                            </Row>
                            {/* <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong>QS CS Rank 2023</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <span className='text-[#84acd4]'>{universityInfo.qs_cs_rank_2023}</span>
                                                    </Col>
                                                </Row>

                                                <Row gutter={30} style={{ borderBottom: ' 1px solid #FFEFCC' }}>
                                                    <Col md={12}>
                                                        <strong>Regular action deadline</strong>
                                                    </Col>
                                                    <Col md={12}>
                                                        <span className='text-[#84acd4]'>{universityInfo.regular_action_deadline}</span>
                                                    </Col>
                                                </Row> */}
                        </Modal>

                    </Col>
                )}

            </Row>
        </>
    )
}

const UniFinalization = () => {

    const { id } = useParams();
    const [myCountry, setMyCountry] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [universitiesForCountry, setUniversitiesForCountry] = useState(null);
    //const [tableData, setTableData] = useState([{ dream: '', action: '' }]);

    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;


    const fetchData = async () => {
        try {
            const res = await axios.post(api_url + 'application/university_finalization', {
                user_id: id,
            });

            //console.log("API response:", res.data);

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user');
                    window.location.href = '/';
                    return; // Early return to avoid further execution
                }
            }

            // Convert the object with numeric keys to an array
            const counArray = Object.values(res.data.data).map(item => ({
                'country_id': item.country_id,
                'country': item.country,
            }));

            const uni_list = Object.values(res.data.data).map(item => ({
                'university': item.university,
            }));

            setMyCountry(counArray);
            // const list = uni_list.map((item) => ({

            // })
            // )
            setUniversities(uni_list[0].university);
            console.log(uni_list[0].university)
            // console.log(uni_list[1].university)
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData()
    }, []);

    const handleCountry = async (country_id) => {
        try {
            const res = await axios.post(api_url + 'application/university_finalization', {
                user_id: id,
                country_id: country_id
            });


            console.log("API response:", res.data);


            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user');
                    window.location.href = '/';
                    return; // Early return to avoid further execution
                }
            }

            // Convert the object with numeric keys to an array
            // const counArray = Object.values(res.data.data).map(item => ({
            //     'country_id': item.country_id,
            //     'country': item.country,
            // }));

            const uni_list = Object.values(res.data.data).map(item => ({
                'university': item.university,
            }));

            //setMyCountry(counArray);
            // const list = uni_list.map((item) => ({

            // })
            // )
            setUniversities(uni_list[0].university);

            // console.log(uni_list[1].university)
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    }

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        setUniversities((universities) =>
            universities.map((uni, idx) =>
                idx === index ? { ...uni, [name]: value } : uni
            )
        );
    };



    const handleSubmit = (values) => {

        axios.post(api_url + 'application/update_finalize_university', {
            created_by: user_data.id,
            record: universities,
        }).then((response) => {

            if (response.data.status) {

                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                const timer = setTimeout(() => {
                    window.location.reload();
                }, 3000);

            } else {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

            }
        })
    };




    return (
        <>
            <Row>
                <Col md={24}>
                    <Row gutter={30}>

                        {loading ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (

                            myCountry.map((row, index) => (
                                <Col md={6}>
                                    <div className="tabs-btn-row" key={index}>

                                        <div>
                                            <Button
                                                className='text-base active rounded-lg flex justify-center items-center py-5 px-16'
                                                onClick={() => handleCountry(row.country_id)}>
                                                {row.country}
                                            </Button>
                                        </div>

                                    </div>
                                </Col>
                            ))
                        )}

                    </Row>


                    {/* <div className="row-span-1 studymajor"><strong>Study Major</strong> <span>Stream1</span> <span>Stream2</span></div> */}
                    {loading ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (

                        universities.length > 0 ? (
                            <Form className="mx-5" onFinish={handleSubmit}>
                                <div className="table-wrapper ">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>University</th>
                                                <th>Stream</th>
                                                <th>Dream</th>
                                                <th>Reach</th>
                                                <th>Safe</th>
                                                {universities[0].country === 5 ? (<><th className="action">Early Action</th>
                                                    <th className="action">Regular Action</th></>) : ('')}

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {universities.map((uni, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{uni.name}</td>
                                                    <td>{uni.stream}</td>
                                                    <td colSpan={3} style={{ padding: '0px' }}>

                                                        <Radio.Group
                                                            onChange={(e) => handleChange(e, index)}
                                                            value={uni.targets} // Set the selected value from the universities state
                                                            style={{ width: '100%' }}

                                                            name="targets" // Use unique name for each group
                                                        >
                                                            <div className="columnsr">
                                                                <Radio value={1}></Radio>
                                                                <Radio value={2}></Radio>
                                                                <Radio value={3}></Radio>
                                                            </div>
                                                        </Radio.Group>
                                                    </td>
                                                    {uni.country === 5 ? (<td className="action" colSpan={2}>
                                                        <Radio.Group
                                                            onChange={(e) => handleChange(e, index)}
                                                            value={uni.actions} // Set the selected value from the universities state
                                                            name="actions" // Use unique name for each group
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className="columnstwo">
                                                                <Radio value={1}></Radio>
                                                                <Radio value={2}></Radio>
                                                            </div>
                                                        </Radio.Group>
                                                    </td>) : ('')}

                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex justify-center items-center">
                                    <Button type="primary"
                                        htmlType="submit"
                                        className="text-sm text-white bg-[#0C3762] py-1.5 px-7  flex justify-center"
                                        style={{ height: 'auto', border: '1px solid #EEE6E6', borderRadius: 20 }}
                                    >
                                        Save
                                    </Button>
                                </div>

                            </Form>
                        ) : (
                            <div>No universities selected for the country.</div>
                        )
                    )}

                </Col>
            </Row>
        </>
    )
}

const GiqInfo = () => {
    const { id } = useParams();
    const [questions, setQuestions] = useState([{ qid: "", question: "", answer: "" }])
    const [loading, setLoading] = useState(true)


    const fetchUserData = async () => {

        await axios.post(api_url + 'get_giq_form_data', {
            user_id: id,

        }).then((res) => {
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const giq_data = res.data.giq_data;
            let obj = JSON.parse(giq_data);
            //console.log(obj);
            setQuestions(obj);
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    return (
        <>
            <div className="mx-0">
                {questions.map((ques, index) => (
                    <div key={index}>
                        <div className='py-5'>
                            <strong className="text-[#6c757d] text-base">{ques.question}</strong>
                            <p className="text-[#6c757d] text-base">{ques.answer}</p>
                        </div>
                    </div>
                ))}

            </div>
        </>
    )
}

const ProfileInfo = () => {

    const infoItems = [
        {
            key: '1',
            label: `Academic Records`,
            children: <AcademicInfo />,
        },
        {
            key: '2',
            label: `Co-Curricular`,
            children: <CurricularInfo />,
        },
        {
            key: '3',
            label: `Research Records`,
            children: <ResearchInfo />,
        },
        {
            key: '4',
            label: `Awards/Honours`,
            children: <AwardsInfo />,
        },
        {
            key: '5',
            label: `Internships`,
            children: <InternshipsInfo />,
        },
        {
            key: '6',
            label: `Publications`,
            children: <PublicationsInfo />,
        },
        {
            key: '7',
            label: `Community Services`,
            children: <CommunityInfo />,
        },
        {
            key: '8',
            label: `School Projects`,
            children: <SchoolProjectInfo />,
        },
        {
            key: '9',
            label: `Other Activities`,
            children: <OtherInfo />,
        },
    ];
    return (
        <div className="my-5">
            <Tabs
                defaultActiveKey="1"
                type="card"
                items={infoItems}
            />
        </div>
    )
}

const AcademicInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [academicData, setAcademicData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "" }]);

    const fetchAcademicData = async () => {
        await axios.post(api_url + 'user/get_student_records', {
            user_id: id,
        }).then((res) => {
            let academic_data = res.data.academic_data;
            //console.log(academic_data);
            if (academic_data) {
                setAcademicData(JSON.parse(academic_data));
            }

            setLoading(false)
        })
    }

    useEffect(() => {

        fetchAcademicData()
    }, [])
    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Marks</th>
                        <th>Rank</th>
                    </tr>
                </thead>
                <tbody>
                    {academicData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.marks}</td>
                                <td>{row.rank}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const CurricularInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [curricularData, setCurricularData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", learning: "" }]);

    const fetchCurricularData = async () => {
        await axios.post(api_url + 'user/get_student_records', {
            user_id: id,
        }).then((res) => {
            let curricular_data = res.data.curricular_data;

            if (curricular_data) {
                let cur_records = JSON.parse(curricular_data);
                setCurricularData(cur_records);
            }

            setLoading(false)
        })
    }

    useEffect(() => {
        fetchCurricularData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Marks</th>
                        <th>Learning</th>
                    </tr>
                </thead>
                <tbody>
                    {curricularData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.marks}</td>
                                <td>{row.learning}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const ResearchInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [researchData, setResearchData] = useState([{ year: "", class: "", activity: "", published: "", role: "", reference_link: "", level: "", outcome: "", learning: "", patent: "" }]);

    const fetchResearchData = async () => {
        await axios.post(api_url + 'user/get_student_records', {
            user_id: id,
        }).then((res) => {
            let research_data = res.data.research_data;

            if (research_data) {
                setResearchData(JSON.parse(research_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchResearchData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of project work</th>
                        <th>Your Role</th>
                        <th>Published</th>
                        <th>Level</th>
                        <th>Reference Link</th>
                        <th>Your Learning</th>
                        <th>Implementation Outcome</th>
                        <th>Patent</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {researchData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.role}</td>
                                <td>{row.published}</td>
                                <td>{row.level}</td>
                                <td>{row.reference_link}</td>
                                <td>{row.learning}</td>
                                <td>{row.outcome}</td>
                                <td>{row.patent}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={12} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const AwardsInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [awardsData, setAwardsData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);

    const fetchAwardData = async () => {
        await axios.post(api_url + 'user/get_student_records', {
            user_id: id,
        }).then((res) => {
            let awards_data = res.data.awards_data;

            if (awards_data) {
                setAwardsData(JSON.parse(awards_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchAwardData()
    }, [])
    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Remarks</th>

                    </tr>
                </thead>
                <tbody>
                    {awardsData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.rank}</td>
                                <td>{row.remarks}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const InternshipsInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [internsData, setinternsData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);

    const fetchInternsData = async () => {
        await axios.post(api_url + 'user/get_student_records', {
            user_id: id,
        }).then((res) => {
            let interns_data = res.data.interns_data;

            if (interns_data) {
                setinternsData(JSON.parse(interns_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchInternsData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {internsData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.rank}</td>
                                <td>{row.remarks}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const PublicationsInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [publicationsData, setpublicationsData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);

    const fetchPublicationData = async () => {
        await axios.post(api_url + 'user/get_student_records', {
            user_id: id,
        }).then((res) => {
            let publication_data = res.data.publication_data;

            if (publication_data) {
                setpublicationsData(JSON.parse(publication_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchPublicationData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {publicationsData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.rank}</td>
                                <td>{row.remarks}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const CommunityInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [communityData, setCommunityData] = useState([{ year: "", class: "", activity: "", type: "", role: "", hours: "", outcome: "", learning: "", patent: "" }]);

    const fetchCommunityData = async () => {
        await axios.post(api_url + 'user/get_student_records', {
            user_id: id,
        }).then((res) => {
            let community_data = res.data.community_data;

            if (community_data) {
                setCommunityData(JSON.parse(community_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchCommunityData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>Sno</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity	</th>
                        <th>Type</th>
                        <th>Role</th>
                        <th>Hours Devoted</th>
                        <th>Final Outcome</th>
                        <th>Your Learning</th>
                        <th>Patent</th>
                    </tr>
                </thead>
                <tbody>
                    {communityData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.hours}</td>
                                <td>{row.outcome}</td>
                                <td>{row.learning}</td>
                                <td>{row.patent}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={10} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const SchoolProjectInfo = () => {
    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>01</td>
                        <td>2020</td>
                        <td>10th</td>
                        <td>-</td>
                        <td>Achivement</td>
                        <td>Participant</td>
                        <td>School</td>
                        <td>First</td>
                        <td>ddf</td>

                    </tr>
                </tbody>
            </table>
        </>
    )
}

const OtherInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [othersData, setOthersData] = useState([{ year: "", class: "", activity: "", type: "", role: "", hours: "", outcome: "", learning: "", patent: "" }]);

    const fetchothersActivityData = async () => {
        await axios.post(api_url + 'user/get_student_records', {
            user_id: id,
        }).then((res) => {
            let other_data = res.data.other_data;

            if (other_data) {
                setOthersData(JSON.parse(other_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchothersActivityData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Hours Devoted</th>
                        <th>Final Outcome</th>
                        <th>Your Learning</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {othersData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.hours}</td>
                                <td>{row.outcome}</td>
                                <td>{row.learning}</td>
                                <td>{row.patent}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={10} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const TestInfo = () => {
    const examinfo = [
        {
            key: '1',
            label: `SAT`,
            children: <SatInfo />,
        },
        {
            key: '2',
            label: `ACT`,
            children: <ActInfo />,
        },
        {
            key: '3',
            label: `IELTS`,
            children: <IeltsInfo />,
        },
        {
            key: '4',
            label: `TOEFL`,
            children: <ToeflInfo />,
        },
        {
            key: '5',
            label: `Dulingo`,
            children: <DulingoInfo />,
        },
        {
            key: '6',
            label: `PTE`,
            children: <PteInfo />,
        },
        {
            key: '7',
            label: `Cambridge`,
            children: <CambridgeInfo />,
        },
    ];
    return (
        <div className="my-5">
            <Tabs
                defaultActiveKey="1"
                type="card"
                items={examinfo}
            />
        </div>
    )

}

const SatInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [satData, setSatData] = useState([{ attempt: "", score: "", maths: "", english: "" }]);

    const fetchSatData = async () => {
        await axios.post(api_url + 'user/get_test_data', {
            user_id: id,
        }).then((res) => {
            let sat_data = res.data.sat_data;

            if (sat_data) {
                setSatData(JSON.parse(sat_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchSatData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Attempt</th>
                        <th>Total Score</th>
                        <th>English Score</th>
                        <th>Math Score</th>
                    </tr>
                </thead>
                <tbody>

                    {satData.map((row, index) => (
                        row.score > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.attempt}</td>
                                <td>{row.score}</td>
                                <td>{row.english}</td>
                                <td>{row.maths}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={5} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

const ActInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [actData, setActData] = useState([{ attempt: "", score: "", maths: "", english: "", science: "", reading: "", writing: "" }]);
    const fetchActData = async () => {
        await axios.post(api_url + 'user/get_test_data', {
            user_id: id,
        }).then((res) => {
            let act_data = res.data.act_data;

            if (act_data) {
                setActData(JSON.parse(act_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchActData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Attempt</th>
                        <th>Composite Score</th>
                        <th>Math Score</th>
                        <th>Science Score</th>
                        <th>English Score</th>
                        <th>Reading Score</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {actData.map((row, index) => (
                        row.score != "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.attempt}</td>
                                <td>{row.score}</td>
                                <td>{row.maths}</td>
                                <td>{row.science}</td>
                                <td>{row.english}</td>
                                <td>{row.reading}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={8} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

const IeltsInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [IeltsData, setIeltsData] = useState([{ over_all_score: "", listening_score: "", reading: "", writing: "" }]);
    const fetchIeltsData = async () => {
        await axios.post(api_url + 'user/get_test_data', {
            user_id: id,
        }).then((res) => {
            let ielts_data = res.data.ielts_data;

            if (ielts_data) {
                setIeltsData(JSON.parse(ielts_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchIeltsData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Over All Band Score</th>
                        <th>Listening Score</th>
                        <th>Reading Score</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {IeltsData.map((row, index) => (
                        row.over_all_score != "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.listening_score}</td>
                                <td>{row.reading}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={5} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}
                </tbody>
            </table>
        </>
    )
}

const ToeflInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [ToeflData, setToeflData] = useState([{ over_all_score: "", listening_score: "", reading: "", writing: "", speaking: "" }]);
    const fetchToeflData = async () => {
        await axios.post(api_url + 'user/get_test_data', {
            user_id: id,
        }).then((res) => {
            let toefl_data = res.data.toefl_data;

            if (toefl_data) {
                setToeflData(JSON.parse(toefl_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchToeflData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Total Score</th>
                        <th>Listening Score</th>
                        <th>Reading Score</th>
                        <th>Speaking Score</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {ToeflData.map((row, index) => (
                        row.over_all_score != "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.listening_score}</td>
                                <td>{row.reading}</td>
                                <td>{row.speaking}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={6} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

const DulingoInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [DulingoData, setDulingoData] = useState([{ over_all_score: "", conversation: "", comprehension: "", literacy: "", production: "" }]);
    const fetchDulingoData = async () => {
        await axios.post(api_url + 'user/get_test_data', {
            user_id: id,
        }).then((res) => {
            let dulingo_data = res.data.dulingo_data;

            if (dulingo_data) {
                setDulingoData(JSON.parse(dulingo_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchDulingoData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Overall Score</th>
                        <th>Conversation</th>
                        <th>Comprehension</th>
                        <th>Literacy</th>
                        <th>Production</th>
                    </tr>
                </thead>
                <tbody>
                    {DulingoData.map((row, index) => (
                        row.over_all_score != "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.conversation}</td>
                                <td>{row.comprehension}</td>
                                <td>{row.literacy}</td>
                                <td>{row.production}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={6} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

const PteInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [PteData, setPteData] = useState([{ over_all_score: "", listening_score: "", reading: "", writing: "", speaking: "" }]);
    const fetchPteData = async () => {
        await axios.post(api_url + 'user/get_test_data', {
            user_id: id,
        }).then((res) => {
            let pte_data = res.data.pte_data;

            if (pte_data) {
                setPteData(JSON.parse(pte_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchPteData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Total Score</th>
                        <th>Listening Score</th>
                        <th>Reading Score</th>
                        <th>Speaking Score</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {PteData.map((row, index) => (
                        row.over_all_score != "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.listening_score}</td>
                                <td>{row.reading}</td>
                                <td>{row.speaking}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={6} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}
                </tbody>
            </table>
        </>
    )
}

const CambridgeInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [CambrigeData, setCambrigeData] = useState([{ over_all_score: "", use_english: "", reading: "", writing: "" }])
    const fetchCambridgeData = async () => {
        await axios.post(api_url + 'user/get_test_data', {
            user_id: id,
        }).then((res) => {
            let cambrige_data = res.data.cambrige_data;

            if (cambrige_data) {
                setCambrigeData(JSON.parse(cambrige_data));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchCambridgeData()
    }, [])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Overall Score</th>
                        <th>Reading Score</th>
                        <th>Use of English</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {CambrigeData.map((row, index) => (
                        row.over_all_score != "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.reading}</td>
                                <td>{row.use_english}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={5} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

export default StudentInfo