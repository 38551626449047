import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space, Layout, Modal, Form } from "antd";
import DashboardLayout from '../components/dashboard-layout';
import { Link, useNavigate } from 'react-router-dom'
import { api_url, studentPath } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';
import Swal from 'sweetalert2';
const { Search } = Input;
const { Content } = Layout;

const AssessmentList = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    //const [loading, setLoading] = useState(true);
    const [assessmentList, setAssessmentList] = useState([]);
    const [materialList, setMatrialList] = useState([]);
    const [studyModalVisible, setStudyModalVisible] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const fetchAssessmentData = async () => {
        await axios.post(api_url + 'test/user_login', {
            user_id: user_data.id,
        }).then((res) => {
            
            if(res.data.status){
                const newWindow = window.open(res.data.url, '_blank');
                if (newWindow) {
                    newWindow.focus();
                }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: "You are not authorized.",
                }).then(() => {
                    // Redirect to another page after closing the error alert
                    window.location.href = '/student-dashboard/dashboard'; // Replace with the desired URL
                });
            }
           
        });
    }

    const viewContent = (record) => {
        console.log(record);return false;
        navigate('/student-dashboard/content/' + record.id)

    };

    useEffect(() => {
        fetchAssessmentData()
    }, [])


    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
        },

        {
            key: "3",
            title: "Id",
            dataIndex: "id",
            align: 'center'
        },
        {
            key: "4",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "5",
            title: "Created Date",
            dataIndex: "created_date",
            align: 'center'
        },

        {
            key: "7",
            title: "Action",
            render: (record) => {
                return (
                    <>
                        <Space>
                            <Button onClick={() => {
                                viewContent(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Contents</Button>

                        </Space>
                    </>
                );
            },
        },
    ];


    return (
        <DashboardLayout>
           

        </DashboardLayout>
    )
}

export default AssessmentList