import React, { useRef,useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import {Input, Layout } from "antd";
import DashboardLayout from "../components/dashboard-layout";
import { useNavigate,useParams } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import ReactPlayer from 'react-player';
import { ToastContainer, toast } from 'react-toastify';
import videojs from 'video.js';
const { Search } = Input;
const { Content } = Layout;


const ViewClass = () => {
    const { meetingid } = useParams();
    const [recordUri, setUrl] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
         
        axios.post(api_url + 'ajax/get_recording', {
            meeting_id: meetingid

            }).then((res) => {
                if (res.data.status) {
                  
                    const recordingUrl = decrypt(res.data.uri,encriptionKey);
                    setUrl(recordingUrl);

                } else {
                    toast.error("Reording not avaliable this time please try after sometime !", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        navigate("/student-dashboard/my-classes-new");
                    }, 3000);

                    
                }
               
            })
          
    }, [])
    // const recordingUrl =
    //     'https://zoom.us/rec/download/PNLECS8Rs-a-YxWXp1HrI6ers4prZeoyzehuLxJhZWt1OshTj6YzKUNp2bvxL29qo123qxZnc-qlS1QG.a69Nk6GV9SVo0DVL';
    // const passcode = 'O+8^uy?x';
    const playerConfig = {
        file: {
            attributes: {
                controlsList: 'nodownload', // Prevents the download button
            },
        },
    };
    return (
        <DashboardLayout>
                <Content
                    className="site-layout-background lg:p-7 md:p-5"
                    style={{
                        margin: '20px 16px 24px',
                        background: '#fff',
                        width: '100%'
                    }}
                >
        <div className="video-container">
            <ReactPlayer
                crossorigin 
                url={`${recordUri}`}
                width="90%"
                height="75%"
                config={playerConfig}
                controls={true}
            />
        </div>
        </Content>
        </DashboardLayout>
    );
};


export default ViewClass