import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form, Input } from 'antd';
import Header from '.././components/header';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import '../styles/form.css';
import '../styles/responsive.css';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url } from '../components/constants';
const { TextArea } = Input;


const IntelligenceQuotient = () => {
    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = useState(false);
    const [questions, setQuestions] = useState([{ qid: "", question: "", answer: "" }])
    const [loading, setLoading] = useState(true)

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const fetchUserData = async () => {
        pushPage('general-enquiry');
        await axios.post(api_url + 'get_giq_form_data', {
            user_id: user_data.id,

        }).then((res) => {
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const giq_data = res.data.giq_data;
            let obj = JSON.parse(giq_data);

            setQuestions(obj);
            setLoading(false)
        })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchUserData()
        }, 1000);
    }, [])

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const result = await axios.post(api_url+'get_giq_form_data');
    //         setQuestions(result.data.questions);
    //     };

    //     fetchData();
    // }, []);


    const handleChange = (i, e) => {

        let newFormValues = [...questions];
        newFormValues[i]['answer'] = e.target.value;
        setQuestions(newFormValues);

    };

    const handleSubmit = () => {

        axios.post(api_url + 'user/update_giq_records', {
            user_id: user_data.id,
            type: 'GIQ',
            records: questions,
        })
            .then(result => {
                if (result.data.status) {

                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => {
                        navigate("/student-portal/student-profile-report")
                    }, 3000);

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something went wrong'
                    });
                }
                // setShowSuccess(true);
                // setTimeout(() => setShowSuccess(false), 3000);
                // // console.log(result);
                // // return false;
                // navigate("/student-portal/student-profile-report")

                // let visitedPages = JSON.parse(sessionStorage.getItem('visitedPages'));
                // visitedPages.push('general-intelligence-quotient');
                // console.log('visitedPages pushed', JSON.stringify(visitedPages))
                // sessionStorage.setItem('visitedPages', JSON.stringify(visitedPages));
                // console.log('visitedPages 1', visitedPages);


            })
        //navigate("/student-portal/student-profile-report")

        // let visitedPages = JSON.parse(sessionStorage.getItem('visitedPages'));
        // console.log('visitedPages 2', visitedPages)
        // visitedPages.push('student-profile-report');

        // sessionStorage.setItem('visitedPages', JSON.stringify(visitedPages));

    }


    const pushPage = (pgKey) => {
        // console.log('page', pgKey);
        let visitedPages = JSON.parse(sessionStorage.getItem('visitedPages'));
        if (!visitedPages.includes(pgKey)) {
            visitedPages.push(pgKey);
            sessionStorage.setItem('visitedPages', JSON.stringify(visitedPages));
        }

    }

    const popPage = (pgKey) => {
        //  console.log('page', pgKey);
        let visitedPages = JSON.parse(sessionStorage.getItem('visitedPages'));
        if (visitedPages.includes(pgKey)) {
            visitedPages.pop(pgKey);
            sessionStorage.setItem('visitedPages', JSON.stringify(visitedPages));
        }

    }

    useEffect(() => {
        pushPage('general-intelligence-quotient');
    })

    return (
        <>
            <Header />
            <ToastContainer />
            {loading ? (
                <div className="loaders"><img src='/images/loader.gif' /></div>
            ) : (
                <div className="page-wrapper">
                    <div className="page-corners">
                        <TopBanner />
                        <div className="container mx-10 py-2.5">
                            <h3 className="text-[#6D6A6A] md:text-lg text-base pb-5"><span className="text-[#000] text-2xl">Important : </span>Basic Guideline to writing answer for below question : </h3>
                            <p className="text-[#6D6A6A] md:text-lg text-base">Everyone's life goals and aspirations are unique. It is important to note that while writing the answers to the below-given questions, you need to deeply examine and reflect upon your objectives and ambitions in life. These questions are NOT about your academic life (P/C/M/IIT/Olympiads); instead, they want you to look into yourself and find out your passion(s) so that we together can set meaningful goals that resonate with your passion(s) and values. Here are a few things to keep in mind while writing the answers.</p>
                            <ul className="imp-list">
                                <li>Be frank and truthful</li>
                                <li>Be specific. When you feel you have written what your heart says, stop writing</li>
                                <li>It must be completed within a week</li>                                
                            </ul>
                        </div>

                        <div className="container flex justify-center items-center">
                            <div className="form-layout mt-10 mb-48">
                                <div className="p-5">
                                    <h1 className="text-[#0F4275] text-2xl flex justify-center text-center">General Introspection Questionnaire</h1>
                                </div>
                                <div className="flex justify-center items-center md:mx-10 mx-5">
                                    <Form
                                        name="normal_login"
                                        className="my-7 giq-mb"
                                        onFinish={handleSubmit}
                                    >
                                        {questions.map((ques, index) => (
                                            <div key={index}>

                                                <Row>
                                                    {/* <Col md={24} xs={24}>
                                                        <div className="que-box mb-2.5">
                                                            <h2 className="text-[#191818] md:text-base text-sm">
                                                                {ques.question}
                                                            </h2>
                                                        </div>
                                                    </Col> */}

                                                    <Col md={24} xs={24}>
                                                        <Form.Item

                                                            name={ques.qid}
                                                            label={ques.question}
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={ques.answer}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please fill all answer",
                                                                },
                                                            ]}
                                                        >
                                                            <TextArea placeholder="Answer :-" type="textarea" className="mt-2.5" onChange={e => handleChange(index, e)} />
                                                        </Form.Item>
                                                    </Col>
                                                    {/* <input type={ques.answer} name={ques.name} /> */}
                                                </Row>
                                            </div>
                                        ))}


                                        <Row>
                                            <Col md={24} xs={24} className='flex justify-end'>
                                                <Form.Item>
                                                    <div className="flex">
                                                        <Button type="primary"
                                                            onClick={popPage('general-intelligence-quotient')}
                                                            href={'/student-portal/general-enquiry'}
                                                            htmlType="submit"
                                                            className="text-lg text-[#fff] bg-[#0D3761] rounded-lg py-1.5 px-5 flex justify-center mr-2.5"
                                                            style={{ height: 'auto' }}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button type="primary"

                                                            htmlType="submit"
                                                            className="text-lg text-[#0D3761] bg-[#FCC222] rounded-lg py-1.5 px-5 flex justify-center"
                                                            style={{ height: 'auto' }}
                                                        >
                                                            Next
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            )
            }

            <Footer />
        </>
    )

}

export default IntelligenceQuotient