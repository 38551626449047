import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input,  Button, Space, Layout } from "antd";
import TeacherLayout from "../components/teacher-layout";
import { useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import {  decrypt, encriptionKey } from '../components/utils';
const { Search } = Input;
const { Content } = Layout;

const DiagnosticStudent = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    console.log(user_data);
    const [loading, setLoading] = useState(true);
    const [studentList, setStudentList] = useState([]);

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const searchItems = (searchValue) => {
        
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = studentList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults(studentList)
        }
    }
  
    const fetchStudentList = async () => {

        await axios.post(api_url + 'faculty/get-diagnostic-student-list', {
            faculty_id: user_data.id

        }).then((res) => {
            //console.log(res.data.student_list);
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.student_list;
            const k = st_list.map((item, index) =>
            ({
                'sno': index + 1,
                'user_id': item.id,
                'name': decrypt(item.name, encriptionKey),
                //'center': item.center,
                'email': decrypt(item.email, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'class': decrypt(item.st_class, encriptionKey),
                'course': decrypt(item.st_course, encriptionKey),
                'diagnostic_marks': item.diagnostic_marks,
                'status': (item.diagnostic_marks ? 'Checked' : 'Pending'),
                'review': (item.faculty===null) ? '-' : decrypt(item.faculty, encriptionKey),
            })
            );

            setStudentList(k);
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchStudentList()
    }, [])
    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
        },
        {
            key: "2",
            title: "Name",
            dataIndex: "name",
            align: 'center'
        },
        {
            key: "3",
            title: "Email",
            dataIndex: "email",
            align: 'center'
        },
        {
            key: "4",
            title: "Mobile No.",
            dataIndex: "mobile",
            align: 'center'
        },
       
        {
            key: "6",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "6",
            title: "Status",
            dataIndex: "status",
            align: 'center'
        },
        {
            key: "6",
            title: "Review By",
            dataIndex: "review",
            align: 'center'
        },
        {
            key: "7",
            title: "Action",
            align: 'center',
            render: (record) => {
                return (
                    <>
                        <Space>
                            
                        <Button onClick={() => { onViewResult(record); }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Dignostic Result</Button>
                        </Space>

                    </>
                );
            },
        },
    ];

    const onViewResult = (record) => {
        navigate('/teacher-dashboard/diagnostic-result/' + record.user_id);
        
    };

    return (
        <TeacherLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                <div className="mx-16 mt-10">
                    <Row>
                        <Col md={24}>
                            <div className="bg-[#114D89] px-5 py-2.5">
                                <h1 className="text-white text-2xl ">Diagnostic Student List</h1>
                            </div>
                        </Col>
                    </Row>
                    <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                        <Row>

                            <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                <Search onChange={(e) => searchItems(e.target.value)} placeholder="Search...." />
                            </Col>
                            <Col md={24}>
                            {searchInput.length > 1 ? (
                                       
                                          
                                       <Table
                                           className="record"
                                           bordered
                                           columns={columns}
                                           dataSource={filteredResults}
                                       />
                               
                              
                           ) : (
                           <Table 
                               className="record"
                               bordered
                               columns={columns}
                               dataSource={studentList}
                           />
                           )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </TeacherLayout>
    )
}

export default DiagnosticStudent