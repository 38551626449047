import React, { useState, useEffect } from "react";
import '../styles/counsellor.css';
import axios from 'axios';
import { Row, Col, Table, Layout, Button } from "antd";
import DashboardLayout from '../components/dashboard-layout';
import { useNavigate } from 'react-router-dom';
import { api_url, studentPath, materialPath } from '../components/constants';
const { Content } = Layout;

const StudyMaterial = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [materialList, setMaterialList] = useState([]);


    const fetchStudyMaterial = async () => {

        await axios.post(api_url + 'user/study-material', {
            user_id: user_data.id

        }).then((res) => {
            const material_list = res.data.data;
            if (material_list) {
                const k = material_list.map((item, index) =>
                ({
                    'sno': index + 1,
                    'title': item.title,
                    'batch': item.batch,
                    'file': item.file,
                })
                );

                setMaterialList(k);
                setLoading(false)
            }

        })
    }

    useEffect(() => {
        fetchStudyMaterial()

    }, [])

    const columns = [
        {
            key: '1',
            title: 'Sno',
            dataIndex: 'sno',
        },
        {
            key: '2',
            title: 'Batch',
            dataIndex: 'batch',
            align: 'center',
        },

        {
            key: '3',
            title: 'Title',
            dataIndex: 'title',
            align: 'center',
        },

        {
            key: '4',
            title: 'Study Material',
            render: (item) => {
                return (
                    <a href={materialPath + item.file} className="bg-[#F0AA0C] text-white"
                        target="_blank" rel="noreferrer" style={{ borderRadius: 11, padding: '6px 15px' }}> View</a>

                );
            },
        },
    ];

    return (
        <>
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >

                        <div className="mx-0 mt-0">
                            <Row>
                                <Col md={24} xs={24}>
                                    <div className="bg-[#114D89] px-5 py-2.5">
                                        <h1 className="text-white text-2xl ">Study Material</h1>
                                    </div>
                                </Col>
                            </Row>
                            <div className="py-5 px-5" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                <Row>
                                    <Col md={24} className="responsive-table">
                                        <Table
                                            className="record"
                                            bordered
                                            columns={columns}
                                            dataSource={materialList}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Content>
                )
                }
            </DashboardLayout>
        </>
    )
}

export default StudyMaterial
