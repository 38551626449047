import React, { useState } from "react";
import CKEditor from "react-ckeditor-component";

const Editor = ({ content, onChange }) => {
  console.log(content);
  console.log(onChange)
  return (
    <CKEditor
      content={content}
      events={{
        change: (event) => {
          onChange(event.editor.getData());
        }
      }}
    />
  );
};

export default Editor;
