import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Dropdown, Button, Space, Layout } from "antd";
import TeacherLayout from "../components/teacher-layout";
import { useNavigate } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
const { Search } = Input;
const { Content } = Layout;

const Class9Record = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
   
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [studentList, setStudentList] = useState(null);

    const fetchStudentList = async () => {

        await axios.post(api_url + 'faculty/get-student-list', {
            faculty_id: user_data.id

        }).then((res) => {
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            const st_list = res.data.users;

            const k = st_list.map((item, index) =>
            ({

                'sno': index + 1,
                'name': decrypt(item.name, encriptionKey),
                'center': item.center,
                'email': decrypt(item.email, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'user_id': item.id,
            })
            );

            setStudentList(k);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchStudentList()
    }, [])
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
        },
        {
            key: "2",
            title: "Name",
            dataIndex: "name",
            align: 'center'
        },
        {
            key: "3",
            title: "Email",
            dataIndex: "email",
            align: 'center'
        },
        {
            key: "4",
            title: "Mobile No.",
            dataIndex: "mobile",
            align: 'center'
        },
        {
            key: "5",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "6",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "7",
            title: "Action",
            // render: (record) => {
            //     return (
            //         <>
            //             <Space>
            //                 <Button onClick={() => {
            //                     pbsTimeline(record);
            //                 }} className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }}>PBS View</Button>
            //                 <Button onClick={() => {
            //                     studentInfo(record);
            //                 }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>View Record</Button>
            //             </Space>

            //         </>
            //     );
            // },
        },
    ];

    return (
        <TeacherLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                <div className="mx-16 mt-10">
                    <Row>
                        <Col md={24}>
                            <div className="bg-[#114D89] px-5 py-2.5">
                                <h1 className="text-white text-2xl ">Class 9th Student Records</h1>
                            </div>
                        </Col>
                    </Row>
                    <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                        <Row>

                            <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                <Search placeholder="Search...." />
                            </Col>
                            <Col md={24}>
                                <Table
                                    className="record"
                                    bordered
                                    columns={columns}
                                    //dataSource={studentList}
                                    pagination={tableParams.pagination}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </TeacherLayout>
    )
}

export default Class9Record