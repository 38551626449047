import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Layout, Select, Form, Modal, Space, message, Popconfirm } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { useNavigate } from "react-router-dom";
import { api_url, base_url } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Search } = Input;
const { Content } = Layout;

const CounsellorList = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    //console.log(user_data);
    const [loading, setLoading] = useState(true);
    const [loadingCountry, setCountryLoading] = useState(true);
    const [loadingSelectedCountry, setSelectedCountryLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [counsellorList, setCounsellorList] = useState([]);
    const [assignCountryModalVisible, setCountryModalVisible] = useState(false);
    //const [counsellorId, setCounsellorId] = useState({ counsellor_id: "" });
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);

    const [centerList, setCenterList] = useState([]);
    const [cid, setId] = useState('');

    const [modalEditCounsellorData, setModalEditCounsellorData] = useState({
        counsellor_type: '', counsellor_name: '', email: '', mobile: '', emp_id: '', center: '', country: '', id: '', status: ''
    });
    const [loadingEdit, setLoadingEdit] = useState(true);

    const fetchCountryList = async () => {

        await axios.get(api_url + 'get_country_list', {

        }).then((response) => {

            const countries = response.data.country_list.map(country => ({ value: country.id, label: country.name }));
            setCountries(countries);
            setCountryLoading(false);
        })
    }

    const fetchCounsellorList = async () => {

        await axios.post(api_url + 'get_counsellor_list', {
            counsellor_id: user_data.id,
            counsellor_type: user_data.counsellor_type,

        }).then((res) => {
            //console.log("Counsellor List Response:", res);

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }

            const st_list = res.data.counsellor_list;
            //console.log(st_list)
            const k = st_list.map((item, index) =>
            ({

                'sno': index + 1,
                'center': item.center,
                'name': decrypt(item.name, encriptionKey),
                'email': decrypt(item.email, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'counsellor_id': item.id,
                'country': item.country_ids,
                'status': item.status === 'Y' ? 'Active' : 'Inactive',

            })
            );

            setCounsellorList(k);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchCounsellorList()
        fetchCountryList()
    }, [])

    const fetchCenterList = async () => {
        await axios.get(api_url + 'ajax/get_center_list', {
        }).then((response) => {

            const centers = response.data.center_list.map(center => ({ value: center.id, label: center.name }));
            setCenterList(response.data.center_list);

        })
    }
    useEffect(() => {
        fetchCenterList()
    }, [])


    const fetchCounsellorDetails = async (counsellorId) => {
        console.log("Fetching details for counsellorId:", counsellorId);
        try {
            const response = await axios.post(api_url + 'counsellor/get_counsellor_details', {
                user_id: counsellorId,
            });

            const counsellorData = [response.data.data];
            const counArry = counsellorData.map(item => ({
                'counsellor_type': item.counsellor_type,
                'counsellor_name': decrypt(item.name, encriptionKey),
                'email': decrypt(item.email, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'emp_id': decrypt(item.emp_id, encriptionKey),
                'center': item.center_id,
                'country': item.country_ids,
                'status': item.status,
            }));

            console.log("Fetched counsellor data:", counArry);

            //if (counsellorId !== editCounsellorData.id) {
            setModalEditCounsellorData(counArry[0]);  // Use await to wait for state update
            console.log("Updated Counsellor data:", counArry[0]);
            setLoadingEdit(false);
            setEditModalVisible(true);
            //}
        } catch (error) {
            console.error("Error fetching counsellor details:", error);
        }
    };




    const handleEditCounsellor = async (counsellorId) => {
        console.log("Editing counsellor with ID:", counsellorId);
        await fetchCounsellorDetails(counsellorId);
        setId(counsellorId);
    };



    useEffect(() => {
        console.log("Updated editCounsellorData:", modalEditCounsellorData);
    }, [modalEditCounsellorData]);

    // useEffect(() => {
    //     if (modalEditCounsellorData.id !== '' && modalVisible) {
    //         setModalVisible(true);
    //     }
    // }, [modalEditCounsellorData]);



    const onFinish = (values) => {
        //console.log(counsellorId)
        if (modalEditCounsellorData) {

            axios.post(api_url + 'counsellor/update_counsellor', {
                id: values.id,
                user_id: user_data.id,
                //counsellor_id: editCounsellorData.counsellor_id,
                type: values.counsellor_type,
                center: values.center,
                counsellor_email: values.counsellor_email,
                counsellor_mobile: values.counsellor_mobile,
                counsellor_name: values.counsellor_name,

            })
                .then(result => {
                    console.log(result)

                    if (result.data.status) {
                        message.success("Counsellor details updated successfully!");
                        setModalVisible(false);
                        // Optionally, you can refresh the counsellor list after successful update
                        fetchCounsellorList();
                    } else {
                        message.error("Failed to update counsellor details!");
                    }
                })
                .catch(error => {
                    message.error("An error occurred while updating counsellor details.");
                    console.error("Error updating counsellor details:", error);
                });
        } else {
            //console.log(values)
            axios.post(api_url + 'counsellor/create_counsellor', {
                user_id: user_data.id,
                type: values.type,
                center: values.center,
                counsellor_email: values.counsellor_email,
                counsellor_mobile: values.counsellor_mobile,
                counsellor_name: values.counsellor_name,
                country: values.country,
                emp_id: values.emp_id,
                password: values.password,
            })
                .then(result => {
                    //console.log('success', values)
                    if (result.data.status) {
                        toast.success(result.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });



                        const timer = setTimeout(() => {
                            window.location.reload();
                        }, 3000);

                    } else {
                        toast.error(result.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });

                    }

                })
        }
    }


    const onAddFinish = (values) => {

        axios.post(api_url + 'counsellor/create_counsellor', {
            user_id: user_data.id,
            type: values.type,
            center: values.center,
            counsellor_email: values.counsellor_email,
            counsellor_mobile: values.counsellor_mobile,
            counsellor_name: values.counsellor_name,
            country: values.country,
            emp_id: values.emp_id,
            password: values.password,
        })
            .then(result => {
                //console.log('success', values)
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                    setAddModalVisible(false);
                    fetchCounsellorList();

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })
    };



    const onEditFinish = (values) => {
        console.log(modalEditCounsellorData)
        // if (modalEditCounsellorData) {

        axios.post(api_url + 'counsellor/update_counsellor', {
            id: values.id,
            user_id: user_data.id,
            //counsellor_id: editCounsellorData.counsellor_id,
            type: values.counsellor_type,
            center: values.center,
            counsellor_email: values.counsellor_email,
            counsellor_mobile: values.counsellor_mobile,
            counsellor_name: values.counsellor_name,

        })
            .then(result => {
                //console.log(result)

                if (result.data.status) {
                    message.success("Counsellor details updated successfully!");
                    setEditModalVisible(false);
                    // Optionally, you can refresh the counsellor list after successful update
                    fetchCounsellorList();
                } else {
                    message.error("Failed to update counsellor details!");
                }
            })
            .catch(error => {
                message.error("An error occurred while updating counsellor details.");
                console.error("Error updating counsellor details:", error);
            });
        // }
    };

    const handleChange = (name, value) => {
        setModalEditCounsellorData({ ...modalEditCounsellorData, [name]: value });

    };

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const handleToggleStatus = (counsellorId, status) => {
        //console.log("Counsellor ID:", counsellorId);
        //console.log("Is Active:", status);
        axios.post(api_url + 'counsellor/update_status', {
            counsellor_id: counsellorId,
            status: status,

        })
            .then(result => {
                //console.log('success', values)
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })


    };


    const columns = [
        {
            title: "Sno",
            dataIndex: "sno",
            align: 'center'
        },
        {
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            title: "Name",
            dataIndex: "name",
            align: 'center'
        },

        {
            title: "Email",
            dataIndex: "email",
            align: 'center'
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            align: 'center'
        },
        {
            title: "Status",
            dataIndex: "status",
            align: 'center'
        },
        {
            title: "Action",
            align: 'center',
            render: (record) => {
                //console.log(record)
                const isActive = record.status === 'active';
                return (
                    <>
                        <Space>
                            <Button
                                onClick={() => {
                                    handleEditCounsellor(record.counsellor_id);
                                    setEditModalVisible(true);
                                }}
                                className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>
                                Edit
                            </Button>

                            <Modal

                                //title={editCounsellorData.counsellor_type != '' ? "Edit Counsellor" : "Add Counsellor"}
                                title="Edit Counsellor"
                                open={editModalVisible}
                                width={800}
                                onCancel={() => {
                                    //setModalVisible(false);
                                    setEditModalVisible(false);
                                    //setEditCounsellorData(null);
                                }}
                                footer={null}

                            >
                                {/* {loadingEdit ? (
                                                <div className="loaders"><img src='/images/loader.gif' /></div>
                                            ) : ( */}
                                <Form
                                    name="normal_login"
                                    className="my-7"
                                    //onFinish={onFinish}
                                    onFinish={onEditFinish}
                                >
                                    {/* <input type='hidden' name='id' value={id} /> */}
                                    <Form.Item label="Username" hidden={true} name='id' initialValue={cid}>
                                        <Input type="number" />
                                    </Form.Item>
                                    <Row gutter={30}>
                                        <Col md={12}>


                                            {/* <Form.Item name="counsellor_type"
                                                label="Counsellor Type"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={modalEditCounsellorData.counsellor_type}

                                            > */}
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="counsellor_type">Counsellor Type</label>
                                                <Select
                                                    value={modalEditCounsellorData.counsellor_type}
                                                    name="counsellor_type"
                                                    placeholder="Select"
                                                    style={{ width: '100%' }}
                                                    onChange={(e) => handleChange('counsellor_type', e.target.value)}
                                                >
                                                    <Select.Option value="Country Counsellor Head">Country Counsellor Head</Select.Option>
                                                    <Select.Option value="Country Counsellor">Country Counsellor</Select.Option>
                                                </Select>
                                            </div>
                                            {/* </Form.Item> */}
                                        </Col>
                                        <Col md={12}>
                                            {/* {modalEditCounsellorData.counsellor_name} */}
                                            {/* <Form.Item
                                                name="counsellor_name"
                                                label="Counsellor Name"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={editCounsellorData.counsellor_name}

                                            >

                                                <Input
                                                    onChange={(e) => handleChange('counsellor_name', e.target.value)}
                                                />
                                            </Form.Item> */}
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="counsellor_name">Counsellor Name</label>
                                                <Input
                                                    name="counsellor_name"
                                                    value={modalEditCounsellorData.counsellor_name}
                                                    onChange={(e) => handleChange('counsellor_name', e.target.value)}
                                                />
                                            </div>
                                        </Col>

                                        <Col md={12}>

                                            {/* <Form.Item
                                                name="counsellor_email"
                                                label="Counsellor Email"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={modalEditCounsellorData.counsellor_email}

                                            > */}
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="email">Counsellor Email</label>
                                                <Input
                                                    value={modalEditCounsellorData.email}
                                                    onChange={(e) => handleChange('email', e.target.value)}
                                                    name="email"
                                                />
                                            </div>
                                            {/* </Form.Item> */}
                                        </Col>
                                        <Col md={12}>
                                            {/* <Form.Item
                                                name="counsellor_mobile"
                                                label="Counsellor Mobile"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={modalEditCounsellorData.mobile}
                                            > */}
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="counsellor_mobile">Counsellor Mobile</label>
                                                <Input
                                                    value={modalEditCounsellorData.mobile}
                                                    onChange={(e) => handleChange('counsellor_mobile', e.target.value)}
                                                    name="counsellor_mobile"

                                                />
                                            </div>

                                            {/* </Form.Item> */}
                                        </Col>
                                        <Col md={12}>
                                            {/* <Form.Item
                                                name="emp_id"
                                                label="Employee ID"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={modalEditCounsellorData.emp_id}
                                            > */}
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="emp_id">Employee ID</label>
                                                <Input
                                                    value={modalEditCounsellorData.emp_id}
                                                    disabled={!!modalEditCounsellorData}
                                                    onChange={(e) => handleChange('emp_id', e.target.value)}
                                                    name="emp_id"
                                                />
                                            </div>
                                            {/* </Form.Item> */}
                                        </Col>
                                        <Col md={12}>
                                            {/* <Form.Item name="center"
                                                label="Center"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={modalEditCounsellorData.center}
                                            > */}
                                            <div style={{ marginBottom: '10px' }}>
                                                <label htmlFor="center">Center</label>
                                                <select
                                                    name="center"
                                                    value={modalEditCounsellorData.center}
                                                    onChange={(e) => handleChange('center', e.target.value)}
                                                    style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', outline: 'none' }}
                                                >
                                                    <option value="" disabled>Select an option</option>
                                                    {centerList.map((center) => (
                                                        <option
                                                            key={center.id}
                                                            value={center.id}
                                                        >
                                                            {center.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* </Form.Item> */}
                                        </Col>



                                    </Row>


                                    <Row>
                                        <Col md={24}>
                                            <Button className='bg-[#F0AA0C] text-white' style={{ float: 'right' }} htmlType="submit">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                                {/* )} */}
                            </Modal>


                            <Popconfirm
                                title="Are you sure?"
                                okButtonProps={{ style: { background: '#205584', color: '#fff' } }}
                                onConfirm={() => handleToggleStatus(record.counsellor_id, record.status)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className={`bg-[${isActive ? 'green' : 'red'}] text-black`}
                                    style={{ borderRadius: 11 }}
                                >
                                    {record.status === "Active" ? 'Inactive' : 'Active'}
                                </Button>
                            </Popconfirm>
                        </Space>

                    </>
                );
            },
        },
    ];


    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const searchItems = (searchValue) => {

        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = counsellorList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(counsellorList)
        }
    }

    const handleSubmit = () => {

        axios.post(api_url + 'assign_country_counsellor', {
            country: selectedCountry,
            counsellor_id: user_data.id,
            id: user_data.id,
        })
            .then(result => {

                if (result.data.status) {

                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        setCountryModalVisible(false);
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }



    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="">
                            <Row>
                                <Col md={24} xs={24}>
                                    <div className="bg-[#114D89] px-5 py-2.5 flex justify-between">
                                        {user_data.counsellor_type === 'Country Counsellor Head' ? (
                                            <h1 className="text-white text-2xl ">Counsellor List</h1>
                                        ) : (<h1 className="text-white text-2xl ">Mentor List</h1>)}
                                        <a className="text-right text-white text-xl" onClick={() => setAddModalVisible(true)}>ADD</a>
                                        <Modal
                                            //title={editCounsellorData.counsellor_type != '' ? "Edit Counsellor" : "Add Counsellor"}
                                            title='Add Counsellor'
                                            open={addModalVisible}
                                            width={800}
                                            onCancel={() => {
                                                //setModalVisible(false);
                                                setAddModalVisible(false);
                                                //setEditCounsellorData(null);
                                            }}
                                            footer={null}

                                        >
                                            <Form
                                                name="normal_login"
                                                className="my-7"
                                                //onFinish={onFinish}
                                                onFinish={onAddFinish}

                                            >
                                                {/* <input type='hidden' name='id' value={id} /> */}
                                                <Form.Item label="Username" hidden={true} name='id' initialValue={cid}>
                                                    <Input type="number" />
                                                </Form.Item>
                                                <Row gutter={30}>
                                                    <Col md={12}>
                                                        <Form.Item name="counsellor_type"
                                                            label="Counsellor Type"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                        //initialValue={editCounsellorData.counsellor_type}

                                                        >
                                                            <Select
                                                                placeholder="Select"
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => handleChange('counsellor_type', e.target.value)}
                                                            >
                                                                <Select.Option value="Country Counsellor Head">Country Counsellor Head</Select.Option>
                                                                <Select.Option value="Country Counsellor">Country Counsellor</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Item
                                                            name="counsellor_name"
                                                            label="Counsellor Name"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                        //initialValue={editCounsellorData.counsellor_name}

                                                        >

                                                            <Input
                                                                onChange={(e) => handleChange('counsellor_name', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col md={12}>
                                                        <Form.Item
                                                            name="counsellor_email"
                                                            label="Counsellor Email"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                        //initialValue={editCounsellorData.email}
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: 'Please enter !',
                                                        //     },
                                                        // ]}
                                                        >

                                                            <Input
                                                                onChange={(e) => handleChange('counsellor_email', e.target.value)}
                                                                name="counsellor_email"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Item
                                                            name="counsellor_mobile"
                                                            label="Counsellor Mobile"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                        // initialValue={editCounsellorData.mobile}
                                                        >

                                                            <Input
                                                                onChange={(e) => handleChange('counsellor_mobile', e.target.value)}
                                                                name="counsellor_mobile"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Item
                                                            name="emp_id"
                                                            label="Employee ID"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                        //initialValue={editCounsellorData.emp_id}
                                                        >

                                                            <Input
                                                                //disabled={!!editCounsellorData}
                                                                onChange={(e) => handleChange('emp_id', e.target.value)}
                                                                name="emp_id" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Item name="center"
                                                            label="Center"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                        //initialValue={editCounsellorData.center}
                                                        >

                                                            <Select
                                                                placeholder="Please select"
                                                                //onChange={(value) => handleChange(value)}
                                                                style={{ width: '100%' }}
                                                                name="center"
                                                                onChange={(e) => handleChange('center', e.target.value)}

                                                            >

                                                                {centerList.map((center) => (
                                                                    <Select.Option
                                                                        key={center.id}
                                                                        value={center.id}
                                                                    // onChange={(e) => setEmpId(e.target.value)}
                                                                    >
                                                                        {center.name}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                            {/* <Select
                                                                style={{ width: '100%' }}
                                                                //mode="multiple"
                                                                //showSearch
                                                                //allowClear
                                                                placeholder="Please select"
                                                                options={centerList}
                                                            //onChange={value => setCenterList(value)}
                                                            /> */}
                                                        </Form.Item>
                                                    </Col>

                                                    {/* {!editCounsellorData && ( */}
                                                    <>
                                                        <Col md={12}>
                                                            <Form.Item
                                                                name="country"
                                                                label="Country"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                                onChange={(e) => handleChange('center', e.target.value)}
                                                            >

                                                                {loadingCountry ? (
                                                                    <div className="loaders"><img src='/images/loader.gif' /></div>
                                                                ) : (
                                                                    <Select
                                                                        style={{ width: '100%' }}
                                                                        name="country"
                                                                        mode="multiple"
                                                                        //showSearch
                                                                        allowClear
                                                                        placeholder="Please select"
                                                                        options={countries}
                                                                        onChange={(e) => handleChange('password', e.target.value)}
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Form.Item
                                                                name="password"
                                                                label="Password"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                            >
                                                                <Input onChange={(e) => handleChange('password', e.target.value)} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Form.Item
                                                                name="confirm_password"
                                                                label="Confirm Password"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                            >
                                                                <Input onChange={(e) => handleChange('confirm_password', e.target.value)} />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                    {/* )} */}
                                                </Row>


                                                <Row>
                                                    <Col md={24}>
                                                        <Button className='bg-[#F0AA0C] text-white' style={{ float: 'right' }} htmlType="submit">
                                                            Submit
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                            {/* )} */}
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>

                            <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                {/* <Row>
                            <Col>
                                <h2 className="text-[#061522] text-2xl mb-2.5">PBS Student Session List</h2>
                                <span className="text-[#9B9B9B] text-lg">This is your most recent records.</span>
                            </Col>
                        </Row> */}
                                <Row>

                                    <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                        <Search onChange={(e) => searchItems(e.target.value)} placeholder="Search...." />
                                    </Col>
                                    <Col md={24} className="responsive-table">
                                        {searchInput.length > 1 ? (
                                            <Table className="record" bordered columns={columns} dataSource={filteredResults} />
                                        ) : (
                                            <Table className="record" bordered columns={columns} dataSource={counsellorList} />
                                        )}

                                    </Col>
                                </Row>
                            </div>
                        </div>

                        {assignCountryModalVisible && (
                            <Modal title="Assign Country"

                                open={assignCountryModalVisible}
                                okText='Assign Country'
                                onOk={() => {
                                    handleSubmit()
                                }}
                                onCancel={() => setCountryModalVisible(false)}
                                okButtonProps={{ style: { background: '#F0AA0C', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16 } }}
                                cancelButtonProps={{ style: { background: '#717070', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16, color: '#fff' } }}
                            >
                                <Form>
                                    <Row gutter={30} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 20 }}>
                                        <Col md={24}>
                                            <Form.Item
                                                name="country"
                                                label='Select Country'
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={selectedCountry}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select',
                                                    },
                                                ]}

                                            >
                                                {loadingCountry ? (
                                                    <div className="loaders"><img src='/images/loader.gif' /></div>
                                                ) : (
                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        allowClear
                                                        placeholder="Please select"
                                                        options={countries}
                                                        onChange={value => setSelectedCountry(value)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                            </Modal>
                        )}
                    </Content>
                )}
            </CounsellorLayout>
        </>
    )
}

export default CounsellorList